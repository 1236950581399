var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"draw-area",on:{"mouseup":_vm.mouseup}},[_c('div',{staticClass:"control"},[_c('span',{staticClass:"color",class:{
        editorActive:
          _vm.editor.options.stroke == '#1a1a1a' && _vm.editor.mode != 'text'
      },staticStyle:{"background":"#1a1a1a"},on:{"click":function($event){return _vm.setColor('#1a1a1a')}}}),_c('span',{staticClass:"color brand-bg-orange",class:{ editorActive: _vm.editor.options.stroke == '#f48333' },on:{"click":function($event){return _vm.setColor('#f48333')}}}),_c('span',{staticClass:"color brand-bg-purple",class:{ editorActive: _vm.editor.options.stroke == '#992367' },on:{"click":function($event){return _vm.setColor('#992367')}}}),_c('span',{staticClass:"color",class:{ editorActive: _vm.editor.options.stroke == '#ff0000' },staticStyle:{"background":"#ff0000"},on:{"click":function($event){return _vm.setColor('#ff0000')}}}),_c('span',{staticClass:"color",class:{ editorActive: _vm.editor.options.stroke == '#65c31a' },staticStyle:{"background":"#65c31a"},on:{"click":function($event){return _vm.setColor('#65c31a')}}}),_c('span',{staticClass:"color",class:{ editorActive: _vm.editor.options.stroke == '#34b7eb' },staticStyle:{"background":"#34b7eb"},on:{"click":function($event){return _vm.setColor('#34b7eb')}}}),_c('span',{staticClass:"color",class:{ editorActive: _vm.editor.options.stroke == '#1a10ad' },staticStyle:{"background":"#1a10ad"},on:{"click":function($event){return _vm.setColor('#1a10ad')}}}),_c('span',{staticClass:"eraser",class:{
        editorActive: _vm.editor.mode == 'text' && _vm.editor.options.stroke != '#fff'
      },on:{"click":_vm.createText}},[_c('i',{staticClass:" fas fa-font "})]),_c('span',{staticClass:"eraser",class:{
        editorActive:
          _vm.editor.options.stroke == '#fff' && _vm.editor.mode == 'freeDrawing'
      },on:{"click":function($event){return _vm.setColor('#fff')}}},[_c('i',{staticClass:"fas fa-eraser"})]),_c('span',{staticClass:"eraser",on:{"click":_vm.undo}},[_c('i',{staticClass:"fas fa-redo-alt"})]),_c('span',{staticClass:"eraser",on:{"click":_vm.clear}},[_c('i',{staticClass:"fas fa-trash-alt"})])]),_c('Editor',{ref:"editor",staticClass:"editor",attrs:{"canvasWidth":_vm.canvasWidth,"canvasHeight":_vm.canvasHeight,"editorId":'a'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
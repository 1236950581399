<template>
  <div v-if="application" class="text-center tagcloud mt-5">
    <button
      class="btn btn-outline-blue"
      :style="'color:#' + getRandomInt(999999)"
      v-for="(item, index) in items"
      :class="{ 'line-through': item.clicked }"
      :key="index"
      @click="
        item.clicked = true;
        checkAnswer(item);
      "
    >
      {{ item.item }}
    </button>
  </div>
</template>
<script>
import EventBus from "@/components/event-bus";
export default {
  name: "TagCloud",
  props: ["application"],
  data() {
    return {
      items: [],
      rightAnswers: []
    };
  },
  mounted() {
    //   Sort for random
    // this.items = JSON.parse(this.application.steps).items.sort(
    //   () => Math.random() - 0.5
    // );

    this.items = JSON.parse(this.application.steps).items.map(item => {
      return {
        item,
        clicked: false
      };
    });

    this.rightAnswers = JSON.parse(this.application.steps).rightAnswers;

    EventBus.$on("getAnswers", () => {
      this.saveAnswers();
    });
  },
  beforeDestroy: function() {
    EventBus.$off("getAnswers");
  },
  sockets: {
    syncAnswers: function(data) {
      if (data.applicationId == this.application.id) {
        this.items = data.answers;
      }
    }
  },
  methods: {
    getRandomInt: function(max) {
      return Math.floor(Math.random() * Math.floor(max));
    },
    checkAnswer: function(item) {
      let isRight = this.rightAnswers.filter(i => i == item.item).length;
      let animations = {
        text: "TEBRİKLER",
        gif: "applause",
        anim: null,
        sounds: true,
        show: true
      };

      if (!isRight) {
        animations.text = "TEKRAR DENE";
        animations.gif = "try-again";
      }
      this.$socket.client.emit("showAnim", {
        to: this.$route.params.id,
        animations: animations
      });
      this.sync();
    },
    saveAnswers: function() {
      EventBus.$emit("saveAnswers");
    },
    sync: function() {
      this.$socket.client.emit("syncAnswers", {
        to: this.$route.params.id,
        answers: this.items,
        applicationId: this.application.id
      });
    }
  }
};
</script>

<template>
  <div class="page-wrapper">
    <!-- Page Content-->
    <div class="page-content-tab">
      <div class="container-fluid">
        <!-- Page-Title -->
        <div class="row">
          <div class="col-sm-12">
            <div class="page-title-box">
              <h4 class="page-title">Yeni Danışan</h4>
            </div>
            <!--end page-title-box-->

            <!-- Body -->

            <div class="row">
              <div class="col-12 col-sm-7">
                <div class="card">
                  <div class="row">
                    <div class="col-12">
                      <div class="card-body">
                        <h6 class="brand-color-orange">Danışan Bilgileri</h6>
                        <div class="form-group mt-2">
                          <label> Ad Soyad</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Ad Soyad"
                            v-model="newChild.name"
                            autocomplete="nope"
                            @blur="$v.newChild.name.$touch()"
                            :class="{
                              'is-invalid': $v.newChild.name.$error
                            }"
                          />
                          <div class="invalid-feedback">
                            Lütfen ad soyad giriniz.
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-check-inline mr-4">
                            <div class="custom-control custom-radio">
                              <input
                                type="radio"
                                id="customRadio7"
                                name="customRadio"
                                v-model="newChild.sex"
                                value="false"
                                class="custom-control-input"
                                :class="{
                                  'is-invalid': $v.newChild.sex.$error
                                }"
                              />
                              <label
                                class="custom-control-label"
                                for="customRadio7"
                                >Kız</label
                              >
                              <div class="invalid-feedback">
                                Lütfen cinsiyet bilgisi seçiniz.
                              </div>
                            </div>
                          </div>

                          <div class="form-check-inline">
                            <div class="custom-control custom-radio">
                              <input
                                type="radio"
                                id="customRadio8"
                                name="customRadio"
                                v-model="newChild.sex"
                                value="true"
                                class="custom-control-input"
                                :class="{
                                  'is-invalid': $v.newChild.sex.$error
                                }"
                              />
                              <label
                                class="custom-control-label"
                                for="customRadio8"
                                >Erkek</label
                              >
                              <div class="invalid-feedback">
                                Lütfen cinsiyet bilgisi seçiniz.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <label>Telefon</label>
                            <the-mask
                              mask="0 (###) ### ## ##"
                              v-model="newChild.tel"
                              class="form-control"
                              type="phone"
                              placeholder="Telefon"
                            ></the-mask>
                          </div>
                          <div class="col-6">
                            <label>E-Mail</label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="E-Mail"
                              autocomplete="nope"
                              v-model="newChild.email"
                              @blur="$v.newChild.email.$touch()"
                              :class="{
                                'is-invalid': $v.newChild.email.$error
                              }"
                            />
                            <div class="invalid-feedback">
                              Lütfen geçerli bir email adresi giriniz.
                            </div>
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col-6">
                            <label>Doğum Tarihi</label>
                            <div>
                              <datepicker
                                class="form-control datepicker"
                                format="DD.MM.YYYY"
                                value-type="YYYY-MM-DD"
                                v-model="birthdate"
                                placeholder="Doğum Tarihi"
                                @blur="$v.birthdate.$touch()"
                                :class="{
                                  'is-invalid': $v.birthdate.$error
                                }"
                              ></datepicker>
                              <div class="invalid-feedback">
                                Lütfen tarih seçiniz.
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="row">
                              <div class="col-8">
                                <label>Okulu</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Okulu"
                                  autocomplete="nope"
                                  v-model="newChild.school"
                                />
                              </div>
                              <div class="col-4">
                                <label>Sınıfı</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Sınıfı"
                                  autocomplete="nope"
                                  v-model="newChild.classroom"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <h6 class="brand-color-purple mt-4">
                          Ebeveyn Bilgileri
                        </h6>
                        <div class="row">
                          <div class="col-6">
                            <div class="form-group">
                              <label>Anne Ad Soyad</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Anne Ad Soyad"
                                autocomplete="nope"
                                v-model="newChild.momName"
                                @blur="$v.newChild.momName.$touch()"
                                :class="{
                                  'is-invalid': $v.newChild.momName.$error
                                }"
                              />
                              <div class="invalid-feedback">
                                Lütfen ad soyad giriniz.
                              </div>
                            </div>
                            <div class="form-group">
                              <label>Anne E-Mail</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Anne E-Mail"
                                autocomplete="nope"
                                v-model="newChild.momEmail"
                                @blur="$v.newChild.momEmail.$touch()"
                                :class="{
                                  'is-invalid': $v.newChild.momEmail.$error
                                }"
                              />
                              <div class="invalid-feedback">
                                Lütfen geçerli bir email adresi girin.
                              </div>
                            </div>
                            <div class="form-group">
                              <label>Anne Telefon</label>
                              <the-mask
                                mask="0 (###) ### ## ##"
                                v-model="newChild.momTel"
                                class="form-control"
                                type="phone"
                                placeholder="Anne Telefon"
                                @blur="$v.newChild.momTel.$touch()"
                                :class="{
                                  'is-invalid': $v.newChild.momTel.$error
                                }"
                              ></the-mask>
                              <div class="invalid-feedback">
                                Lütfen geçerli bir telefon numarası girin.
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label>Baba Ad Soyad</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Baba Ad Soyad"
                                autocomplete="nope"
                                v-model="newChild.dadName"
                                @blur="$v.newChild.dadName.$touch()"
                                :class="{
                                  'is-invalid': $v.newChild.dadName.$error
                                }"
                              />
                              <div class="invalid-feedback">
                                Lütfen ad soyad girin.
                              </div>
                            </div>
                            <div class="form-group">
                              <label>Baba E-Mail</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Baba E-Mail"
                                autocomplete="nope"
                                v-model="newChild.dadEmail"
                                @blur="$v.newChild.dadEmail.$touch()"
                                :class="{
                                  'is-invalid': $v.newChild.dadEmail.$error
                                }"
                              />
                              <div class="invalid-feedback">
                                Lütfen geçerli bir email adresi girin.
                              </div>
                            </div>
                            <div class="form-group">
                              <label>Baba Telefon</label>
                              <the-mask
                                mask="0 (###) ### ## ##"
                                v-model="newChild.dadTel"
                                class="form-control"
                                type="phone"
                                placeholder="Baba Telefon"
                                @blur="$v.newChild.dadTel.$touch()"
                                :class="{
                                  'is-invalid': $v.newChild.dadTel.$error
                                }"
                              ></the-mask>
                              <div class="invalid-feedback">
                                Lütfen geçerli bir telefon numarası girin.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="checkbox my-2">
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="customCheck02"
                                v-model="isConsentOk"
                                data-parsley-multiple="groups"
                                data-parsley-mincheck="2"
                                @change="$v.isConsentOk.$touch()"
                                :class="{
                                  'is-invalid': $v.isConsentOk.$error
                                }"
                              />
                              <label
                                class="custom-control-label"
                                for="customCheck02"
                                >Bu danışanı ekleyerek değerlendirme ölçeği
                                kullanımına istinaden sistemden
                                <u>1 uygulama hakkı</u>mın düşeceğini
                                onaylıyorum.</label
                              >
                            </div>
                          </div>
                        </div>
                        <div class="float-right">
                          <router-link
                            :to="{ name: 'Childs' }"
                            class="btn btn-md btn-danger waves-effect waves-light pr-4 pl-4 mr-3"
                          >
                            İptal
                          </router-link>
                          <button
                            type="button"
                            class="btn btn-md btn-primary waves-effect waves-light pr-4 pl-4"
                            @click="addChild"
                          >
                            Ekle
                          </button>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Body End -->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <!-- end page title end breadcrumb -->
      </div>
      <!-- container -->

      <footer-text></footer-text>
      <!--end footer-->
    </div>
    <!-- end page content -->
  </div>
</template>

<script>
/* eslint-disable*/
import footer from "@/components/Footer";
import HTTP from "@/main-source";
import router from "@/router";
import {
  required,
  minLength,
  email,
  requiredIf,
  sameAs
} from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  name: "CreateChild",
  components: {
    "footer-text": footer
  },
  data() {
    return {
      passInputType: "password",
      authFirmId: JSON.parse(localStorage.getItem("user")).firmId,
      cities: [],
      selectedCity: null,
      birthdate: null,
      isConsentOk: null,
      newChild: {
        name: "",
        email: "",
        birthdate: "",
        sex: null,
        tel: null,
        school: null,
        classroom: null,
        momName: null,
        momEmail: null,
        momTel: null,
        dadName: null,
        dadEmail: null,
        dadTel: null
      },
      usageRights: 0
    };
  },
  validations: {
    birthdate: { required },
    isConsentOk: { sameAs: sameAs(() => true) },
    newChild: {
      name: { required },
      email: { email },
      birthdate: { required },
      sex: { required },
      momName: {
        required: requiredIf(function(newChildOb) {
          return newChildOb.dadName == null;
        })
      },
      momEmail: {
        required: requiredIf(function(newChildOb) {
          return newChildOb.dadEmail == null;
        }),
        email
      },
      momTel: {
        required: requiredIf(function(newChildOb) {
          return newChildOb.dadTel == null;
        })
      },
      dadName: {
        required: requiredIf(function(newChildOb) {
          return newChildOb.momName == null;
        })
      },
      dadEmail: {
        required: requiredIf(function(newChildOb) {
          return newChildOb.momEmail == null;
        }),
        email
      },
      dadTel: {
        required: requiredIf(function(newChildOb) {
          return newChildOb.momTel == null;
        })
      }
    }
  },
  created() {
    HTTP.get("cities").then(result => {
      result.data.forEach(city => {
        this.cities.push({ id: city.id, label: city.name });
      });
    });
    HTTP.get("firms/" + this.authFirmId + "/usagerights/").then(result => {
      this.usageRights = result.data.total;
    });
  },
  methods: {
    addChild: function() {
      this.$v.newChild.$touch();
      this.$v.birthdate.$touch();
      this.$v.isConsentOk.$touch();
      if (!this.$v.birthdate.$error) {
        this.newChild.birthdate = new Date(this.birthdate);
      }
      if (this.usageRights <= 0) {
        Swal.fire({
          icon: "error",
          text:
            "Kullanım hakkınız bulunmuyor, lütfen Sobece ile iletişime geçiniz."
        });
      }
      if (
        !this.$v.newChild.$error &&
        !this.$v.birthdate.$error &&
        !this.$v.isConsentOk.$error &&
        this.usageRights > 0
      ) {
        HTTP.post("childs", this.newChild)
          .then(result => {
            Swal.fire({
              icon: "success",
              text: "Danışan oluşturuldu, yönlendiriliyorsunuz.",
              timer: 1000
            });
            setTimeout(() => {
              router.push({
                name: "ChildDetail",
                params: { id: result.data.id }
              });
            }, 1000);
          })
          .catch(e => {
            if (e.response.status == 412) {
              Swal.fire({
                icon: "warning",
                text: "Lütfen girdiğiniz bilgileri kontrol edin."
              });
            } else {
              Swal.fire({
                icon: "error",
                text:
                  "Hata meydana geldi, bilgilerinizi kontrol ederek lütfen tekrar deneyiniz."
              });
            }
          });
      } else {
        console.log(this.$v);
      }
    }
  }
};
</script>

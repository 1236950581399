var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.application)?_c('div',{staticClass:"text-center"},[(
      _vm.application.steps && _vm.$route.name == 'App' && !_vm.application.childCanSee
    )?_c('div',{staticClass:"alert alert-outline-purple b-round",attrs:{"role":"alert"}},[_c('i',{staticClass:" fas fa-info-circle "}),_vm._v(" Aşağıdaki metni sadece siz görüyorsunuz. ")]):_vm._e(),(
      (_vm.$route.name == 'AppChildSide' && _vm.application.childCanSee) ||
        _vm.$route.name == 'App'
    )?_c('div',{domProps:{"innerHTML":_vm._s(
      _vm.application.steps
        ? JSON.parse(_vm.application.steps).items[0].directive
        : ''
    )}}):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
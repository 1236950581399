<template>
  <div class="row">
    <div class="col-12 table-day-todo">
      <div class="row table-head">
        <div class="col">Yapılacaklar</div>
        <div class="col-4 ">
          Süre
        </div>
      </div>
      <draggable tag="ul" :list="list" class="list-group" @end="sync">
        <div
          class="list-group-item form-group row"
          v-for="(element, idx) in list"
          :key="element.name"
        >
          <i class="fa fa-align-justify handle"></i>

          <input
            type="text"
            class="form-control w-80 ml-4"
            v-model="element.text"
            placeholder="Yapacağını buraya yazabilirsin."
            @change="sync"
          />
          <input
            type="text"
            class="form-control col-3 ml-4"
            v-model="element.time"
            placeholder="Süre"
            @change="sync"
          />

          <i class="fa fa-times close" @click="removeAt(idx)" v-if="false"></i>
        </div>
      </draggable>
      <button
        class="btn btn-secondary button float-right"
        @click="
          add();
          sync();
        "
      >
        Yeni Ekle
      </button>
    </div>
  </div>
</template>

<script>
import EventBus from "@/components/event-bus";
let id = 0;
import draggable from "vuedraggable";
export default {
  name: "TodoListWithTime",
  components: {
    draggable
  },
  props: ["application"],
  data() {
    return {
      list: [{ time: "", text: "", id: 0 }],
      dragging: false
    };
  },
  mounted() {
    this.list = JSON.parse(this.application.steps).items.map((l, index) => {
      return { time: "", text: l.name, id: index };
    });
    EventBus.$on("getAnswers", () => {
      this.saveAnswers();
    });
  },
  beforeDestroy: function() {
    EventBus.$off("getAnswers");
  },
  computed: {
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    }
  },
  sockets: {
    syncAnswers: function(answers) {
      if (this.application.id == answers.applicationId) {
        this.list = answers.answers;
      }
    }
  },
  methods: {
    removeAt(idx) {
      this.list.splice(idx, 1);
    },
    add: function() {
      id++;
      this.list.push({ name: "Juan " + id, id, text: "" });
    },
    saveAnswers: function() {
      EventBus.$emit("saveAnswers", this.list);
    },
    sync: function() {
      this.$socket.client.emit("syncAnswers", {
        to: this.$route.params.id,
        answers: this.list,
        applicationId: this.application.id
      });
    }
  }
};
</script>
<style scoped>
.button {
  margin-top: 35px;
}
.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}
.close {
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
}

.text {
  margin: 20px;
}
</style>

<template>
  <div class="row status-switch">
    <div class="col statuses">
      <span class="h4">Durumlar</span>
      <draggable class="list-group" :list="status" group="asd" @change="sync">
        <div
          class="list-group-item"
          v-for="element in status"
          :key="element.name"
        >
          {{ element.name }}
        </div>
      </draggable>
    </div>

    <div class="col do">
      <span class="h4">Yap</span>
      <draggable class="list-group" :list="dot" group="asd" @change="sync">
        <div class="list-group-item" v-for="element in dot" :key="element.name">
          {{ element.name }}
        </div>
      </draggable>
    </div>
    <div class="col dont">
      <span class="h4">Yapma</span>
      <draggable class="list-group" :list="dont" group="asd" @change="sync">
        <div
          class="list-group-item"
          v-for="element in dont"
          :key="element.name"
        >
          {{ element.name }}
        </div>
      </draggable>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import EventBus from "@/components/event-bus";
export default {
  name: "doOrNotList",
  props: ["application"],
  components: {
    draggable
  },
  data() {
    return {
      status: [],
      dot: [],
      dont: []
    };
  },
  mounted() {
    this.status = JSON.parse(this.application.steps).items;
    EventBus.$on("getAnswers", () => {
      this.saveAnswers();
    });
  },
  beforeDestroy: function() {
    EventBus.$off("getAnswers");
  },
  sockets: {
    syncAnswers: function(answers) {
      if (this.application.id == answers.applicationId) {
        this.status = answers.answers.status;
        this.dot = answers.answers.dot;
        this.dont = answers.answers.dont;
      }
    }
  },
  methods: {
    add: function() {
      this.list.push({ name: "" });
    },
    replace: function() {
      this.list = [{ name: "" }];
    },
    clone: function(el) {
      return {
        name: el.name + " cloned"
      };
    },
    log: function(evt) {
      window.console.log(evt);
    },
    saveAnswers: function() {
      EventBus.$emit("saveAnswers", {
        status: this.status,
        dot: this.dot,
        dont: this.dont
      });
    },
    sync: function() {
      this.$socket.client.emit("syncAnswers", {
        to: this.$route.params.id,
        answers: {
          status: this.status,
          dot: this.dot,
          dont: this.dont
        },
        applicationId: this.application.id
      });
    }
  }
};
</script>

<template>
  <div>
    <div class="table-responsive">
      <table class="table table-hover mb-0 child-list-table">
        <thead>
          <tr>
            <th>Adı Soyadı</th>
            <th>Yaşı</th>
            <th>Kayıt Tarihi</th>
            <th class="c-width"></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="child in childs"
            :key="child.id"
            @click="goToChildDetail(child.id)"
          >
            <td>
              <b>{{ child.name }}</b>
            </td>
            <td>
              {{ childAge(child.birthdate) }}
            </td>
            <td>
              {{ new Date(child.createdAt).toLocaleDateString("tr") }}
            </td>
            <td @click.prevent.stop>
              <button
                type="button"
                class="btn btn-success waves-effect waves-light create-usage"
                @click="createUsage(child.id)"
              >
                <i class=" fas fa-play "></i>
                Oturum Başlat
              </button>
            </td>
            <td>
              <a href="#" class="mr-2"
                ><i
                  class="fas fa-angle-right
 font-16"
                ></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
      <!--end /table-->
    </div>
  </div>
</template>

<script>
import router from "@/router";
import HTTP from "@/main-source";
import Swal from "sweetalert2";

export default {
  name: "users",
  props: ["childs", "usageRights"],
  data() {
    return {
      role: localStorage.getItem("role")
    };
  },
  methods: {
    childAge(birthdate) {
      return this.$childAge(birthdate);
    },
    goToChildDetail(childId) {
      router.push({ name: "ChildDetail", params: { id: childId } });
    },
    createUsage(childId) {
      if (this.usageRights > 0) {
        Swal.fire({
          title: "Oturum Başlatılıyor",
          html:
            "Online Sobece Uygulamasına yönlendirliyorsunuz. Danışanınız katıldığında oturum başlayacak ve kullanım hakkınıza yansıyacak.",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `Oturum Başlat`,
          denyButtonText: `İptal`
        }).then(result => {
          if (result.isConfirmed) {
            let data = {
              childId
            };
            HTTP.post("usages", data).then(result => {
              if (result.status == 200) {
                let usage = result.data;

                router.push({
                  name: "App",
                  params: { id: usage.shortlink }
                });
              }
            });
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Kullanım hakkınız bulunmuyor",
          confirmButtonText: `Tamam`
        });
      }
    }
  }
};
</script>

<template>
  <div>
    <div class="carousel slide just-slide">
      <ol class="carousel-thumbs" v-if="routeName != 'AppChildSide'">
        <div class="float-right carousel-control">
          <button
            class="btn btn-secondary btn-circle"
            v-if="routeName != 'AppChildSide'"
            @click="
              activeSlide != 0 ? activeSlide-- : '';
              syncSlide();
            "
          >
            <i class="fas fa-angle-left"></i>
          </button>
          <button
            class="btn btn-secondary btn-circle"
            v-if="routeName != 'AppChildSide'"
            @click="
              activeSlide != steps.length - 1 ? activeSlide++ : '';
              syncSlide();
            "
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </div>
        <li
          v-for="(app, index) in steps"
          :key="index"
          :class="{ active: activeSlide == index }"
          @click="
            activeSlide = index;
            syncSlide();
          "
        >
          <div class="directive">
            {{ index + 1 }}.

            <div v-html="app.directive.substr(0, 8)" v-if="app.directive"></div>
          </div>
          <img
            class="d-block img-fluid"
            v-if="app.img"
            :src="app.img"
            :title="app.directive"
            :alt="app.directive"
          />
          <div class="no-image" :title="app.directive" v-if="!app.img">
            <span class="appindex"> {{ index + 1 }} </span>
          </div>
        </li>

        <div class="clearfix"></div>
      </ol>
      <div>
        <transition
          name="fade"
          enter-active-class="animate__animated animate__fadeIn"
        >
          <template
            v-for="(app, index) in steps"
            :class="{ active: activeSlide == index }"
          >
            <div
              v-if="activeSlide == index"
              :key="index"
              :class="{ 'just-text': !app.img }"
            >
              <div v-html="app.directive"></div>

              <img
                class="d-block img-fluid"
                v-if="app.img"
                :src="app.img"
                :alt="app.directive"
              />
              <div class="d-flex mt-3">
                <div class="row w-100">
                  <div class="col">
                    <div class="card">
                      <h5 class="card-header bg-primary text-white mt-0">
                        Durum
                      </h5>
                      <span class="h4 p-2 pl-3">{{ app.question.status }}</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <h5 class="card-header bg-secondary text-white mt-0">
                        Kişi
                      </h5>
                      <span class="h4 p-2 pl-3">{{ app.question.person }}</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <h5 class="card-header bg-warning text-white mt-0">
                        Başlatma Cümleleri
                      </h5>
                      <button
                        class="btn btn-outline-blue mt-2"
                        v-for="(answer, index) in app.answers"
                        :key="index"
                        @click="checkAnswer(index)"
                      >
                        {{ answer }}
                      </button>
                    </div>
                  </div>
                </div>

                <button
                  class="col btn btn-outline-blue btn-round waves-effect waves-light m-2"
                  v-for="(option, index) in app.options"
                  :key="index"
                  @click="checkAnswer(index)"
                >
                  {{ option }}
                </button>
              </div>
            </div>
          </template>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/components/event-bus";
export default {
  name: "hasOptionAndRightAnswer",
  props: ["application"],
  data() {
    return {
      routeName: this.$route.name,
      activeSlide: -1,
      steps: null
    };
  },
  mounted() {
    this.steps = JSON.parse(this.application.steps).items;

    EventBus.$on("getAnswers", () => {
      this.saveAnswers();
    });
  },
  sockets: {
    setActiveSlide: function(data) {
      if (data.applicationId == this.application.id) {
        this.activeSlide = data.activeSlideIndex;
      }
    }
  },
  beforeDestroy: function() {
    EventBus.$off("getAnswers");
  },
  methods: {
    syncSlide: function() {
      this.$socket.client.emit("setActiveSlide", {
        to: this.$route.params.id,
        activeSlideIndex: this.activeSlide,
        applicationId: this.application.id
      });
    },
    checkAnswer: function(index) {
      let animations = {
        text: "TEBRİKLER",
        gif: "applause",
        anim: null,
        sounds: true,
        show: true
      };

      if (this.steps[this.activeSlide].rightAnswerIndex != index) {
        animations.text = "TEKRAR DENE";
        animations.gif = "try-again";
      }
      this.$socket.client.emit("showAnim", {
        to: this.$route.params.id,
        animations: animations
      });
    },
    saveAnswers: function() {
      EventBus.$emit("saveAnswers");
    }
  },
  watch: {
    application: {
      deep: true,
      handler() {
        if (this.application.steps) {
          this.activeSlide = -1;
          this.steps = JSON.parse(this.application.steps).items;
        }
      }
    }
  }
};
</script>

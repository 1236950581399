<template>
  <div
    class="row mt-5 mr-3 ml-3 assessment-form"
    :class="{ 'centered-content': xhrProcess }"
  >
    <div
      class="spinner-border text-success"
      role="status"
      v-if="xhrProcess"
    ></div>
    <button
      type="button"
      class="btn btn-secondary btn-round waves-effect waves-light h-2 position-absolute goback"
      @click="goBack()"
      v-if="!xhrProcess"
    >
      <i class=" far fa-arrow-alt-circle-left "></i>
      Panel
    </button>

    <div
      class="col-12 col-sm-8 offset-sm-2 card p-4 report-wrapper"
      v-if="!xhrProcess"
    >
      <button
        class="btn btn-primary btn-round waves-effect waves-light h-2 position-absolute"
        style="right:15px;top:15px;"
        @click="generateReport"
      >
        <span v-if="!pdfProcess">
          <i class="far fa-file-pdf"></i> PDF olarak Kaydet
        </span>
        <div
          class="spinner-border spinner-border-sm"
          role="status"
          v-if="pdfProcess"
        ></div>
      </button>
      <img
        src="@/assets/images/logo.svg"
        alt="Sobece Logo"
        class="logo text-left"
      />
      <span class="h4">SOBECE Bireysel Uygulama Formu</span>
      <p v-if="xhrProcess">
        ÇOCUĞUN ADI SOYADI:<b> {{ child.name }}</b
        ><br />

        ÇOCUĞUN DOĞUM TARİHİ:
        <b>{{ new Date(child.birthdate).toLocaleDateString("tr") }}</b>
      </p>

      <table class="table table-bordered mb-0 table-centered">
        <thead>
          <tr>
            <th></th>
            <th>Ön Test</th>
            <th>Son Test</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Anne - Baba</td>
            <td>
              {{
                scaleResults.parentFirst != 0
                  ? scaleResults.parentFirst + " %"
                  : "Doldurulmadı"
              }}
            </td>
            <td>
              {{
                scaleResults.parentLast != 0
                  ? scaleResults.parentLast + " %"
                  : "Doldurulmadı"
              }}
            </td>
          </tr>
          <tr>
            <td>Öğretmen</td>
            <td>
              {{
                scaleResults.teacherFirst != 0
                  ? scaleResults.teacherFirst + " %"
                  : "Doldurulmadı"
              }}
            </td>
            <td>
              {{
                scaleResults.teacherLast != 0
                  ? scaleResults.teacherLast + " %"
                  : "Doldurulmadı"
              }}
            </td>
          </tr>
          <tr>
            <td>Uzman</td>
            <td>
              {{
                scaleResults.userFirst != 0
                  ? scaleResults.userFirst + " %"
                  : "Doldurulmadı"
              }}
            </td>
            <td>
              {{
                scaleResults.userLast != 0
                  ? scaleResults.userLast + " %"
                  : "Doldurulmadı"
              }}
            </td>
          </tr>
          <tr>
            <td>Genel Toplam</td>
            <td>
              {{
                scaleResults.firstsSum != 0
                  ? scaleResults.firstsSum + " %"
                  : "Doldurulmadı"
              }}
            </td>
            <td>
              {{
                scaleResults.lastsSum != 0
                  ? scaleResults.lastsSum + " %"
                  : "Doldurulmadı"
              }}
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Cat List -->
      <div class="list" v-for="(cat, index) in categories" :key="index">
        <span class="h4 brand-color-purple"> {{ cat.name }}</span>

        <div
          class="row item m-1 p-2"
          v-for="(subCategory, index) in cat.categories"
          :key="subCategory.id"
        >
          <div class="col-12 p-1">
            <b>{{ index + 1 }}. {{ subCategory.name }} </b>
            <div
              class="float-right position-relative c-poin"
              @click="tickCat(subCategory.id, isCheckedSub(subCategory.id))"
            >
              <i class="fas fa-check" v-if="isCheckedSub(subCategory.id)"></i>
              <span class="box"></span>
            </div>
          </div>

          <!-- {{ getFilteredApplications(application.id) }} -->
          <div class="col-12 p-1 note">
            <template
              v-for="(app, j) in getFilteredApplications(subCategory.id)"
            >
              <div class="col-12 mb-2 p-0" :key="app.id">
                <hr />
                <b> {{ index + 1 }}.{{ j + 1 }}. {{ app.name }}</b>
                <div
                  class="float-right position-relative  c-poin"
                  @click="tickApp(app.id)"
                >
                  <i class="fas fa-check" v-if="isChecked(app.id)"></i>
                  <span class="box"></span>
                </div>

                <div v-if="getAnswer(app.id)">
                  <div
                    v-if="getAnswer(app.id).answer || getAnswer(app.id).img"
                    class="mt-1"
                  >
                    <b
                      ><i>Cevap: <br /></i
                    ></b>
                    <span
                      class="italic"
                      v-html="getAnswerText(getAnswer(app.id), app.type)"
                    ></span>
                  </div>
                </div>
                <div v-if="getAnswer(app.id)">
                  <div v-if="getAnswer(app.id).note" class="mt-1">
                    <b
                      ><i> Notlar: <br /></i
                    ></b>
                    <span
                      class="italic"
                      v-html="returnNote(getAnswer(app.id).note)"
                    ></span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- Cat List End -->
    </div>
    <vue-html2pdf
      v-if="!xhrProcess"
      :enable-download="true"
      :paginate-elements-by-height="22400"
      :manual-pagination="false"
      :html-to-pdf-options="{
        margin: [10, 5, 10, 5],
        filename: child
          ? child.name + ' Bireysel Uygulama Formu'
          : 'Bireysel Uygulama Formu'
      }"
      pdf-format="a4"
      pdf-orientation="portrait"
      ref="html2Pdf"
      @hasDownloaded="pdfProcess = false"
    >
      <section slot="pdf-content">
        <div class="card p-4 report-wrapper m-0 p-0">
          <img
            src="@/assets/images/logo.svg"
            alt="Sobece Logo"
            class="logo text-left"
            width="300"
            height="auto"
          />
          <span class="h4"
            >SOBECE ÇOCUKTA SOSYAL BECERİLERİ DEĞERLENDİRME ÖLÇEĞİ</span
          >
          <p v-if="child">
            ÇOCUĞUN ADI SOYADI:<b> {{ child.name }}</b
            ><br />

            ÇOCUĞUN DOĞUM TARİHİ:
            <b>{{ new Date(child.birthdate).toLocaleDateString("tr") }}</b>
          </p>

          <table class="table table-bordered mb-0 table-centered">
            <thead>
              <tr>
                <th></th>
                <th>Ön Test</th>
                <th>Son Test</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Anne - Baba</td>
                <td>
                  {{
                    scaleResults.parentFirst != 0
                      ? scaleResults.parentFirst + " %"
                      : "Doldurulmadı"
                  }}
                </td>
                <td>
                  {{
                    scaleResults.parentLast != 0
                      ? scaleResults.parentLast + " %"
                      : "Doldurulmadı"
                  }}
                </td>
              </tr>
              <tr>
                <td>Öğretmen</td>
                <td>
                  {{
                    scaleResults.teacherFirst != 0
                      ? scaleResults.teacherFirst + " %"
                      : "Doldurulmadı"
                  }}
                </td>
                <td>
                  {{
                    scaleResults.teacherLast != 0
                      ? scaleResults.teacherLast + " %"
                      : "Doldurulmadı"
                  }}
                </td>
              </tr>
              <tr>
                <td>Uzman</td>
                <td>
                  {{
                    scaleResults.userFirst != 0
                      ? scaleResults.userFirst + " %"
                      : "Doldurulmadı"
                  }}
                </td>
                <td>
                  {{
                    scaleResults.userLast != 0
                      ? scaleResults.userLast + " %"
                      : "Doldurulmadı"
                  }}
                </td>
              </tr>
              <tr>
                <td>Genel Toplam</td>
                <td>
                  {{
                    scaleResults.firstsSum != 0
                      ? scaleResults.firstsSum + " %"
                      : "Doldurulmadı"
                  }}
                </td>
                <td>
                  {{
                    scaleResults.lastsSum != 0
                      ? scaleResults.lastsSum + " %"
                      : "Doldurulmadı"
                  }}
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Cat List -->
          <div class="list" v-for="(cat, index) in categories" :key="index">
            <span class="h4 brand-color-purple"> {{ cat.name }}</span>

            <div
              class="row item m-1 p-2"
              v-for="(subCategory, index) in cat.categories"
              :key="subCategory.id"
            >
              <div class="col-12 p-1">
                <b>{{ index + 1 }}. {{ subCategory.name }} </b>
                <div class="float-right position-relative">
                  <i class="fas fa-check" v-if="isChecked(subCategory.id)"></i>
                  <span class="box"></span>
                </div>
              </div>

              <!-- {{ getFilteredApplications(application.id) }} -->
              <div class="col-12 p-1 note">
                <template
                  v-for="(app, j) in getFilteredApplications(subCategory.id)"
                >
                  <div class="col-12 mb-2 p-0" :key="app.id">
                    {{ index + 1 }}.{{ j + 1 }}. {{ app.name }}
                    <div class="float-right position-relative">
                      <i class="fas fa-check" v-if="isChecked(app.id)"></i>
                      <span class="box"></span>
                    </div>
                    <div v-if="getAnswer(app.id)">
                      <span v-html="returnNote(getAnswer(app.id).note)"></span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <!-- Cat List End -->
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
/* eslint-disable */
import HTTP from "@/main-source";
import Swal from "sweetalert2";
import router from "@/router";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "Fill",
  data() {
    return {
      authUser: JSON.parse(localStorage.getItem("user")),
      role: localStorage.getItem("role"),
      categories: [],
      applications: [],
      xhrProcess: false,
      childId: this.$route.params.childId,
      child: null,
      answers: [],
      answeredQuestionIdList: [],
      scaleResults: JSON.parse(this.$route.query.scales),
      pdfProcess: false
    };
  },
  components: {
    VueHtml2pdf
  },
  created() {
    document.getElementsByTagName("body")[0].classList.add("nobg");
    HTTP.get("childs/" + this.childId).then(result => {
      if (result.data.rows.length > 0) {
        this.child = result.data.rows[0];
      }
    });
    HTTP.get("answers/child/" + this.childId).then(res => {
      if (res.data.length > 0) {
        this.answers = res.data;
        this.answeredQuestionIdList = this.answers.map(ans => {
          return ans.applicationId;
        });
      }
      this.getCategoriesAndApplications();
    });
  },
  beforeDestroy: function() {
    document.getElementsByTagName("body")[0].classList.remove("nobg");
  },
  methods: {
    generateReport() {
      this.pdfProcess = true;
      this.$refs.html2Pdf.generatePdf();
    },
    getCategoriesAndApplications: function() {
      HTTP.get("categories")
        .then(result => {
          this.categories = result.data;
        })
        .then(() => {
          HTTP.get("applications")
            .then(result => {
              this.applications = result.data;
              this.xhrProcess = false;
            })
            .catch(() => {
              this.xhrProcess = false;
            });
        })
        .catch(() => {
          this.xhrProcess = false;
        });
    },
    getAnswer: function(questionId) {
      return this.answers.filter(ans => ans.applicationId == questionId)[0];
    },
    getFilteredApplications: function(categoryId) {
      return this.applications.filter(app => app.categoryId == categoryId);
    },
    returnNote: function(noteArray) {
      if (typeof noteArray == "string") {
        let result = "";
        JSON.parse(noteArray).forEach((r, index) => {
          result += r + "<br> ";
        });

        return result;
      }
    },
    goBack: function() {
      router.go(-1);
    },
    isChecked: function(applicationId) {
      return this.answeredQuestionIdList.includes(applicationId);
    },
    isCheckedSub: function(subcategoryId) {
      let applications = this.getFilteredApplications(subcategoryId);
      let fullDone = applications.some(app => {
        return !this.answeredQuestionIdList.includes(app.id);
      });
      return !fullDone;
    },
    getAnswerText: function(answer, appType) {
      let answerHTML = "";
      if (answer.img) {
        answerHTML +=
          "<a href=" +
          this.$apiDomain +
          answer.img +
          " target='_blank'><img class='bordered-img' src='" +
          this.$apiDomain +
          answer.img +
          "' width='150px' height='auto'></a>";
      }
      return answerHTML;
    },
    tickCat: function(catId, isChecked) {
      let selectedCatApp = this.applications.filter(
        app => app.categoryId == catId
      );
      if (isChecked) {
        console.log("hepsini kaldır");
        Swal.fire({
          title: "Çalışılmış etkinlik kaldırılıyor",
          html: "Bu işlemle birlikte kaydedilmiş cevaplarda kaybolacak.",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `Kaldır`,
          denyButtonText: `İptal`
        }).then(result => {
          if (result.isConfirmed) {
            selectedCatApp.forEach(app => {
              let data = {
                appId: app.id,
                childId: this.childId,
                userId: this.authUser.userId
              };
              HTTP.delete("answers", { params: data }).then(() => {
                this.answeredQuestionIdList = this.answeredQuestionIdList.filter(
                  id => id != app.id
                );
              });
            });
          }
        });
      } else {
        selectedCatApp.forEach(app => {
          if (!this.answeredQuestionIdList.includes(app.id)) {
            let formData = new FormData();
            formData.append("applicationId", app.id);
            formData.append("usageId", null);
            formData.append("childId", this.childId);
            HTTP.post("answers", formData, {
              headers: {
                "content-type": "multipart/form-data"
              }
            })
              .then(() => {
                this.answeredQuestionIdList.push(app.id);
              })
              .catch(() => {
                this.$notify({
                  group: "general",
                  type: "error",
                  text: "Kaydedilirken bir hata oluştu, lütfen tekrar deneyin.",
                  duration: 4000
                });
              });
          }
        });
      }
    },
    tickApp: function(appId) {
      if (this.answeredQuestionIdList.includes(appId)) {
        Swal.fire({
          title: "Çalışılmış etkinlik kaldırılıyor",
          html: "Bu işlemle birlikte kaydedilmiş cevaplarda kaybolacak.",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `Kaldır`,
          denyButtonText: `İptal`
        }).then(result => {
          if (result.isConfirmed) {
            let data = {
              appId,
              childId: this.childId,
              userId: this.authUser.userId
            };
            HTTP.delete("answers", { params: data }).then(() => {
              this.answeredQuestionIdList = this.answeredQuestionIdList.filter(
                id => id != appId
              );
            });
          }
        });
      } else {
        let formData = new FormData();
        formData.append("applicationId", appId);
        formData.append("usageId", null);
        formData.append("childId", this.childId);
        HTTP.post("answers", formData, {
          headers: {
            "content-type": "multipart/form-data"
          }
        })
          .then(() => {
            this.answeredQuestionIdList.push(appId);
            this.$notify({
              group: "bottom",
              type: "success",
              text:
                "<i class='mdi mdi-check-circle-outline'></i> Çalışıldı olarak işaretlendi.",
              duration: 4000
            });
          })
          .catch(() => {
            this.$notify({
              group: "general",
              type: "error",
              text: "Kaydedilirken bir hata oluştu, lütfen tekrar deneyin.",
              duration: 4000
            });
          });
      }
    }
  }
};
</script>

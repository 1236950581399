var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"carousel slide just-slide conversation-forest"},[(_vm.routeName != 'AppChildSide')?_c('ol',{staticClass:"carousel-thumbs"},[_c('div',{staticClass:"float-right carousel-control"},[(_vm.routeName != 'AppChildSide')?_c('button',{staticClass:"btn btn-secondary btn-circle",on:{"click":function($event){_vm.activeSlide != 0 ? _vm.activeSlide-- : '';
            _vm.syncSlide();}}},[_c('i',{staticClass:"fas fa-angle-left"})]):_vm._e(),(_vm.routeName != 'AppChildSide')?_c('button',{staticClass:"btn btn-secondary btn-circle",on:{"click":function($event){_vm.activeSlide != _vm.steps.length - 1 ? _vm.activeSlide++ : '';
            _vm.syncSlide();}}},[_c('i',{staticClass:"fas fa-angle-right"})]):_vm._e()]),_vm._l((_vm.steps),function(app,index){return _c('li',{key:index,class:{ active: _vm.activeSlide == index },on:{"click":function($event){_vm.activeSlide = index;
          _vm.syncSlide();}}},[_c('div',{staticClass:"directive"},[_vm._v(" "+_vm._s(index + 1)+". "),(app.directive)?_c('div',{domProps:{"innerHTML":_vm._s(app.directive.substr(0, 8))}}):_vm._e()]),(app.img)?_c('img',{staticClass:"d-block img-fluid",attrs:{"src":app.img,"title":app.directive,"alt":app.directive}}):_vm._e(),(!app.img)?_c('div',{staticClass:"no-image",attrs:{"title":app.directive}},[_c('span',{staticClass:"appindex"},[_vm._v(" "+_vm._s(index + 1)+" ")])]):_vm._e()])}),_c('div',{staticClass:"clearfix"})],2):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 p-0 text-left"},[(_vm.$route.name == 'App')?_c('button',{staticClass:"btn btn-primary mr-3 ml-3",on:{"click":_vm.replay}},[_c('i',{staticClass:"fas fa-redo-alt"}),_vm._v(" Yeniden Oyna ")]):_vm._e()])]),_c('div',[_c('transition',{attrs:{"name":"fade","enter-active-class":"animate__animated animate__fadeIn"}},[_vm._l((_vm.steps),function(app,index){return [(_vm.activeSlide == index)?_c('div',{key:index},[_c('div',{staticClass:"mb-4 mt-4 bold",domProps:{"innerHTML":_vm._s(app.directive)}}),(_vm.activeSlide == 0)?_c('div',{staticClass:"select-places"},_vm._l((app.options),function(place,index){return _c('button',{key:index,staticClass:"btn btn-outline-primary mr-3 ml-3",class:{ selectedPLace: _vm.answers.place.title == place.title },on:{"click":function($event){_vm.answers.place = place;
                  _vm.syncAnswers();}}},[_vm._v(" "+_vm._s(place.title)+" ")])}),0):_vm._e(),(_vm.activeSlide == 1)?_c('div',{staticClass:"select-person"},[_c('div',{staticClass:"row"},[_vm._l((app.options),function(person,index){return [_c('div',{key:index,staticClass:"col-3 text-center",class:[
                      { selectedChild: _vm.answers.childPersonIndex == index },
                      { selectedExpert: _vm.answers.expertPersonIndex == index }
                    ]},[(person.img)?_c('img',{staticClass:"d-block",attrs:{"src":person.img},on:{"click":function($event){return _vm.selectPerson(index)}}}):_vm._e(),_c('button',{staticClass:"btn btn-primary mr-2 ml-2 mt-3",on:{"click":function($event){return _vm.selectPerson(index)}}},[_vm._v(" "+_vm._s(person.title ? person.title : "Seç")+" ")])])]})],2)]):_vm._e(),(_vm.activeSlide > 1)?_c('div',{staticClass:"select-words pt-3",style:({
                'background-image': 'url(' + _vm.answers.place.img + ')'
              })},[_c('div',{staticClass:"d-block buttons"},_vm._l((app.options),function(option,index){return _c('button',{key:index,staticClass:"btn btn-secondary btn-round waves-effect waves-light bg-white m-2",class:{ clicked: option.clicked },on:{"click":function($event){option.clicked = true;
                    _vm.syncOption(index);}}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0),(_vm.told.child)?_c('div',{staticClass:"speech left"},[_vm._v(" "+_vm._s(_vm.told.child)+" ")]):_vm._e(),_c('img',{staticClass:"child",class:[
                  { right: _vm.answers.childPersonIndex >= 2 },
                  { leftFlip: _vm.answers.childPersonIndex >= 2 }
                ],attrs:{"src":_vm.childImage,"alt":"Danışan"}}),(_vm.told.expert)?_c('div',{staticClass:"speech right"},[_vm._v(" "+_vm._s(_vm.told.expert)+" ")]):_vm._e(),_c('img',{staticClass:"expert",class:[
                  { right: _vm.answers.expertPersonIndex >= 2 },
                  { rightFlip: _vm.answers.expertPersonIndex < 2 }
                ],attrs:{"src":_vm.expertImage,"alt":"Uzman"}})]):_vm._e()]):_vm._e()]})],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
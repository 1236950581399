<template>
  <div>
    <div class="carousel slide just-slide">
      <ol class="carousel-thumbs" v-if="routeName != 'AppChildSide'">
        <div class="float-right carousel-control">
          <button
            class="btn btn-secondary btn-circle"
            v-if="routeName != 'AppChildSide'"
            @click="
              activeSlide != 0 ? activeSlide-- : '';
              syncSlide();
            "
          >
            <i class="fas fa-angle-left"></i>
          </button>
          <button
            class="btn btn-secondary btn-circle"
            v-if="routeName != 'AppChildSide'"
            @click="
              activeSlide != steps.length - 1 ? activeSlide++ : '';
              syncSlide();
            "
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </div>
        <li
          v-for="(app, index) in steps"
          :key="index"
          :class="{ active: activeSlide == index }"
          @click="
            activeSlide = index;
            syncSlide();
          "
        >
          <div class="directive">
            {{ index + 1 }}.

            <div v-html="app.directive.substr(0, 8)" v-if="app.directive"></div>
          </div>
          <img
            class="d-block img-fluid"
            v-if="app.img"
            :src="app.img"
            :title="app.directive"
            :alt="app.directive"
          />
          <div class="no-image" :title="app.directive" v-if="!app.img">
            <span class="appindex"> {{ index + 1 }} </span>
          </div>
        </li>

        <div class="clearfix"></div>
      </ol>
      <div>
        <transition
          name="fade"
          enter-active-class="animate__animated animate__fadeIn"
        >
          <template
            v-for="(app, index) in steps"
            :class="{ active: activeSlide == index }"
          >
            <div
              v-if="activeSlide == index"
              :key="index"
              :class="{ 'just-text': !app.img }"
            >
              <div v-if="app.type == 1">
                <div v-html="app.directive"></div>

                <img
                  class="d-block img-fluid"
                  v-if="app.img"
                  :src="app.img"
                  :alt="app.directive"
                />
              </div>
              <div
                v-if="app.type == 2"
                class="col-sm-8 offset-sm-2 text-center"
              >
                <div class="row">
                  <div class="col-12">
                    <img
                      src="/images/application/sdbbcb/ofke-yanardagi-1.jpg"
                      alt=""
                    />
                  </div>
                  <div class="col-12">
                    <input
                      type="text"
                      v-model="answers[0]"
                      class="form-control text-center mt-2"
                      placeholder="Buraya yazabilirsin."
                      @change="sync()"
                    />
                  </div>
                  <div class="col-12">
                    <img
                      src="/images/application/sdbbcb/ofke-yanardagi-2.jpg"
                      alt=""
                    />
                  </div>
                  <div class="col-12">
                    <input
                      type="text"
                      v-model="answers[1]"
                      class="form-control text-center mt-2"
                      placeholder="Buraya yazabilirsin."
                      @change="sync()"
                    />
                  </div>
                  <div class="col-12">
                    <img
                      src="/images/application/sdbbcb/ofke-yanardagi-3.jpg"
                      alt=""
                    />
                  </div>
                  <div class="col-12">
                    <input
                      type="text"
                      v-model="answers[2]"
                      class="form-control text-center mt-2"
                      placeholder="Buraya yazabilirsin."
                      @change="sync()"
                    />
                  </div>
                  <div class="col-12">
                    <img
                      src="/images/application/sdbbcb/ofke-yanardagi-4.jpg"
                      alt=""
                    />
                  </div>
                  <div class="col-12">
                    <input
                      type="text"
                      v-model="answers[3]"
                      class="form-control text-center mt-2"
                      placeholder="Buraya yazabilirsin."
                      @change="sync()"
                    />
                  </div>
                  <div class="col-12">
                    <img
                      src="/images/application/sdbbcb/ofke-yanardagi-5.jpg"
                      alt=""
                    />
                  </div>
                  <div class="col-12">
                    <input
                      type="text"
                      v-model="answers[4]"
                      class="form-control text-center mt-2"
                      placeholder="Buraya yazabilirsin."
                      @change="sync()"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/components/event-bus";
export default {
  name: "VolcanoInputs",
  props: ["application"],
  data() {
    return {
      routeName: this.$route.name,
      activeSlide: -1,
      steps: null,
      answers: []
    };
  },
  mounted() {
    this.steps = JSON.parse(this.application.steps).items;

    EventBus.$on("getAnswers", () => {
      this.saveAnswers();
    });
  },
  beforeDestroy: function() {
    EventBus.$off("getAnswers");
  },
  sockets: {
    setActiveSlide: function(data) {
      if (data.applicationId == this.application.id) {
        this.activeSlide = data.activeSlideIndex;
      }
    },
    syncAnswers: function(data) {
      if (data.applicationId == this.application.id) {
        this.answers = data.answers;
      }
    }
  },
  methods: {
    syncSlide: function() {
      this.$socket.client.emit("setActiveSlide", {
        to: this.$route.params.id,
        activeSlideIndex: this.activeSlide,
        applicationId: this.application.id
      });
    },
    saveAnswers: function() {
      EventBus.$emit("saveAnswers", this.answers);
    },
    sync: function() {
      this.$socket.client.emit("syncAnswers", {
        to: this.$route.params.id,
        answers: this.answers,
        applicationId: this.application.id
      });
    }
  },
  watch: {
    application: {
      deep: true,
      handler() {
        if (this.application.steps) {
          this.activeSlide = -1;
          this.steps = JSON.parse(this.application.steps).items;
        }
      }
    }
  }
};
</script>

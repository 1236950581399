<template>
  <!-- Left Sidenav -->
  <div class="left-sidenav">
    <!-- LOGO -->
    <div class="topbar-left">
      <router-link class="logo" :to="{ name: 'Home' }">
        <span>
          <img
            src="@/assets/images/logo.svg"
            alt="logo-small"
            class="logo-md"
          />
        </span>
        <span>
          <img
            src="@/assets/images/logo.svg"
            alt="logo-large"
            class="logo-lg logo-light"
          />
        </span>
      </router-link>
    </div>
    <!--end logo-->
    <div class="leftbar-profile p-3 w-100 hidden"></div>
    <ul class="metismenu left-sidenav-menu slimscroll">
      <li class="leftbar-menu-item">
        <router-link class="menu-link" :to="{ name: 'Home' }">
          <i
            data-feather="home"
            class="align-self-center vertical-menu-icon icon-dual-vertical"
          ></i>
          <span>Panel</span>
        </router-link>
      </li>
      <li class="leftbar-menu-item">
        <router-link class="menu-link" :to="{ name: 'Childs' }">
          <i
            data-feather="user-check"
            class="align-self-center vertical-menu-icon icon-dual-vertical"
          ></i>
          <span>Danışanlar</span>
        </router-link>
      </li>
      <li class="leftbar-menu-item">
        <router-link class="menu-link" :to="{ name: 'Usages' }">
          <i
            data-feather="layers"
            class="align-self-center vertical-menu-icon icon-dual-vertical"
          ></i>
          <span>Uygulanan Eğitimler</span>
        </router-link>
      </li>
      <li class="leftbar-menu-item" >
        <router-link
          class="menu-link"
          :to="{ name: 'ClientDetail', params: { id: authUser.firmId } }"
        >
          <i
            data-feather="grid"
            class="align-self-center vertical-menu-icon icon-dual-vertical"
          ></i>
          <span>Kurumum</span>
        </router-link>
      </li>
      <li class="leftbar-menu-item" v-if="role == 1">
        <router-link class="menu-link" :to="{ name: 'Clients' }">
          <i
            data-feather="users"
            class="align-self-center vertical-menu-icon icon-dual-vertical"
          ></i>
          <span>Kurum Yönetimi</span>
        </router-link>
      </li>
    </ul>
  </div>
  <!-- end left-sidenav-->
</template>

<script>
export default {
  name: "sidebar",
  data() {
    return {
      authUser: JSON.parse(localStorage.getItem("user")),
      role: localStorage.getItem("role")
    };
  }
};
</script>

<template>
  <div class="page-wrapper">
    <!-- Page Content-->
    <div class="page-content-tab">
      <div class="container-fluid home">
        <!-- Page-Title -->
        <div class="row">
          <div class="col-sm-12">
            <div class="page-title-box">
              <h4 class="page-title">Panel</h4>
            </div>
            <!--end page-title-box-->

            <!-- Body -->

            <div class="row">
              <div class="col-4">
                <UsageInfoBox :clientUsageRights="firmUsages"></UsageInfoBox>
              </div>
              <div class="col-4">
                <div class="card">
                  <div class="card-body min-box">
                    <b class="mb-3 d-block">Uygulanan Eğitimler Grafiği</b>
                    <LineChart
                      :height="170"
                      :graphData="usagesChart"
                      v-if="usagesChart.length > 0"
                    ></LineChart>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="card">
                  <div class="card-body min-box">
                    <b> Danışan Yaş Dağılımı</b>
                    <DoughnutChart
                    class="mt-1"
                      :height="165"
                      :graphData="ages"
                      v-if="ages.length > 0"
                    >
                    </DoughnutChart>
                  </div>
                </div>
              </div>
            </div>

            <Childs :asComponent="true"></Childs>

            <!-- Body End -->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <!-- end page title end breadcrumb -->
      </div>
      <!-- container -->
      <footer-text></footer-text>
      <!--end footer-->
    </div>
    <!-- end page content -->
  </div>
</template>

<script>
import footer from "@/components/Footer";
import HTTP from "@/main-source";
import UsageInfoBox from "@/components/UsageInfoBox";
import Childs from "@/views/Childs";
import DoughnutChart from "@/components/charts/doughnut";
import LineChart from "@/components/charts/line";

export default {
  name: "Home",
  data() {
    return {
      authFirmId: JSON.parse(localStorage.getItem("user")).firmId,
      firmUsages: [],
      ages: [],
      usagesChart: []
    };
  },
  components: {
    "footer-text": footer,
    UsageInfoBox,
    Childs,
    DoughnutChart,
    LineChart
  },
  created() {
    this.getHome();
  },
  methods: {
    getHome() {
      HTTP.get("firms/" + this.authFirmId + "/usagerights/").then(result => {
        this.firmUsages = result.data;
      });
      HTTP.get("childs/ages").then(result => {
        this.ages = result.data;
      });
      HTTP.get("usages/graph").then(result => {
        this.usagesChart = result.data;
      });
    }
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"video-list"},_vm._l((_vm.videoList),function(item){return _c('div',{key:item.id,staticClass:"video-item",class:[
      { AppExpertSide: _vm.$route.name == 'App' },
      { AppChildSide: _vm.$route.name == 'AppChildSide' },
      { expertHide: _vm.expertHide },
      { childHide: _vm.childHide }
    ],attrs:{"video":item}},[_c('video',{ref:"videos",refInFor:true,class:[
        { local: item.type == 'local' },
        { remote: item.type != 'local' }
      ],attrs:{"autoplay":"","playsinline":"","height":_vm.cameraHeight,"id":item.id},domProps:{"muted":item.muted}})])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <!-- Log In page -->
  <div class="container">
    <div class="row vh-100 ">
      <div class="col-12 align-self-center">
        <div class="auth-page">
          <div class="card auth-card shadow-lg">
            <div class="card-body">
              <div class="px-3">
                <div class="auth-logo-box">
                  <img
                    src="../assets/images/hand.svg"
                    height="55"
                    alt="logo"
                    class="auth-logo"
                  />
                </div>
                <!--end auth-logo-box-->
                <div class="col-12 text-center mt-5">
                  <img src="../assets/images/login-logo.svg" />
                </div>

                <div class="text-center auth-logo-text">
                  <h4 class="mb-3 mt-4">Giriş Yapın</h4>
                </div>
                <!--end auth-logo-text-->

                <form
                  class="form-horizontal auth-form my-4"
                  action="index.html"
                >
                  <div class="form-group">
                    <label for="username">Email</label>
                    <div class="input-group mb-3">
                      <span class="auth-form-icon">
                        <i class="dripicons-user"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="username"
                        placeholder="Email"
                        v-model="user.email"
                        @keyup.enter="login"
                        @blur="$v.user.email.$touch()"
                        :class="{ 'is-invalid': $v.user.email.$error }"
                      />
                      <div class="invalid-feedback">
                        Lütfen geçerli bir email adresi girin.
                      </div>
                    </div>
                  </div>
                  <!--end form-group-->

                  <div class="form-group">
                    <label for="userpassword">Şifre</label>
                    <div class="input-group mb-3">
                      <span class="auth-form-icon">
                        <i class="dripicons-lock"></i>
                      </span>
                      <input
                        type="password"
                        class="form-control"
                        id="userpassword"
                        placeholder="Şifre"
                        v-model="user.password"
                        @keyup.enter="login"
                        @blur="$v.user.password.$touch()"
                        :class="{ 'is-invalid': $v.user.password.$error }"
                      />
                      <div class="invalid-feedback">
                        Lütfen parolanızı girin.
                      </div>
                    </div>
                  </div>
                  <!--end form-group-->

                  <div class="form-group row mt-4">
                    <div class="col-sm-6">
                      <div class="custom-control custom-switch switch-success">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customSwitchSuccess"
                        />
                        <label
                          class="custom-control-label text-muted"
                          for="customSwitchSuccess"
                          v-if="false"
                          >Remember me</label
                        >
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-sm-6 text-right" v-if="false">
                      <a href="#" class="text-muted font-13">Şifremi Unuttum</a>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end form-group-->

                  <div class="form-group mb-0 row">
                    <div
                      class="col-12 text-danger text-center"
                      v-if="badCredentials"
                    >
                      Kullanıcı adı veya şifre yanlış.
                    </div>
                    <div
                      class="col-12 text-danger text-center"
                      v-if="userNotFound"
                    >
                      Kullanıcı bulunamadı veya aktif değil.
                    </div>
                    <div class="col-12 mt-2">
                      <button
                        class="btn btn-gradient-primary btn-round
                        btn-block waves-effect waves-light"
                        type="button"
                        @click="login"
                      >
                        Giriş <i class="fas fa-sign-in-alt ml-1"></i>
                      </button>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end form-group-->
                </form>
                <!--end form-->
              </div>
              <!--end /div-->
            </div>
            <!--end card-body-->
          </div>
          <!--end card-->

          <!--end account-social-->
        </div>
        <!--end auth-page-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- End Log In page -->
</template>

<script>
import router from "@/router";
import HTTP from "@/main-source";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
  name: "Login",
  data() {
    return {
      user: {
        email: null,
        password: null
      },
      badCredentials: false,
      userNotFound: false
    };
  },
  validations: {
    user: {
      email: { required, email },
      password: { required, minLength: minLength(5) }
    }
  },
  created() {
    document.body.classList.add("account-body", "accountbg");
  },
  beforeDestroy() {
    document.body.classList.remove("account-body", "accountbg");
  },
  methods: {
    login() {
      this.$v.user.$touch();

      if (!this.$v.user.$error) {
        this.badCredentials = false;
        this.userNotFound = false;
        HTTP.post("/users/login", this.user)
          .then(result => {
            if (result.status === 202) {
              HTTP.defaults.headers.common.Authorization = `Bearer ${result.data.token}`;
              let user = {
                name: result.data.name,
                profileImg: result.data.profileImg,
                firmId: result.data.firm,
                userId: result.data.userId
              };

              localStorage.setItem("token", result.data.token);
              localStorage.setItem("user", JSON.stringify(user));
              localStorage.setItem("role", result.data.role);
              router.push({ name: "Home" });
            }
            // console.log(result);
          })
          .catch(er => {
            if (er.response.status === 401) {
              this.badCredentials = true;
            }

            if (er.response.status === 404) {
              this.userNotFound = true;
            }
            return false;
          });
      }
      //   router.push({ name: 'Home' });
    }
  }
};
</script>

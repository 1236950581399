<template>
  <ul class="metismenu left-sidenav-menu slimscroll">
    <li class="title">
      Uygulama
    </li>

    <li
      class="leftbar-menu-item"
      v-for="(category, index) in categories"
      :key="category.id"
      aria-expanded="false"
    >
      <a class="menu-link">
        {{ index + 1 }}. {{ category.name }}
        <span class="menu-arrow">
          <i class="mdi mdi-chevron-right"></i>
        </span>
      </a>
      <ul class="nav-second-level" aria-expanded="false">
        <li
          class="nav-item"
          v-for="(subcategory, index) in category.categories"
          :key="subcategory.id"
        >
          <a class="nav-link"
            >{{ index + 1 }}. {{ subcategory.name }}
            <span class="menu-arrow">
              <i class="mdi mdi-chevron-right"></i>
            </span>
          </a>
          <ul class="nav-second-level" aria-expanded="false">
            <li class="nav-item">
              <a
                class="nav-link application-link"
                @click="showWarmingQuestions(subcategory.warming_questions)"
              >
                Isınma Soruları
              </a>
            </li>
            <li
              class="nav-item"
              v-for="(application, index) in filterCategory(subcategory.id)"
              :key="application.id"
            >
              <a
                class="nav-link application-link"
                :class="{ activeApplication: activeApplicationId == application.id }"
                @click="getApplication(application.id)"
                :title="application.directive"
                >{{ index + 1 }}. {{ application.name }}
                <button
                  type="button"
                  class="btn btn-secondary btn-circle btn-send-application"
                  @click.prevent.stop
                  @click="sendToChild(application.id)"
                >
                  <i class="far fa-paper-plane"></i>
                </button>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import EventBus from "@/components/event-bus";

export default {
  name: "ApplicationMenu",
  props: ["categories", "applications"],
  data() {
    return {
      activeApplicationId: null
    };
  },
  methods: {
    filterCategory: function(categoryId) {
      return this.applications.filter(
        application => application.categoryId == categoryId
      );
    },
    sendToChild: function(applicationId) {
      this.activeApplicationId = applicationId;
      EventBus.$emit("applicationSetAndSendChild", applicationId);
    },
    getApplication: function(applicationId) {
      EventBus.$emit("getApplication", applicationId);
    },
    showWarmingQuestions: function(questions) {
      EventBus.$emit("show-warming-questions", questions);
    }
  },
  mounted() {
    setTimeout(() => {
      window.initMetisMenu();
    }, 750);
  }
};
</script>

<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click="close">
        <div class="modal-container" @click.stop.prevent>
          <div class="row head col-12">
            <div class="col">
              <!-- <i class="fas fa-exclamation-triangle"></i> -->
              <slot name="title">Attention</slot>
            </div>
            <div class="col text-right">
              <i class="fas fa-times" @click="close"></i>
            </div>
          </div>

          <div class="modal-body">
            <slot name="body"></slot>
          </div>
          <div class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  methods: {
    close: function() {
      this.$emit("close");
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/colors.scss";

.modal-mask {
  position: fixed;
  z-index: 1002;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: table;
  transition: opacity 0.3s ease;
  -webkit-backdrop-filter: saturate(180%) blur(3px);
  backdrop-filter: saturate(180%) blur(3px);
  background-color: rgba(245, 245, 245, 0.4);
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 400px;
  margin: 0px auto;
  //   padding: 20px 30px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.modal-header {
  margin-top: 0;
  color: #42b983;
  padding: 15px 20px;
}

.modal-body {
  // margin: 10px 0;
}

.modal-enter {
  opacity: 0;
}
.modal-footer {
  padding: 5px 15px 15px 15px;
  // flex-direction: row-reverse;
  justify-content: flex-end;
  display: flex;
  button {
    margin-left: 10px;
    padding: 5px 20px;
  }
}
.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
}
.modal-mask {
  .head {
    padding: 15px 5px;
    border-bottom: 1px solid darken($white, 5%);
    margin: 0;
    font-size: 14px;
    color: darken($white, 45%);
    text-align: left;
    .fa-times {
      cursor: pointer;
    }
  }

  .actions {
    margin: 0px 15px 15px 15px;
    .btn {
      margin: 5px;
      font-size: 14px;
    }
    .btn-primary {
      // background: $brandcolor !important;
    }
  }
}
</style>

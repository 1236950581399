<template>
  <div class="page-wrapper">
    <!-- Page Content-->
    <div
      class="page-content-tab c-size childwrap no-selection"
      :class="{
        'set-img-height':
          activeApplication != null ? activeApplication.type != 6 : false
      }"
    >
      <div class="container-fluid">
        <!-- Body -->
        <div
          class="col-12 col-sm-10 offset-sm-1"
          oncopy="return false"
          oncut="return false"
        >
          <div class="card">
            <div class="card-body">
              <div
                id="play-area"
                class="play-area child-side"
                ref="playArea"
                :class="{ 'sobece-bg': applicationType == null }"
              >
                <div
                  v-if="false"
                  class="mouse"
                  :style="{ left: mouseX + 'px', top: mouseY + 'px' }"
                ></div>

                <div
                  id="area"
                  class="wrapper"
                  :class="{ 'centered-content': !isClientConnetected }"
                  @mousemove="mousemove"
                >
                  <div class="main-directive">
                    <p
                      class="lead text-center"
                      v-if="activeApplication"
                      v-html="activeApplication.directive"
                    ></p>
                  </div>
                  <component
                    v-if="showApplication"
                    :is="applicationType"
                    :application="activeApplication"
                  ></component>

                  <!-- Animations -->
                  <animations
                    :animations="animations"
                    v-if="animations.show"
                  ></animations>
                  <!-- Animations -->

                  <!-- Test Area -->
                  <div class="test" v-if="false">
                    <button @click="connect">
                      Bağlan
                    </button>
                    {{ active }}
                  </div>
                  <!-- Test Area End -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import animations from "@/components/application/Animations";
// Applications
import OnlyText from "@/components/application/types/1";
import Draw from "@/components/application/types/2";
import Slide from "@/components/application/types/3";
import TrueFalseCastle from "@/components/application/types/4";
import FindOnImage from "@/components/application/types/6";
import FillText from "@/components/application/types/7";
import DragAndOrder from "@/components/application/types/8";
import SlideDragAndOrderImage from "@/components/application/types/9";
import TimeTableDragAndOrder from "@/components/application/types/10";
import DoOrNotList from "@/components/application/types/11";
import TodoListWithTime from "@/components/application/types/12";
import Trello from "@/components/application/types/13";
import EventWeekCalendar from "@/components/application/types/14";
import HasOptionAndRightAnswer from "@/components/application/types/15";
import CustomAppStatusPerson from "@/components/application/types/16";
import CustomTwoInputSlide from "@/components/application/types/17";
import MixedCarousel from "@/components/application/types/18";
import TagCloud from "@/components/application/types/19";
import SingleToDoAndOrder from "@/components/application/types/20";
import VolcanoInputs from "@/components/application/types/21";
import SolutionsKit from "@/components/application/types/22";
import TrafficLights from "@/components/application/types/23";
import ConversationForest from "@/components/application/types/24";

export default {
  name: "AppChildSide",
  data() {
    return {
      usageId: this.$route.params.id,
      mouseX: 20,
      mouseY: 90,
      active: "Bekleniyor",
      offsetLeft: null,
      offsetTop: null,
      isClientConnetected: false,
      // activeComponent: "OnlyText",
      // activeComponent: "Draw",
      applicationType: null,
      activeApplication: null,
      showApplication: false,
      canISendMouse: false,
      animations: {
        text: "TEBRİKLER",
        gif: "fireworks",
        sounds: true,
        anim: null,
        show: false
      }
    };
  },
  created() {
    this.connect();

    setInterval(() => {
      this.canISendMouse = !this.canISendMouse;
    }, 750);
  },
  destroyed() {
    this.$socket.client.emit("leave-room", { to: this.usageId });
  },
  components: {
    animations,
    OnlyText,
    Draw,
    Slide,
    TrueFalseCastle,
    FindOnImage,
    FillText,
    DragAndOrder,
    SlideDragAndOrderImage,
    TimeTableDragAndOrder,
    DoOrNotList,
    TodoListWithTime,
    Trello,
    EventWeekCalendar,
    HasOptionAndRightAnswer,
    CustomAppStatusPerson,
    CustomTwoInputSlide,
    MixedCarousel,
    TagCloud,
    SingleToDoAndOrder,
    VolcanoInputs,
    SolutionsKit,
    TrafficLights,
    ConversationForest
  },
  sockets: {
    connect() {
      // console.log("socket connected");
    },
    childConnected: function() {
      this.isClientConnetected = true;
    },
    setActive(val) {
      this.active = val;
    },
    setActiveApplication: function(application) {
      this.showApplication = false;
      this.activeApplication = application;
      if (this.activeApplication) {
        this.applicationType = this.$setApplicationType(
          this.activeApplication.type
        );
      } else {
        this.applicationType = null;
      }
      setTimeout(() => {
        this.showApplication = true;
      }, 50);
    },
    showAnim: function(animations) {
      this.animations = animations;
      setTimeout(() => {
        this.animations.show = false;
      }, 3500);
    },
    showAlert: function(data) {
      if (data) {
        if (data.alert.last == 5) {
          Swal.fire({
            icon: "info",
            text: "Son 5 dakika",
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: "Tamam"
          });
        } else {
          Swal.fire({
            icon: "info",
            text: "Son 2 dakika",
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: "Tamam"
          });
        }
      }
    },
    sessionEnd: function() {
      this.activeApplication = null;
      Swal.fire({
        icon: "success",
        text: "Oturum tamamlandı, yönlendiriliyorsunuz.",
        timer: 5000,
        showCancelButton: false,
        showConfirmButton: false
      });
      setTimeout(() => {
        window.location = "https://sobece.com/";
      }, 5000);
    }
  },
  methods: {
    connect: function() {
      this.$socket.client.emit("get-room-clients", { name: this.usageId });

      this.$socket.client.emit("connect-room", {
        nickname: "osman",
        room: this.usageId,
        to: this.usageId
      });
    },
    getRelativeCoordinates(event, referenceElement) {
      const position = {
        x: event.pageX,
        y: event.pageY
      };

      const offset = {
        left: referenceElement.offsetLeft,
        top: referenceElement.offsetTop
      };

      let reference = referenceElement.offsetParent;

      while (reference) {
        offset.left += reference.offsetLeft;
        offset.top += reference.offsetTop;
        reference = reference.offsetParent;
      }

      return {
        x: position.x - offset.left,
        y: position.y - offset.top
      };
    },
    mousemove: function(event) {
      // if (this.isClientConnetected && this.canISendMouse) {
      var area = document.getElementById("area");

      this.mouseX = this.getRelativeCoordinates(event, area).x;
      this.mouseY = this.getRelativeCoordinates(event, area).y;

      let data = {
        clientX: this.getRelativeCoordinates(event, area).x,
        clientY: this.getRelativeCoordinates(event, area).y,
        isClicked: event.buttons
      };

      this.$socket.client.emit("mouse-move-bithces", {
        to: this.usageId,
        event: data
      });
      // }
    }
  }
};
</script>

<template>
  <div class="row">
    <div class="col-sm-8 col-12 offset-2">
      <p>
        Aşağıdaki maddeleri sürükle bırak ile sıralayınız.
      </p>

      <draggable
        v-model="list"
        @end="sync"
        ghost-class="ghost"
        v-bind="dragOptions"
      >
        <div
          class="card task-box p-2"
          v-for="(element, index) in list"
          :key="element.order"
        >
          <span class="h5">{{ index + 1 }}. {{ element.name }}</span>
          <i class="fas fa-align-justify"></i>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import EventBus from "@/components/event-bus";

export default {
  name: "DragAndOrder",
  components: {
    draggable
  },
  props: ["application"],
  data() {
    return {
      list: JSON.parse(this.application.steps).items.map((name, index) => {
        return { name, order: index + 1 };
      })
    };
  },
  mounted() {
    EventBus.$on("getAnswers", () => {
      this.saveAnswers();
    });
  },
  beforeDestroy: function() {
    EventBus.$off("getAnswers");
  },
  sockets: {
    syncAnswers: function(answers) {
      if (this.application.id == answers.applicationId) {
        this.list = answers.answers;
      }
    }
  },
  methods: {
    sort() {
      this.list = this.list.sort((a, b) => a.order - b.order);
    },
    saveAnswers: function() {
      EventBus.$emit("saveAnswers", this.list);
    },
    sync: function() {
      this.$socket.client.emit("syncAnswers", {
        to: this.$route.params.id,
        answers: this.list,
        applicationId: this.application.id
      });
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  }
};
</script>

<template>
  <div>
    <div class="carousel slide just-slide right-answer">
      <ol class="carousel-thumbs" v-if="routeName != 'AppChildSide'">
        <div class="float-right carousel-control">
          <button
            class="btn btn-secondary btn-circle"
            v-if="routeName != 'AppChildSide'"
            @click="
              activeSlide != 0 ? activeSlide-- : '';
              syncSlide();
            "
          >
            <i class="fas fa-angle-left"></i>
          </button>
          <button
            class="btn btn-secondary btn-circle"
            v-if="routeName != 'AppChildSide'"
            @click="
              activeSlide != steps.length - 1 ? activeSlide++ : '';
              syncSlide();
            "
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </div>
        <li
          v-for="(app, index) in steps"
          :key="index"
          :class="{ active: activeSlide == index }"
          @click="
            activeSlide = index;
            syncSlide();
          "
        >
          <div class="directive">
            {{ index + 1 }}.

            <div v-html="app.directive.substr(0, 8)" v-if="app.directive"></div>
          </div>
          <img
            class="d-block img-fluid"
            v-if="app.img"
            :src="app.img"
            :title="app.directive"
            :alt="app.directive"
          />
          <div class="no-image" :title="app.directive" v-if="!app.img">
            <span class="appindex"> {{ index + 1 }} </span>
          </div>
        </li>

        <div class="clearfix"></div>
      </ol>
      <div>
        <transition
          name="fade"
          enter-active-class="animate__animated animate__fadeIn"
        >
          <template
            v-for="(app, index) in steps"
            :class="{ active: activeSlide == index }"
          >
            <div
              v-if="activeSlide == index"
              :key="index"
              :class="{ 'just-text': !app.img }"
            >
              <div v-html="app.directive"></div>

              <img
                class="d-block img-fluid"
                v-if="app.img"
                :src="app.img"
                :alt="app.directive"
              />
              <div class="d-flex mt-3">
                <button
                  class="col btn btn-outline-blue btn-round waves-effect waves-light m-2"
                  v-for="(option, index) in app.options"
                  :key="index"
                  :class="{ clicked: option.clicked }"
                  @click="syncOption(index)"
                >
                  {{ option.option }}
                </button>
              </div>
            </div>
          </template>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/components/event-bus";
export default {
  name: "hasOptionAndRightAnswer",
  props: ["application"],
  data() {
    return {
      routeName: this.$route.name,
      activeSlide: -1,
      steps: null
    };
  },
  mounted() {
    this.steps = JSON.parse(this.application.steps).items;

    EventBus.$on("getAnswers", () => {
      this.saveAnswers();
    });
  },
  beforeDestroy: function() {
    EventBus.$off("getAnswers");
  },
  sockets: {
    setActiveSlide: function(data) {
      if (data.applicationId == this.application.id) {
        this.activeSlide = data.activeSlideIndex;
      }
    },
    syncOption: function(data) {
      if (data.applicationId == this.application.id) {
        this.steps[this.activeSlide].options = this.steps[
          this.activeSlide
        ].options.map(option => {
          return {
            option: option.option,
            clicked: false
          };
        });
        this.steps[this.activeSlide].options[data.optionIndex].clicked = true;
      }
    }
  },
  methods: {
    syncSlide: function() {
      this.$socket.client.emit("setActiveSlide", {
        to: this.$route.params.id,
        activeSlideIndex: this.activeSlide,
        applicationId: this.application.id
      });
    },
    checkAnswer: function(index) {
      let animations = {
        text: "TEBRİKLER",
        gif: "applause",
        anim: null,
        sounds: true,
        show: true
      };

      if (this.steps[this.activeSlide].rightAnswerIndex != index) {
        animations.text = "TEKRAR DENE";
        animations.gif = "try-again";
      }
      this.$socket.client.emit("showAnim", {
        to: this.$route.params.id,
        animations: animations
      });
    },
    saveAnswers: function() {
      EventBus.$emit("saveAnswers");
    },
    syncOption: function(optionIndex) {
      this.steps[this.activeSlide].options = this.steps[
        this.activeSlide
      ].options.map(option => {
        return {
          option: option.option,
          clicked: false
        };
      });
      this.steps[this.activeSlide].options[optionIndex].clicked = true;
      let data = {
        to: this.$route.params.id,
        optionIndex,
        applicationId: this.application.id
      };
      this.$socket.client.emit("syncOption", data);
    }
  },
  watch: {
    application: {
      deep: true,
      handler() {
        if (this.application.steps) {
          this.activeSlide = -1;
          this.steps = JSON.parse(this.application.steps).items;
        }
      }
    }
  }
};
</script>

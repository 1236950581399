<script>
import { Bar } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  name: "BarChart",
  extends: Bar,
  props: ["graphData", "anim"],

  mounted() {
    let datasets = [
      {
        label: "Ön Test",
        backgroundColor:
          (this.graphData.type == "single" ||
            this.graphData.type == "first" ||
            this.graphData.type == "last") &&
          this.graphData.isSingleTypeFirst == true
            ? "#f48333"
            : "#992367",
        data: this.graphData.data,
        datalabels: {
          color: "#fff"
        }
      }
    ];

    if (this.graphData.type == "comparative") {
      datasets[0].backgroundColor = "#f48333";
      datasets.push({
        label: "Son Test",
        backgroundColor: "#992367",
        data: this.graphData.last,
        datalabels: {
          color: "#fff"
        }
      });
    }

    setTimeout(
      () => {
        this.renderChart(
          {
            // labels: ["İlişki Başlatma ve Sürdürme", "Atılganlık Becerileri", "Duygulara Yönelik Beceriler", "Saldırgan Davranışlar ve Dürtülerle Başa Çıkma Becerileri ", "Sorun Çözme Becerileri", "Plan Yapma Becerileri", "Grupla Etkileşim ve Bir İş Yürütme Becerileri "],
            labels: [
              "1. İBSB",
              "2. AB",
              "3. DYB",
              "4. SDDBÇB ",
              "5. SÇB",
              "6. PYB",
              "7. GEBİYB",
              "Sosyal Beceri Düzeyi"
            ],
            datasets
          },
          {
            legend: {
              display: this.graphData.type == "comparative" ? true : false
            },
            plugins: [ChartDataLabels],
            scales: {
              yAxes: [
                {
                  ticks: {
                    min: 0,
                    max: 100,
                    callback: function(value) {
                      return value + "%";
                    }
                  },
                  scaleLabel: {
                    display: false,
                    labelString: ""
                  }
                }
              ],
              xAxes: [
                {
                  barThickness: this.graphData.type == "comparative" ? 35 : 50
                }
              ]
            }
          }
        );
      },
      this.anim != false ? 300 : 5
    );
  }
};
</script>

<template>
  <div class="page-wrapper">
    <!-- Page Content-->
    <div class="page-content-tab">
      <div class="container-fluid">
        <!-- Page-Title -->
        <div class="row">
          <div class="col-sm-12">
            <div class="page-title-box">
              <h4 class="page-title">Profil</h4>
            </div>
            <!--end page-title-box-->

            <!-- Body -->

            <div class="row">
              <div class="col-sm-8">
                <div class="card">
                  <div class="card-body ">
                    <div class="met-profile">
                      <div class="row">
                        <div class="col-lg-7 align-self-center mb-3 mb-lg-0">
                          <div class="met-profile-main">
                            <div class="met-profile-main-pic">
                              <file-upload
                                ref="upload"
                                class="file profile-img"
                                v-model="image"
                                accept="image/png, image/gif, image/jpeg"
                                extensions="gif,jpg,jpeg,png"
                              >
                                <img
                                  :src="
                                    user.profileImg
                                      ? $apiDomain + user.profileImg
                                      : '/assets/images/users/user-4.jpg'
                                  "
                                  alt="Profil Fotoğrafı"
                                  class="rounded-circle c-size-prof"
                                />
                                <span class="fro-profile_main-pic-change">
                                  <i class="fas fa-camera"></i>
                                </span>
                              </file-upload>
                            </div>
                            <div>
                              <h4>{{ user.name }}</h4>
                              <p>
                                <span
                                  class="badge"
                                  :class="
                                    user.status
                                      ? 'badge-soft-success'
                                      : 'badge-soft-primary'
                                  "
                                  >{{ user.status ? "Aktif" : "Pasif" }}</span
                                >
                              </p>
                              <ul class="list-unstyled ">
                                <li class="">
                                  <i
                                    class="dripicons-phone mr-2 text-info font-18"
                                  ></i>
                                  {{ user.tel }}
                                </li>
                                <li class="mt-2">
                                  <i
                                    class="dripicons-mail text-info font-18 mt-2 mr-2"
                                  ></i>
                                  {{ user.email }}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <!--end col-->
                        <div class="col-lg-4 ml-auto"></div>
                        <!--end col-->
                      </div>
                      <!--end row-->
                    </div>
                    <!--end f_profile-->
                  </div>
                  <!--end card-body-->
                  <div class="card-body" v-if="false">
                    <ul
                      class="nav nav-pills mb-0"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          id="general_detail_tab"
                          data-toggle="pill"
                          href="#general_detail"
                          >General</a
                        >
                      </li>
                    </ul>
                  </div>
                  <!--end card-body-->
                </div>
                <div class="card">
                  <div class="card-body min-height-40">
                    <h4>Bilgi Güncelle</h4>
                    <div class="form-group mt-2">
                      <label>Ad Soyad</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        v-model="userDetail.name"
                        autocomplete="nope"
                        @blur="$v.userDetail.name.$touch()"
                        :class="{
                          'is-invalid': $v.userDetail.name.$error
                        }"
                      />
                      <div class="invalid-feedback">
                        Lütfen adsoyad girin.
                      </div>
                    </div>
                    <div class="form-group mt-2">
                      <label>Email</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        v-model="userDetail.email"
                        autocomplete="nope"
                        @blur="$v.userDetail.email.$touch()"
                        :class="{
                          'is-invalid': $v.userDetail.email.$error
                        }"
                      />
                      <div class="invalid-feedback">
                        Lütfen geçerli bir email adresi girin.
                      </div>
                    </div>
                    <div class="form-group mt-2">
                      <label>Telefon</label>
                      <the-mask
                        mask="0 (###) ### ## ##"
                        v-model="userDetail.tel"
                        class="form-control"
                        type="phone"
                        placeholder="Telefon"
                      ></the-mask>
                      <div class="invalid-feedback">
                        Lütfen geçerli bir telefon numarası girin.
                      </div>
                    </div>

                    <button
                      class="btn btn-primary btn-sm waves-effect waves-light float-right mt-3"
                      @click="updateUserInfo"
                    >
                      Bilgi Güncelle
                    </button>
                  </div>
                </div>
                <!--end card-->
              </div>

              <div class="col-sm-4">
                <div class="card">
                  <div class="card-body">
                    <h4>
                      Şifre Güncelle
                    </h4>
                    <div class="form-group mt-2">
                      <label>Yeni Şifre</label>
                      <input
                        type="password"
                        class="form-control"
                        placeholder="Yeni Şifre"
                        v-model="password"
                        autocomplete="nope"
                        @blur="$v.password.$touch()"
                        :class="{
                          'is-invalid': $v.password.$error
                        }"
                      />
                      <div class="invalid-feedback">
                        Lütfen yeni şifre girin.
                      </div>
                    </div>
                    <div class="form-group mt-2">
                      <label>Yeni Şifre Tekrar</label>

                      <input
                        type="password"
                        class="form-control"
                        placeholder="Yeni Şifre Tekrar"
                        v-model="passCheck"
                        autocomplete="nope"
                        @blur="$v.passCheck.$touch()"
                        :class="{
                          'is-invalid': $v.passCheck.$error
                        }"
                      />
                      <div class="invalid-feedback">
                        Lütfen yeni şifrenizi tekrar girin.
                      </div>
                    </div>
                    <button
                      class="btn btn-primary btn-sm waves-effect waves-light float-right mt-3"
                      @click="updatePassword"
                    >
                      Şifre Güncelle
                    </button>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->

            <!-- Body End -->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <!-- end page title end breadcrumb -->
      </div>
      <!-- container -->

      <footer-text></footer-text>
      <!--end footer-->
    </div>
    <!-- end page content -->
  </div>
</template>

<script>
import HTTP from "@/main-source";
import footer from "@/components/Footer";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import EventBus from "@/components/event-bus";

export default {
  name: "MyAccount",
  data() {
    return {
      user: [],
      userDetail: {
        name: null,
        email: null,
        tel: null
      },
      password: null,
      passCheck: null,
      image: []
    };
  },
  validations: {
    userDetail: {
      name: { required },
      email: { required, email }
    },
    password: { required, minLength: minLength(5) },
    passCheck: { sameAsPassword: sameAs("password") }
  },
  components: {
    "footer-text": footer
  },
  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      if (this.$route.params.id) {
        HTTP.get("users/" + this.$route.params.id).then(result => {
          this.user = result.data[0];
          this.userDetail.name = this.user.name;
          this.userDetail.email = this.user.email;
          this.userDetail.tel = this.user.tel;
        });
      } else {
        HTTP.get("users/me").then(result => {
          this.user = result.data[0];
          this.userDetail.name = this.user.name;
          this.userDetail.email = this.user.email;
          this.userDetail.tel = this.user.tel;
        });
      }
    },
    updatePassword() {
      this.$v.password.$touch();
      this.$v.passCheck.$touch();
      if (!this.$v.password.$error && !this.$v.passCheck.$error) {
        HTTP.patch("users/changepassword", {
          password: this.password,
          userId: this.user.id
        })
          .then(() => {
            this.password = null;
            this.passCheck = null;
            this.$v.password.$reset();
            this.$v.passCheck.$reset();
            Swal.fire({
              icon: "success",
              text: "Şifre başarıyla değiştirildi."
            });
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              text:
                "Şifre değiştirme sırasında hata oluştu, lütfen daha sonra tekrar deneyin."
            });
          });
      }
    },
    updateUserInfo() {
      this.$v.userDetail.$touch();

      if (!this.$v.userDetail.$error) {
        let data = {
          name: this.userDetail.name,
          email: this.userDetail.email
        };
        if (this.userDetail.tel) {
          data.tel = "0" + this.userDetail.tel;
        }
        HTTP.put("users/me", data)
          .then(() => {
            let authUser = JSON.parse(localStorage.getItem("user"));

            authUser.name = this.userDetail.name;

            localStorage.setItem("user", JSON.stringify(authUser));
            EventBus.$emit("updateAuthUserInfo", true);

            Swal.fire({
              icon: "success",
              text: "Bilgileriniz başarıyla güncellendi."
            });
          })
          .catch(e => {
            console.log(e);
            if (e.response.status == 409) {
              Swal.fire({
                icon: "warning",
                text: "E-mail daha önce kaydedilmiş"
              });
            }
            if (e.response.status == 412) {
              Swal.fire({
                icon: "warning",
                text: "Lütfen girdiğiniz bilgileri kontrol edin."
              });
            }
          });
      }
    }
  },
  watch: {
    $route: function() {
      this.getUser();
    },
    image: function() {
      let fd = new FormData();
      fd.append("files", this.image[0].file, this.image[0].name);

      HTTP.post("/files/", fd, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
        .then(res => {
          HTTP.patch("users/" + this.user.id + "/profilephoto", {
            profileImg: res.data[0].path
          }).then(() => {
            this.user.profileImg = res.data[0].path;
            let authUser = JSON.parse(localStorage.getItem("user"));
            if (authUser.userId == this.user.id) {
              authUser.profileImg = this.user.profileImg;
              localStorage.setItem("user", JSON.stringify(authUser));
              EventBus.$emit("updateAuthUserInfo", true);
            }
          });
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>

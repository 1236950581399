<template>
  <div>
    <div class="carousel slide just-slide">
      <ol class="carousel-thumbs" v-if="routeName != 'AppChildSide'">
        <div class="float-right carousel-control">
          <button
            class="btn btn-secondary btn-circle"
            v-if="routeName != 'AppChildSide'"
            @click="
              activeSlide != 0 ? activeSlide-- : '';
              syncSlide();
            "
          >
            <i class="fas fa-angle-left"></i>
          </button>
          <button
            class="btn btn-secondary btn-circle"
            v-if="routeName != 'AppChildSide'"
            @click="
              activeSlide != steps.length - 1 ? activeSlide++ : '';
              syncSlide();
            "
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </div>
        <li
          v-for="(app, index) in steps"
          :key="index"
          :class="{ active: activeSlide == index }"
          @click="
            activeSlide = index;
            syncSlide();
          "
        >
          <div class="directive">
            {{ index + 1 }}.
            <div v-html="app.directive.substr(0, 8)" v-if="app.directive"></div>
          </div>

          <img
            class="d-block img-fluid"
            v-if="app.img"
            :src="app.img"
            :title="app.directive"
            :alt="app.directive"
          />
          <div class="no-image" :title="app.directive" v-if="!app.img">
            <span class="appindex"> {{ index + 1 }} </span>
          </div>
        </li>

        <div class="clearfix"></div>
      </ol>
      <div>
        <transition
          name="fade"
          enter-active-class="animate__animated animate__fadeIn"
        >
          <template
            v-for="(app, index) in steps"
            :class="{ active: activeSlide == index }"
          >
            <div
              v-if="activeSlide == index"
              :key="index"
              :class="{ 'just-text': !app.img }"
            >
              <div class="d-flex mt-3">
                <div class="row w-100">
                  <div class="col">
                    <div class="card">
                      <h5 class="card-header bg-primary text-white mt-0">
                        Durum
                      </h5>
                      <span class="h4 p-2 pl-3">{{ app.directive }}</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <h5 class="card-header bg-secondary text-white mt-0">
                        Uygun Söz veya Davranış
                      </h5>

                      <input
                        v-if="routeName == 'AppChildSide'"
                        class="form-control mt-2 text-center"
                        type="text"
                        v-model="answers[index][0]"
                        placeholder="Buraya yazabilirsin."
                        @change="setAnswersChild()"
                      />
                      <input
                        v-if="routeName != 'AppChildSide'"
                        class="form-control mt-2 text-center"
                        type="text"
                        v-model="answers[index][0]"
                        placeholder="Buraya yazabilirsin."
                        @change="setAnswersParent()"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <h5 class="card-header bg-warning text-white mt-0">
                        Uygun Olmayan Söz veya Davranış
                      </h5>
                      <input
                        v-if="routeName == 'AppChildSide'"
                        class="form-control mt-2 text-center"
                        type="text"
                        v-model="answers[index][1]"
                        placeholder="Buraya yazabilirsin."
                        @change="setAnswersChild()"
                      />
                      <input
                        v-if="routeName != 'AppChildSide'"
                        class="form-control mt-2 text-center"
                        type="text"
                        v-model="answers[index][1]"
                        placeholder="Buraya yazabilirsin."
                        @change="setAnswersParent()"
                      />
                    </div>
                    <div class="text-right">
                    <button
                      v-if="routeName != 'AppChildSide'"
                      class="btn btn-secondary btn-round waves-effect waves-light mt-3 pl-4 pr-4"
                      @click="setAnswersParent()"
                    >
                      Cevapla
                    </button>
                    <button
                      v-if="routeName != 'App'"
                      class="btn btn-secondary btn-round waves-effect waves-light mt-3 pl-4 pr-4"
                      @click="setAnswersChild()"
                    >
                      Cevapla
                    </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/components/event-bus";

export default {
  name: "CustomTwoInputSlide",
  props: ["application"],
  data() {
    return {
      routeName: this.$route.name,
      activeSlide: -1,
      steps: null,
      answers: []
    };
  },

  mounted() {
    this.steps = JSON.parse(this.application.steps).items;

    this.steps.forEach(() => {
      this.answers.push([], []);
    });
    EventBus.$on("getAnswers", () => {
      this.saveAnswers();
    });
  },
  beforeDestroy: function() {
    EventBus.$off("getAnswers");
  },
  sockets: {
    setActiveSlide: function(data) {
      if (data.applicationId == this.application.id) {
        this.activeSlide = data.activeSlideIndex;
      }
    },
    setAnswersChild: function(data) {
      if (data.applicationId == this.application.id) {
        this.answers = data.answers;
      }
    },
    setAnswersParent: function(data) {
      if (data.applicationId == this.application.id) {
        this.answers = data.answers;
      }
    }
  },
  methods: {
    syncSlide: function() {
      this.$socket.client.emit("setActiveSlide", {
        to: this.$route.params.id,
        activeSlideIndex: this.activeSlide,
        applicationId: this.application.id
      });
    },
    setAnswersParent: function() {
      this.$socket.client.emit("setAnswersParent", {
        to: this.$route.params.id,
        answers: this.answers,
        applicationId: this.application.id
      });
    },
    setAnswersChild: function() {
      this.$socket.client.emit("setAnswersChild", {
        to: this.$route.params.id,
        answers: this.answers,
        applicationId: this.application.id
      });
    },
    saveAnswers: function() {
      EventBus.$emit("saveAnswers", this.answers);
    }
  },
  watch: {
    application: {
      deep: true,
      handler() {
        if (this.application.steps) {
          this.activeSlide = -1;
          this.steps = JSON.parse(this.application.steps).items;
        }
      }
    }
  }
};
</script>

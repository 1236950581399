<template>
  <footer class="footer text-center text-sm-left">
    SOBECE | Sosyal Beceri Çocuk Eğitimi
  </footer>
</template>
<script>
export default {
  name: 'Footer',
};
</script>

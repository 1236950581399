var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',{staticClass:"table table-hover mb-0 usage-list-table"},[_vm._m(0),(_vm.usages.data)?_c('tbody',_vm._l((_vm.usages.data.rows),function(usage){return _c('tr',{key:usage.id},[_c('td',[_c('b',[_vm._v(_vm._s(usage.child.name))])]),_c('td',[_vm._v(" "+_vm._s(new Date(usage.createdAt).toLocaleString("tr"))+" ")]),_c('td',[_vm._v(" "+_vm._s(usage.user.name)+" ")]),_c('td',[_c('span',{staticClass:"badge",class:usage.status == 1 ? 'badge-soft-primary' : 'badge-soft-success'},[_vm._v(_vm._s(usage.status == 1 ? "Kullanım Olmamış" : "Kullanım Başarılı"))]),(usage.status == 1)?_c('p',[_vm._v(" * Başlamamış kullanımlar, kullanım hakkınıza yansımaz."),_c('br'),_vm._v(" * Üretilen linkler 2 saat için geçerlidir. ")]):_vm._e()]),_c('td',[(
              _vm.diffMinutesNowBetweenStartedAt(
                usage.startedAt ? usage.startedAt : usage.createdAt
              ) < 120 &&
                usage.status != 2 &&
                !_vm.disableContinue
            )?_c('router-link',{staticClass:"btn btn-success waves-effect waves-light create-usage mt-3",attrs:{"type":"button","to":{
              name: 'App',
              params: {
                id: usage.shortlink
              }
            }}},[_c('i',{staticClass:" fas fa-play "}),_vm._v(" Oturuma Devam Et ("+_vm._s(usage.remainingMinutes ? usage.remainingMinutes : "50")+".dk'nız kaldı)")]):_vm._e(),(
              _vm.diffMinutesNowBetweenStartedAt(
                usage.startedAt ? usage.startedAt : usage.createdAt
              ) < 120 &&
                usage.status != 2 &&
                !_vm.disableContinue
            )?_c('p',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$appDomain)+"application/"+_vm._s(usage.shortlink)+" ")]):_vm._e(),(
              _vm.diffMinutesNowBetweenStartedAt(
                usage.startedAt ? usage.startedAt : usage.createdAt
              ) > 120 || usage.status != 1
            )?_c('p',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(usage.shortlink)+" ")]):_vm._e()],1),_c('td',[(usage.practiceIds)?_c('button',{staticClass:"btn btn-gradient-secondary",on:{"click":function($event){return _vm.linkCopy(usage.shortlink)}}},[_c('i',{staticClass:"fas fa-copy"}),_vm._v(" Pratik Linki Kopyala ")]):_vm._e(),(usage.status != 1)?_c('router-link',{staticClass:"btn btn-primary waves-effect waves-light create-practice ml-1",attrs:{"to":{
              name: 'PracticeDefine',
              params: { id: usage.shortlink }
            }}},[_c('i',{staticClass:" fa fa-pen "}),_vm._v(" "+_vm._s(usage.practiceIds ? "" : "Pratik Tanımla")+" ")]):_vm._e()],1)])}),0):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Adı Soyadı")]),_c('th',[_vm._v("Tarih")]),_c('th',[_vm._v("Uygulayıcı")]),_c('th',[_vm._v("Durum")]),_c('th',[_vm._v("Katılım Linki / Oturum Kodu")]),_c('th',[_vm._v("Sobece Pratiği")])])])}]

export { render, staticRenderFns }
<template>
  <div class="page-wrapper">
    <!-- Page Content-->
    <div class="page-content-tab">
      <div class="container-fluid">
        <!-- Page-Title -->
        <div class="row">
          <div class="col-sm-12">
            <div class="page-title-box">
              <h4 class="page-title">Yeni</h4>
            </div>
            <!--end page-title-box-->

            <!-- Body -->

            <div class="row">
              <div class="col-12 col-sm-7">
                <div class="card">
                  <div class="row">
                    <div class="col-12 col-sm-3">
                      <div class="card-body">
                        <div class="met-profile">
                          <div class="met-profile-main">
                            <div class="met-profile-main-pic">
                              <file-upload
                                ref="upload"
                                class="file profile-img"
                                v-model="image"
                                accept="image/png, image/gif, image/jpeg"
                                extensions="gif,jpg,jpeg,png"
                              >
                                <img
                                  :src="
                                    newClient.profileImg
                                      ? $apiDomain + newClient.profileImg
                                      : '/assets/images/users/user-4.jpg'
                                  "
                                  alt="Profil Fotoğrafı"
                                  class="rounded-circle c-size-prof"
                                />
                                <span class="fro-profile_main-pic-change">
                                  <i class="fas fa-camera"></i>
                                </span>
                              </file-upload>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-9">
                      <div class="card-body">
                        <div class="form-check-inline my-1">
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              id="customRadio7"
                              name="customRadio"
                              v-model="newClient.isCorporate"
                              value="false"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="customRadio7"
                              >Bireysel</label
                            >
                          </div>
                        </div>
                        <div class="form-check-inline my-1">
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              id="customRadio8"
                              name="customRadio"
                              v-model="newClient.isCorporate"
                              value="true"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="customRadio8"
                              >Kurumsal</label
                            >
                          </div>
                        </div>

                        <div class="form-group mt-2">
                          <label
                            >{{
                              newClient.isCorporate === "true" ? "Yetkili" : ""
                            }}
                            Ad Soyad</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            :placeholder="[
                              newClient.isCorporate === 'true'
                                ? 'Yetkili Ad Soyad'
                                : 'Ad Soyad'
                            ]"
                            v-model="newClient.owner.name"
                            autocomplete="nope"
                            @blur="$v.newClient.owner.name.$touch()"
                            :class="{
                              'is-invalid': $v.newClient.owner.name.$error
                            }"
                          />
                          <div class="invalid-feedback">
                            Lütfen ad soyad giriniz.
                          </div>
                        </div>

                        <div class="form-group mt-2">
                          <label
                            >{{
                              newClient.isCorporate === "true" ? "Yetkili" : ""
                            }}
                            E-Mail</label
                          >
                          <input
                            type="mail"
                            class="form-control"
                            :placeholder="[
                              newClient.isCorporate === 'true'
                                ? 'Yetkili E-Mail'
                                : 'E-Mail'
                            ]"
                            autocomplete="nope"
                            v-model="newClient.owner.email"
                            @blur="$v.newClient.owner.email.$touch()"
                            :class="{
                              'is-invalid': $v.newClient.owner.email.$error
                            }"
                          />
                          <div class="invalid-feedback">
                            Lütfen geçerli bir email adresi girin.
                          </div>
                        </div>

                        <div class="form-group mt-2">
                          <label
                            >{{
                              newClient.isCorporate === "true" ? "Yetkili" : ""
                            }}
                            Şifre</label
                          >
                          <div class="input-group">
                            <input
                              :type="passInputType"
                              class="form-control"
                              placeholder="Şifre"
                              v-model="newClient.owner.password"
                              @blur="$v.newClient.owner.password.$touch()"
                              :class="{
                                'is-invalid': $v.newClient.owner.password.$error
                              }"
                            />

                            <span class="input-group-prepend">
                              <button
                                type="button"
                                class="btn btn-gradient-primary"
                                @click="passwordToggle"
                              >
                                <i class="fas fa-eye"></i>
                              </button>
                            </span>
                            <div class="invalid-feedback">
                              Lütfen parolanızı girin.
                            </div>
                          </div>
                        </div>
                        <div class="form-group mt-2">
                          <label>Yetkili Telefon </label>

                          <the-mask
                            mask="0 (###) ### ## ##"
                            v-model="newClient.owner.tel"
                            class="form-control"
                            type="phone"
                            placeholder="Telefon"
                          ></the-mask>
                        </div>
                        <div
                          class="form-group mt-2"
                          v-if="newClient.isCorporate === 'true'"
                        >
                          <label>Kurum Adı</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Kurum Adı"
                            autocomplete="nope"
                            v-model="newClient.name"
                            @blur="$v.newClient.name.$touch()"
                            :class="{
                              'is-invalid': $v.newClient.name.$error
                            }"
                          />
                          <div class="invalid-feedback">
                            Lütfen kurum adı girin.
                          </div>
                        </div>
                        <div
                          class="form-group mt-2"
                          v-if="newClient.isCorporate === 'true'"
                        >
                          <label>Kurum Telefon </label>

                          <the-mask
                            mask="0 (###) ### ## ##"
                            v-model="newClient.tel"
                            class="form-control"
                            type="phone"
                            placeholder="Kurum Telefon"
                          ></the-mask>
                        </div>
                        <div
                          class="row"
                          v-if="newClient.isCorporate === 'true'"
                        >
                          <div class="col-6">
                            <label>Vergi Dairesi</label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Vergi Dairesi"
                              autocomplete="nope"
                              v-model="newClient.taxOffice"
                            />
                          </div>
                          <div class="col-6">
                            <label>Vergi Numarası</label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Vergi Numarası"
                              autocomplete="nope"
                              v-model="newClient.taxNo"
                            />
                          </div>
                        </div>

                        <div class="form-group mt-2">
                          <label>İl</label>
                          <v-select
                            :options="cities"
                            v-model="selectedCity"
                            class="form-control"
                            placeholder="İl"
                            autocomplete="nope"
                          ></v-select>
                        </div>

                        <div class="form-group mt-2">
                          <label>Açık Adres</label>
                          <textarea
                            type="text"
                            class="form-control"
                            placeholder="Açık Adres"
                            autocomplete="nope"
                            v-model="newClient.address"
                          ></textarea>
                        </div>

                        <div class="float-right">
                          <router-link
                            :to="{ name: 'Clients' }"
                            class="btn btn-md btn-danger waves-effect waves-light pr-4 pl-4 mr-3"
                          >
                            İptal
                          </router-link>
                          <button
                            type="button"
                            class="btn btn-md btn-primary waves-effect waves-light pr-4 pl-4"
                            @click="addClient"
                          >
                            Ekle
                          </button>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Body End -->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <!-- end page title end breadcrumb -->
      </div>
      <!-- container -->

      <footer-text></footer-text>
      <!--end footer-->
    </div>
    <!-- end page content -->
  </div>
</template>

<script>
import footer from "@/components/Footer";
import HTTP from "@/main-source";
import router from "@/router";
import {
  required,
  minLength,
  email,
  requiredIf
} from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  name: "CreateClients",
  components: {
    "footer-text": footer
  },
  data() {
    return {
      passInputType: "password",
      cities: [],
      image: [],
      selectedCity: null,
      newClient: {
        name: null,
        isCorporate: "false",
        cityCode: null,
        address: null,
        tel: null,
        profileImg: null,
        owner: {
          name: null,
          email: null,
          password: null,
          tel: null
        }
      }
    };
  },
  validations: {
    newClient: {
      isCorporate: { required },
      name: {
        required: requiredIf(function(newClientOb) {
          return newClientOb.isCorporate == "true";
        })
      },
      owner: {
        name: { required },
        email: { required, email },
        password: { required, minLength: minLength(5) }
      }
    }
  },
  created() {
    HTTP.get("cities").then(result => {
      result.data.forEach(city => {
        this.cities.push({ id: city.id, label: city.name });
      });
    });
  },
  methods: {
    passwordToggle: function() {
      this.passInputType == "password"
        ? (this.passInputType = "text")
        : (this.passInputType = "password");
    },
    addClient: function() {
      this.$v.newClient.$touch();
      if (!this.$v.newClient.$error) {
        this.newClient.isCorporate =
          this.newClient.isCorporate == "true" ? true : false;
        if (this.selectedCity) {
          this.newClient.cityCode = this.selectedCity.id;
        }
        if (this.newClient.owner.tel) {
          this.newClient.owner.tel = "0" + this.newClient.owner.tel;
        }
        if (this.newClient.tel) {
          this.newClient.tel = "0" + this.newClient.tel;
        }

        HTTP.post("firms", this.newClient)
          .then(result => {
            router.push({
              name: "ClientDetail",
              params: { id: result.data.firm.id }
            });
          })
          .catch(e => {
            this.newClient.isCorporate = this.newClient.isCorporate
              ? "true"
              : "false";
            if (e.response.status == 409) {
              Swal.fire({
                icon: "warning",
                text: "E-mail daha önce kaydedilmiş"
              });
            }
            if (e.response.status == 412) {
              Swal.fire({
                icon: "warning",
                text: "Lütfen girdiğiniz bilgileri kontrol edin."
              });
            }
          });
      }
    }
  },
  watch: {
    image: function() {
      let fd = new FormData();
      fd.append("files", this.image[0].file, this.image[0].name);

      HTTP.post("/files/", fd, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
        .then(res => {
          this.newClient.profileImg = res.data[0].path;
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>

<template>
  <div>
    <div class="carousel slide just-slide">
      <ol class="carousel-thumbs" v-if="routeName != 'AppChildSide'">
        <div class="float-right carousel-control">
          <button
            class="btn btn-secondary btn-circle"
            v-if="routeName != 'AppChildSide'"
            @click="
              activeSlide != 0 ? activeSlide-- : '';
              syncSlide();
            "
          >
            <i class="fas fa-angle-left"></i>
          </button>
          <button
            class="btn btn-secondary btn-circle"
            v-if="routeName != 'AppChildSide'"
            @click="
              activeSlide != steps.length - 1 ? activeSlide++ : '';
              syncSlide();
            "
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </div>
        <li
          v-for="(app, index) in steps"
          :key="index"
          :class="{ active: activeSlide == index }"
          @click="
            activeSlide = index;
            syncSlide();
          "
        >
          <div class="directive">
            {{ index + 1 }}.
            <div v-html="app.directive.substr(0, 8)" v-if="app.directive"></div>
          </div>

          <img
            class="d-block img-fluid"
            v-if="app.img"
            :src="app.img"
            :title="app.directive"
            :alt="app.directive"
          />
          <div class="no-image" :title="app.directive" v-if="!app.img">
            <span class="appindex"> {{ index + 1 }} </span>
          </div>
        </li>

        <div class="clearfix"></div>
      </ol>
      <div>
        <transition
          name="fade"
          enter-active-class="animate__animated animate__fadeIn"
        >
          <template
            v-for="(app, index) in steps"
            :class="{ active: activeSlide == index }"
          >
            <div
              v-if="activeSlide == index"
              :key="index"
              :class="{ 'just-text': !app.img }"
            >
              <div v-html="app.directive"></div>

              <img
                class="d-block img-fluid"
                v-if="app.img && app.type != 3"
                :src="app.img"
                :alt="app.directive"
              />

              <div class="user-feedback" v-if="app.type == 2">
                <input
                  v-if="routeName == 'AppChildSide'"
                  class="form-control mt-2 text-center"
                  type="text"
                  v-model="answers[index]"
                  placeholder="Buraya yazabilirsin."
                  @change="setAnswersChild()"
                />
                <input
                  v-if="routeName != 'AppChildSide'"
                  class="form-control mt-2 text-center"
                  type="text"
                  v-model="answers[index]"
                  placeholder="Buraya yazabilirsin."
                  @change="setAnswersParent()"
                />
                <button
                  v-if="routeName == 'AppChildSide'"
                  class="btn btn-secondary btn-round waves-effect waves-light mt-3 pl-4 pr-4"
                  @click="setAnswersChild()"
                >
                  Cevapla
                </button>
                <button
                  v-if="routeName != 'AppChildSide'"
                  class="btn btn-secondary btn-round waves-effect waves-light mt-3 pl-4 pr-4"
                  @click="setAnswersParent()"
                >
                  Cevapla
                </button>
              </div>
              <div class="user-feedback draw" v-if="app.type == 3">
                <draw
                  :application="steps[activeSlide]"
                  :applicationId="application.id"
                  :bg="steps[activeSlide].img"
                  v-if="showDraw && $route.name == 'AppChildSide'"
                ></draw>

                <ExpertView
                  v-if="showDraw && $route.name != 'AppChildSide'"
                  :sub="true"
                  :application="application"
                ></ExpertView>
              </div>
              <div
                class="user-feedback sad-or-smile-buttons"
                v-if="app.type == 4"
              >
                <img
                  src="/images/application/smile.svg"
                  alt="Mutlu"
                  @click="checkAnswer(true)"
                />
                <img
                  src="/images/application/sad.svg"
                  alt="Üzgün"
                  @click="checkAnswer(false)"
                />
              </div>
              <div
                class="user-feedback sad-or-smile-buttons"
                v-if="app.type == 5"
              >
                <button class="btn m-2 btn-primary" @click="checkAnswer(true)">
                  Uygun
                </button>
                <button class="btn m-2 btn-danger" @click="checkAnswer(false)">
                  Uygun Değil
                </button>
              </div>
            </div>
          </template>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import ExpertView from "@/components/application/types/exp-2";
import draw from "@/components/application/types/2";
import EventBus from "@/components/event-bus";

export default {
  name: "MixedCarousel",
  props: ["application"],
  data() {
    return {
      routeName: this.$route.name,
      activeSlide: -1,
      steps: null,
      answers: [],
      showDraw: false
    };
  },
  components: {
    draw,
    ExpertView
  },
  mounted() {
    this.steps = JSON.parse(this.application.steps).items;

    EventBus.$on("getAnswers", () => {
      this.saveAnswers();
    });
  },
  beforeDestroy() {
    EventBus.$off("getAnswers");
  },
  sockets: {
    setActiveSlide: function(data) {
      if (data.applicationId == this.application.id) {
        this.activeSlide = data.activeSlideIndex;
      }
    },
    setAnswersChild: function(data) {
      if (data.applicationId == this.application.id) {
        this.answers = data.answers;
      }
    },
    setAnswersParent: function(data) {
      if (data.applicationId == this.application.id) {
        this.answers = data.answers;
      }
    }
  },
  methods: {
    syncSlide: function() {
      this.$socket.client.emit("setActiveSlide", {
        to: this.$route.params.id,
        activeSlideIndex: this.activeSlide,
        applicationId: this.application.id
      });
    },
    setAnswersParent: function() {
      this.$socket.client.emit("setAnswersParent", {
        to: this.$route.params.id,
        answers: this.answers,
        applicationId: this.application.id
      });
    },
    setAnswersChild: function() {
      this.$socket.client.emit("setAnswersChild", {
        to: this.$route.params.id,
        answers: this.answers,
        applicationId: this.application.id
      });
    },
    saveAnswers: function() {
      EventBus.$emit("saveAnswers", this.answers);
    },

    checkAnswer: function(answer = null) {
      let animations = {
        text: "TEBRİKLER",
        gif: "applause",
        anim: null,
        sounds: true,
        show: true
      };

      if (this.steps[this.activeSlide].rightAnswer != answer) {
        animations.text = "TEKRAR DENE";
        animations.gif = "try-again";
      }
      this.$socket.client.emit("showAnim", {
        to: this.$route.params.id,
        animations: animations
      });
    }
  },
  watch: {
    application: {
      deep: true,
      handler() {
        if (this.application.steps) {
          this.activeSlide = -1;
          this.steps = JSON.parse(this.application.steps).items;
        }
      }
    },
    activeSlide: function() {
      this.showDraw = false;
      if (this.steps[this.activeSlide].type == 3) {
        setTimeout(() => {
          this.showDraw = true;
        }, 100);
      }
    }
    // activeSlide: function() {
    //   if (this.steps[this.activeSlide].type == 3) {
    //     this.$refs.editor.set(this.editor.mode, this.editor.options);
    //     let customizeFreeDrawing = { stroke: "#1a1a1a", strokeWidth: "4" };
    //     this.$refs.editor.set("freeDrawing", customizeFreeDrawing);

    //     // const ctx = this.$refs.editor.canvas.getContext("2d");
    //     if (this.steps[this.activeSlide].img) {
    //       let imageUrl = this.steps[this.activeSlide].img;
    //       this.$refs.editor.setBackgroundImage(imageUrl);

    //       // const img = new Image();
    //       // img.src = this.$appDomain + imageUrl;
    //       //   ctx.drawImage(img, 200, 20, 70, 70);

    //       setTimeout(() => {
    //         this.$socket.client.emit("drawing", {
    //           to: this.$route.params.id,
    //           data: this.$refs.editor.canvas.toDataURL()
    //         });
    //       }, 500);
    //     }
    //   }
    // }
  }
};
</script>

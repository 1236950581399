<template>
  <div class="page-wrapper">
    <!-- New User Modal -->
    <modal v-if="newUserModal" @close="newUserModal = false">
      <div slot="title">
        Yeni Kullanıcı
      </div>
      <div slot="body">
        <div class="form-group mt-2">
          <label>Ad Soyad</label>
          <input
            type="text"
            class="form-control"
            placeholder="Ad Soyad"
            v-model="subuser.name"
            autocomplete="nope"
            @blur="$v.subuser.name.$touch()"
            :class="{
              'is-invalid': $v.subuser.name.$error
            }"
          />
          <div class="invalid-feedback">
            Lütfen ad soyad giriniz.
          </div>
        </div>
        <div class="form-group mt-2">
          <label>Email</label>
          <input
            type="text"
            class="form-control"
            placeholder="Email"
            v-model="subuser.email"
            autocomplete="nope"
            @blur="$v.subuser.email.$touch()"
            :class="{
              'is-invalid': $v.subuser.email.$error
            }"
          />
          <div class="invalid-feedback">
            Lütfen geçerli bir email adresi girin.
          </div>
        </div>
        <div class="form-group mt-2">
          <label>Şifre</label>
          <input
            type="password"
            class="form-control"
            placeholder="Şifre"
            v-model="subuser.password"
            autocomplete="nope"
            @blur="$v.subuser.password.$touch()"
            :class="{
              'is-invalid': $v.subuser.password.$error
            }"
          />
          <div class="invalid-feedback">
            Lütfen şifre giriniz.
          </div>
        </div>
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-md btn-danger waves-effect waves-light pr-4 pl-4 mr-3"
          @click="newUserModal = false"
        >
          İptal
        </button>
        <button
          type="button"
          class="btn btn-md btn-primary waves-effect waves-light pr-4 pl-4"
          @click="addSubUser"
        >
          Ekle
        </button>
      </div>
    </modal>
    <!-- New User Modal -->

    <!-- New Usageright Modal -->
    <modal v-if="newUsageRightModal" @close="newUsageRightModal = false">
      <div slot="title">
        {{ isUsageRightUp ? "Kullanım Tanımlama" : "Kullanım Düşürme" }}
      </div>
      <div slot="body">
        <div class="form-group mt-2">
          <label>Kullanım Hakkı</label>
          <the-mask
            mask="####"
            v-model="newUsageRight.count"
            class="form-control"
            type="num"
            placeholder="Kullanım Hakkı"
            autocomplete="nope"
            @blur="$v.newUsageRight.count.$touch()"
            :class="{
              'is-invalid': $v.newUsageRight.count.$error
            }"
          ></the-mask>

          <div class="invalid-feedback">
            Lütfen kullanım hakkı giriniz.
          </div>
        </div>
        <div class="form-group mt-2">
          <label>Açıklama</label>
          <input
            type="text"
            class="form-control"
            placeholder="Açıklama"
            v-model="newUsageRight.desc"
            autocomplete="nope"
          />
        </div>
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-md waves-effect waves-light pr-4 pl-4 mr-3"
          :class="!isUsageRightUp ? 'btn-primary' : 'btn-danger'"
          @click="newUsageRightModal = false"
        >
          İptal
        </button>
        <button
          type="button"
          class="btn btn-md waves-effect waves-light pr-4 pl-4"
          :class="isUsageRightUp ? 'btn-primary' : 'btn-danger'"
          @click="addUsageRight"
        >
          {{ isUsageRightUp ? "Tanımla" : "Düşür" }}
        </button>
      </div>
    </modal>
    <!-- New Usageright Modal -->

    <!-- Page Content-->
    <div class="page-content-tab">
      <div class="container-fluid">
        <!-- Page-Title -->
        <div class="row">
          <div class="col-sm-12">
            <div class="page-title-box">
              <h4 class="page-title">Detay</h4>
            </div>
            <!--end page-title-box-->

            <!-- Body -->

            <div class="row">
              <div class="col-sm-8">
                <div class="card">
                  <div class="card-body">
                    <div class="met-profile">
                      <div class="row">
                        <div class="col-lg-8 align-self-center mb-3 mb-lg-0">
                          <div class="met-profile-main">
                            <div class="met-profile-main-pic">
                              <file-upload
                                ref="upload"
                                class="file profile-img"
                                v-model="image"
                                accept="image/png, image/gif, image/jpeg"
                                extensions="gif,jpg,jpeg,png"
                              >
                                <img
                                  :src="
                                    client.profileImg
                                      ? $apiDomain + client.profileImg
                                      : '/assets/images/users/user-4.jpg'
                                  "
                                  alt="Profil Fotoğrafı"
                                  class="rounded-circle c-size-prof"
                                />
                                <span class="fro-profile_main-pic-change">
                                  <i class="fas fa-camera"></i>
                                </span>
                              </file-upload>
                            </div>
                            <div>
                              <h2 class="title-text">{{ client.name }}</h2>
                              <p>
                                <span
                                  class="custom-badge"
                                  :class="{ corporate: client.isCorporate }"
                                  >{{
                                    client.isCorporate ? "Kurumsal" : "Bireysel"
                                  }}
                                  Üye</span
                                >
                              </p>
                              <ul class="list-unstyled ">
                                <li class="">
                                  <i
                                    class="dripicons-phone mr-2 text-info font-18"
                                  ></i>
                                  {{ client.tel }}
                                </li>

                                <li class="mt-2">
                                  <i
                                    class="dripicons-location text-info font-18 mt-2 mr-2"
                                  ></i>
                                  {{ client.cityCode ? client.city.name : " " }}
                                </li>
                                <li class="mt-2">
                                  <i
                                    class="dripicons-mail text-info font-18 mt-2 mr-2"
                                  ></i>
                                  {{ clientAuthor.email }}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <!--end col-->
                        <div class="col-lg-4 ml-auto mt-5">
                          <ul class="list-unstyled ">
                            <li class="" v-if="client.taxOffice">
                              <i
                                class="dripicons-blog mr-2 text-info font-18"
                              ></i>
                              Vergi Dairesi: {{ client.taxOffice }}
                            </li>

                            <li class="mt-2" v-if="client.taxNo">
                              <i
                                class="dripicons-archive text-info font-18 mt-2 mr-2"
                              ></i>
                              Vergi No: {{ client.taxNo }}
                            </li>
                          </ul>
                        </div>
                        <!--end col-->
                      </div>
                      <!--end row-->
                    </div>
                    <!--end f_profile-->
                  </div>
                  <!--end card-body-->
                  <div class="card-body">
                    <ul class="nav nav-pills mb-0">
                      <li class="nav-item">
                        <button
                          class="btn nav-link"
                          :class="{ active: activeComponent == 'usagesList' }"
                          id="general_detail_tab"
                          data-toggle="pill"
                          @click="activeComponent = 'usagesList'"
                        >
                          Genel
                        </button>
                      </li>
                      <li class="nav-item">
                        <button
                          class="btn nav-link"
                          :class="{ active: activeComponent == 'users' }"
                          id="activity_detail_tab"
                          data-toggle="pill"
                          @click="activeComponent = 'users'"
                        >
                          Kullanıcılar
                        </button>
                      </li>
                    </ul>
                  </div>
                  <!--end card-body-->
                </div>
                <!--end card-->
              </div>
              <div class="col">
                <div class="card">
                  <div class="card-body usages-box mh">
                    <b>Kullanımlar</b>
                    <div class="icon-info mt-1 mb-4">
                      <i class="mdi mdi-bullseye bg-soft-success"></i>
                    </div>

                    <div class="total text-center">
                      <div>
                        <h1>
                          {{ clientUsageRights ? clientUsageRights.total : 0 }}
                        </h1>
                        <p v-if="clientUsageRights">adet kullanım hakkı var.</p>
                      </div>
                    </div>
                    <div class="row" v-if="role == 1">
                      <div class="col text-center">
                        <button
                          type="button"
                          class="btn btn-secondary waves-effect pr-5 pl-5"
                          @click="
                            newUsageRightModal = true;
                            isUsageRightUp = true;
                          "
                        >
                          + Yeni Kullanım Hakkı Tanımla
                        </button>

                        <button
                          type="button"
                          class="btn btn-danger waves-effect ml-2"
                          @click="
                            newUsageRightModal = true;
                            isUsageRightUp = false;
                          "
                          v-if="clientUsageRights.total"
                        >
                          Düşür
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
            <div class="row">
              <div class="col-sm-8">
                <div class="card">
                  <div class="card-body min-height-40">
                    <h5 class="d-inline">
                      {{
                        activeComponent == "users"
                          ? "Kullanıcılar"
                          : "Kullanımlar"
                      }}
                    </h5>
                    <div class="mt-2">
                      <component
                        :is="activeComponent"
                        :users="clientUsers"
                        :usages="usages"
                        :isCorporate="client.isCorporate"
                        :disableContinue="true"
                        v-if="!xhrProcess"
                      ></component>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="card">
                  <div class="card-body usages-box">
                    <span class="h5 mb-4 d-block">Kullanım Grafiği</span>
                    <LineChart
                      :height="200"
                      :graphData="usagesChart"
                      v-if="usagesChart.length > 0"
                    ></LineChart>
                  </div>
                </div>

                <div class="card">
                  <div class="card-body usages-box">
                    <span class="h5 mb-4 d-block">Tanımlamalar</span>
                    <usageRights :usagerights="clientUsageRights"></usageRights>
                  </div>
                </div>
              </div>
            </div>
            <!--end row-->
            <!-- Body End -->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <!-- end page title end breadcrumb -->
      </div>
      <!-- container -->

      <footer-text></footer-text>
      <!--end footer-->
    </div>
    <!-- end page content -->
  </div>
</template>

<script>
import footer from "@/components/Footer";
import HTTP from "@/main-source";
import router from "@/router";
import users from "@/components/clientDetail/users";
import usagesList from "@/components/clientDetail/usages";
import usageRights from "@/components/clientDetail/usageRights";
import Swal from "sweetalert2";
import modal from "@/components/modal";
import EventBus from "@/components/event-bus";
import { required, email, minLength } from "vuelidate/lib/validators";
import LineChart from "@/components/charts/line";

export default {
  name: "ClientDetail",
  data() {
    return {
      clientId: this.$route.params.id,
      image: [],
      client: [],
      clientAuthor: [],
      clientUsers: [],
      clientUsageRights: [],
      xhrProcess: false,
      role: localStorage.getItem("role"),
      activeComponent: "usagesList", // usages or users
      newUserModal: false,
      subuser: {
        name: null,
        email: null,
        password: null
      },
      newUsageRightModal: false,
      newUsageRight: {
        count: null,
        desc: null
      },
      isUsageRightUp: true,
      usages: [],
      usagesChart: []
    };
  },
  validations: {
    subuser: {
      name: { required },
      email: { required, email },
      password: { required, minLength: minLength(5) }
    },
    newUsageRight: {
      count: { required }
    }
  },
  components: {
    "footer-text": footer,
    users,
    usagesList,
    modal,
    usageRights,
    LineChart
  },
  created() {
    this.getClient();
    this.getUsageRights();
    this.getUsages();
    HTTP.get("usages/byfirm/" + this.clientId + "/graph").then(result => {
      this.usagesChart = result.data;
    });
  },
  methods: {
    getClient() {
      HTTP.get("firms/" + this.clientId)
        .then(result => {
          this.client = result.data.rows[0];
          this.clientUsers = result.data.rows[0].users;
          this.clientAuthor = result.data.rows[0].users.filter(
            user => user.roleId == 2 || user.roleId == 1
          )[0];
        })
        .catch(e => {
          console.log(e);
          if (e.response.status == 401) {
            router.push({ name: "Home" });
            Swal.fire({ icon: "error", text: "Yetkiniz yok!" });
          } else {
            Swal.fire({ icon: "error", text: "Kurum Bulunamadı" });
          }
        });
    },
    getUsages: function() {
      HTTP.get("usages/byfirm/" + this.clientId).then(result => {
        this.usages = result;
        this.xhrProcess = false;
      });
    },
    addSubUser() {
      this.$v.subuser.$touch();
      if (!this.$v.subuser.$error) {
        HTTP.post("firms/" + this.clientId + "/user", this.subuser)
          .then(result => {
            console.log(result);
            this.getClient();
            this.newUserModal = false;
          })
          .catch(e => {
            if (e.response.status == 409) {
              Swal.fire({
                icon: "warning",
                text: "E-mail daha önce kaydedilmiş."
              });
            }
            if (e.response.status == 412) {
              Swal.fire({
                icon: "warning",
                text: "Lütfen girdiğiniz bilgileri kontrol edin."
              });
            }
          });
      }
    },
    updateSubUserStatus(data) {
      HTTP.patch("users/" + data.userId + "/status", data).then(() => {
        this.clientUsers.filter(user => user.id == data.userId)[0].status =
          data.status;
      });
    },
    getUsageRights() {
      HTTP.get("firms/" + this.clientId + "/usagerights").then(usagerights => {
        this.clientUsageRights = usagerights.data;
      });
    },
    addUsageRight() {
      this.$v.newUsageRight.$touch();
      if (!this.$v.newUsageRight.$error) {
        let usageRight = {
          count: this.newUsageRight.count,
          desc: this.newUsageRight.desc,
          firmId: this.clientId
        };

        if (!this.isUsageRightUp) {
          usageRight.count = "-" + this.newUsageRight.count;
        }

        HTTP.post("usagerights", usageRight)
          .then(() => {
            this.newUsageRightModal = false;
            Swal.fire({
              icon: "success",
              text: "Kullanım hakkı başarıyla tanımlandı.",
              confirmButtonText: "Tamam"
            });
            this.getUsageRights();
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              text:
                "Kullanım hakkı tanımlarken hata meydana geldi, lütfen daha sonra tekrar deneyiniz."
            });
          });
      }
    }
  },
  watch: {
    newUserModal: function() {
      if (!this.newUserModal) {
        this.subuser = {
          name: null,
          email: null,
          password: null
        };
        this.$v.subuser.$reset();
      }
    },
    newUsageRightModal: function() {
      if (!this.newUsageRightModal) {
        this.newUsageRight = {
          count: null,
          desc: null
        };
        this.$v.newUsageRight.$reset();
      }
    },
    $route: function() {
      this.clientId = this.$route.params.id;
      this.getClient();
      this.getUsageRights();
      this.getUsages();
    },
    image: function() {
      let fd = new FormData();
      fd.append("files", this.image[0].file, this.image[0].name);

      HTTP.post("/files/", fd, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
        .then(res => {
          HTTP.patch("firms/" + this.clientId + "/profilephoto", {
            profileImg: res.data[0].path
          }).then(() => {
            this.client.profileImg = res.data[0].path;
          });
        })
        .catch(e => {
          console.log(e);
        });
    }
  },
  mounted() {
    EventBus.$on("newUserModal", () => {
      this.newUserModal = true;
    });
    EventBus.$on("updateSubUserStatus", data => {
      this.updateSubUserStatus(data);
    });
  },
  beforeDestroy() {
    EventBus.$off("newUserModal");
    EventBus.$off("updateSubUserStatus");
  }
};
</script>

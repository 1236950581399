<template>
  <div
    class="row mt-5 mr-3 ml-3 assessment-form"
    :class="[
      { 'centered-content': xhrProcess },
      { 'centered-content': processDone }
    ]"
  >
    <div
      class="spinner-border text-success"
      role="status"
      v-if="xhrProcess"
    ></div>
    <span v-if="processDone" class="h4 brand-color-orange">Onayınız için Teşekkürler!</span>

    <div
      class="col-12 col-sm-8 offset-sm-2 card p-4"
      v-if="!xhrProcess && !processDone && child"
    >
      <img src="@/assets/images/logo.svg" alt="Sobece Logo" class="logo" />
      <span class="h4">ONLINE SOBECE EBEVEYN ONAM FORMU </span>
      <p>
        ADI SOYADI:<b> {{ child.name }}</b
        ><br />
      </p>
<p>
      Sevgili ebeveyn,
      
         <ul>
            <li>SOBECE Sosyal Beceri Değerlendirme Ölçeğinin ebeveynler tarafından yanıtlanması çocuğun sosyal beceri düzeyinin belirlenmesi için önemlidir.</li>
            <li>Ailenin izni ve onayı var ise çocuğunuzun öğretmeninin de SOBECE Sosyal Beceri Değerlendirme Ölçeğini yanıtlaması istenir.</li>
            <li>Terapi seansına başlamadan önce çocuğun temel ihtiyaçlarının (tuvalet, açlık vb.) karşılandığından emin olun.</li>
            <li>Seansın başlamasına 5 dakika kala çocuğunuzun bilgisayarına görüşmenin yapılacağı SOBECE programına ait link gönderilecektir. Çocuğunuzun bu linke tıklayarak görüşmeye bağlandığından emin olun.</li>
            <li>Seanstan önce mikrofon ve kameranın, açık ve çalışır durumda olup olmadığını kontrol edin.</li>
            <li>Çocuğunuzun bulunduğu ortamın güvenli ve sessiz olmasını sağlayın, dışardan gelebilecek gürültü gibi sorunları kontrol altında tutun.</li>
            <li>Çocuğun online seansı gerçekleştireceği masanın üzerinde ve etrafında dikkat dağıtıcı, uyarıcı unsurların görsellerin (oyuncak vb.) bulunmamasına özen gösterin.</li>
            <li>Çocuğunuzun seans süresince rahatsız edilmeyeceğinden emin olun.</li>
            <li>Yeterli aydınlatmayı sağlayın.</li>
            <li>Seans boyunca herhangi bir aksilik çıkması durumunda çocuğunuzun ve bizim ulaşabileceğimiz bir mesafede bulunmaya özen gösterin.</li>
            <li>İki taraf da (aile- çocuk / uzman) hiçbir şekilde oturumları kaydetmeyecektir. Bunun güvencesi bu onam formunda karşılıklı olarak kesinleştirilmiştir.</li>
            <li>SOBECE Sosyal Beceri Değerlendirme Ölçeğinin puanlama sonuçları çocukla ilgili herhangi bir kimlik bilgisi içermeden araştırmalarda kullanılabilir.</li>
            <li>Yüz yüze oturumlarda olduğu gibi online terapilerde de gizlilik esası geçerlidir.</li>
            <li>Online SOBECE bireysel seansları ortalama 40-45 dakikadır. Bu süre çocuğun gelişimi, yaşı, dikkat süresi ve ihtiyaçlarına göre uzman tarafından kısaltılabilir ya da uzatılabilir.</li>
            <li>Her seans sonrası çocuğa verilecek SOBECE pratikleri mail yoluyla gönderilecektir. SOBECE pratikleri çocukla birlikte ebeveynin de sorumluluğundadır.</li>
            <li>Online SOBECE seansında sadece çocuk ile çalışılır ebeveyn ile görüşme yapılmaz. Devam edilen süreç içerisinde terapistin gerekli gördüğü veya ebeveynin ihtiyaç duyduğu durumlarda ortaklaşa kararlaştırılan bir zaman diliminde aile danışma seansı düzenlenir.</li>
            <li>Seansların (acil durumlar dışında) aynı gün içinde iptal edilmemesi önemlidir. Erteleme ve iptal taleplerinin bir gün önceden bildirilmesi gerekmektedir.</li>
         </ul>
      
        Aşağıdaki “onaylıyorum” butonuna basınız.
</p>
      <!-- Cat List End -->
      <button class="btn btn-success mt-2" @click="consentOk">
        Onaylıyorum
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import HTTP from "@/main-source";
import Swal from "sweetalert2";

export default {
  name: "Fill",
  data() {
    return {
      xhrProcess: true,
      processDone: false,
      childId: this.$route.params.id,
      child: []
    };
  },
  created() {
    this.consentCheck();
  },
  methods: {
    consentCheck: function() {
      HTTP.get("childs/" + this.childId).then(result => {
            this.child = result.data.rows[0];
        if (this.child.isConsent == true) {
          this.processDone = true;
        }
        this.xhrProcess = false
      }).catch(e=>{
          this.xhrProcess = false
      });
    },
    consentOk: function() {
      HTTP.patch("childs/" + this.childId).then(result => {

        this.processDone = true;
      });
    }
  }
};
</script>

<script>
import EventBus from "@/components/event-bus";

export default {
  name: "notes",
  data() {
    return {
      activeApplication: null,
      answer: [],
      notes: [],
      childId: null,
      newNote: ""
    };
  },
  methods: {
    addNote: function() {
      this.notes.push(this.newNote);
      this.newNote = "";
      EventBus.$emit("addNote", this.notes);
      //
    }
  },
  mounted() {
    EventBus.$on("updateNoteApplication", data => {
      this.activeApplication = data;
    });
    EventBus.$on("setApplicationNotes", data => {
      this.notes = [];
      this.answer = data;
      if (this.answer) {
        if (this.answer.note) {
          this.notes = JSON.parse(this.answer.note);
        } else {
          this.notes = [];
        }
      }
    });
    EventBus.$on("setChildId", childId => {
      this.childId = childId;
    });
  }
};
</script>

<template>
  <div class="notes">
    <div class="head" v-if="activeApplication != null">
      <span class="h6 brand-color-purple">{{ activeApplication.name }}</span>
    </div>
    <div class="list">
      <span v-for="(note, index) in notes" :key="index">{{ note }} </span>
      <div class="no-content" v-if="activeApplication == null">
        Lütfen uygulama seçiniz.
      </div>

      <div class="no-content" v-if="notes.length == 0">
        Bu uygulama için henüz not almadınız.
      </div>
    </div>

    <div class="take-note">
      <textarea
        class="form-control"
        placeholder="Notunuz"
        :disabled="activeApplication == null"
        v-model="newNote"
      ></textarea>
      <button
        class="btn btn-gradient-primary waves-effect waves-light float-right mt-2 pr-4 pl-4"
        :disabled="activeApplication == null || newNote.length == 0"
        @click="addNote"
      >
        Ekle
      </button>
    </div>
  </div>
</template>

<template>
  <div>
    <div class="table-responsive">
      <table class="table table-hover mb-0">
        <thead>
          <tr>
            <th>Ad Soyad</th>
            <th>Email</th>
            <th>Rol</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <!-- @click="goToUserProfile(user.id)" -->
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td>
              <img
                :src="
                  user.profileImg
                    ? $apiDomain + user.profileImg
                    : '/assets/images/users/user-4.jpg'
                "
                alt=""
                class="rounded-circle thumb-sm mr-1"
              />
              {{ user.name }}
            </td>
            <td>{{ user.email }}</td>
            <td>
              <span
                class="badge"
                :class="
                  user.roleId == 2 ? 'badge-soft-success' : 'badge-soft-primary'
                "
                >{{
                  user.roleId == 2
                    ? "Yetkili"
                    : user.roleId == 1
                    ? "Sistem Yöneticisi"
                    : "Alt Kullanıcı"
                }}</span
              >
            </td>
            <td class="pl-5">
              
              <div
                class="custom-switch switch-primary p-0"
                v-if="user.roleId != 1 && currentUser.userId != user.id && role != 3"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="user.id"
                  :checked="user.status"
                />
                <label
                  class="custom-control-label"
                  @click="updateSubUserStatus(user.id, user.status)"
                  :for="user.id"
                  >{{ user.status ? "Aktif" : "Pasif" }}</label
                >
              </div>
            </td>
            <td>
              <router-link
                :to="{
                  name: 'UserProfile',
                  params: { id: user.id }
                }"
              >
                <i
                  class="fas fa-angle-right
 font-16"
                ></i
              ></router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <!--end /table-->
      <button
        class="btn btn-primary btn-sm waves-effect waves-light float-right mt-3"
        v-if="isCorporate && (role == 1 || role == 2)"
        @click="newUserModal"
      >
        + Yeni Kullanıcı
      </button>
    </div>
  </div>
</template>

<script>
import EventBus from "@/components/event-bus";
import router from "@/router";

export default {
  name: "users",
  props: ["users", "isCorporate"],
  data() {
    return {
      role: parseInt(localStorage.getItem("role")),
      currentUser: JSON.parse(localStorage.getItem("user")),
    };
  },
  methods: {
    newUserModal() {
      EventBus.$emit("newUserModal", true);
    },
    updateSubUserStatus(userId, status) {
      let data = {
        userId: userId,
        status: !status
      };
      EventBus.$emit("updateSubUserStatus", data);
    },
    goToUserProfile(userId) {
      router.push({
        name: "UserProfile",
        params: { id: userId }
      });
    }
  }
};
</script>

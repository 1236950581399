<template>
  <div>
    <img ref="expertcanvas" width="700" height="500" class="editor" />
  </div>
</template>
<script>
import EventBus from "@/components/event-bus";

export default {
  name: "DrawingExpSide",
  props: ["sub", "application"],
  data() {
    return {
      imgData: null
    };
  },
  sockets: {
    drawing: function(data) {
      if (data) {
        if (data.applicationId == this.application.id) {
          const img = new Image();
          img.src = data.data;
          this.imgData = data.data;
          this.$refs.expertcanvas.setAttribute("src", this.imgData);
        }
      }
    }
  },
  mounted() {
    // if (!this.sub) {
      EventBus.$on("getAnswers", () => {
        EventBus.$emit("saveAnswers", this.imgData);
      });
    // }
  },
  beforeDestroy() {
    EventBus.$off("getAnswers");
  }
};
</script>

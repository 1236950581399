<template>
  <div class="page-wrapper">
    <!-- Page Content-->
    <div class="page-content-tab">
      <div class="container-fluid">
        <!-- Page-Title -->
        <div class="row">
          <div class="col-sm-12">
            <div class="page-title-box">
              <h4 class="page-title">Pratik Tanımlama</h4>
            </div>
            <!--end page-title-box-->

            <!-- Body -->

            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body" v-if="!xhrProcess">
                    <b>{{ usage.child.name }}</b> adlı danışana
                    {{ new Date(usage.createdAt).toLocaleDateString("tr") }}
                    tarihli oturum için pratik tanımlıyorsunuz.
                    <hr />
                    <div class="row practice">
                      <div class="col-12 col-sm-8">
                        <!-- Cat List -->
                        <div
                          class="list"
                          v-for="(cat, index) in categories"
                          :key="index"
                        >
                          <span class="h4 brand-color-purple">
                            {{ cat.name }}</span
                          >

                          <div
                            class="row item m-1 p-2 cursor-p"
                            v-for="(subCategory, index) in cat.categories"
                            :key="subCategory.id"
                            @click="selectPractice(subCategory.id)"
                          >
                            <div class="col-12 p-1">
                              <b>{{ index + 1 }}. {{ subCategory.name }} </b>
                              <p v-html="subCategory.practice"></p>
                              <!-- Checkbox -->
                              <div class="check-wrapper">
                                <i
                                  class="fas fa-check"
                                  v-if="
                                    selectedPractices.includes(subCategory.id)
                                  "
                                ></i>

                                <span class="box"></span>
                              </div>
                              <!-- Checkbox End -->
                            </div>
                          </div>
                        </div>
                        <!-- Cat List End -->
                      </div>
                      <div class="col-12 col-sm-4">
                        <div class="sticky">
                          <span class="h5 d-block"
                            >Seçilen Pratikler ({{
                              selectedPractices ? selectedPractices.length : 0
                            }})</span
                          >

                          <div
                            class="d-block"
                            v-for="(practice, index) in listSelectedPractice()"
                            :key="index"
                          >
                            <b>{{ practice.name }} </b>
                            <p v-html="practice.practice"></p>
                          </div>
                          <button
                            class="btn btn-success waves-effect waves-light create-usage mt-3 sticky-button"
                            :disabled="selectedPractices.length == 0"
                            @click="definePractice"
                          >
                            <i class="fas fa-save"></i>
                            Tanımla
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Body End -->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <!-- end page title end breadcrumb -->
      </div>
      <!-- container -->

      <footer-text></footer-text>
      <!--end footer-->
    </div>
    <!-- end page content -->
  </div>
</template>

<script>
import footer from "@/components/Footer";
import HTTP from "@/main-source";
import Swal from "sweetalert2";

export default {
  name: "Usages",
  data() {
    return {
      usageId: this.$route.params.id,
      usage: [],
      categories: [],
      selectedPractices: [],
      subCategories: [],
      xhrProcess: true,
      xhrProcessCat: true
    };
  },
  components: {
    "footer-text": footer
  },
  created() {
    this.getUsage();
    this.getCategories();
  },
  methods: {
    getUsage: function() {
      HTTP.get("usages/" + this.usageId)
        .then(result => {
          this.usage = result.data.rows ? result.data.rows[0] : [];
          if (this.usage.practiceIds) {
            this.selectedPractices = [
              ...new Set(JSON.parse(this.usage.practiceIds))
            ];
          }
          this.xhrProcess = false;
          this.getWorkedApp();
        })
        .catch(() => {
          this.xhrProcess = false;
        });
    },
    getCategories: function() {
      HTTP.get("categories")
        .then(result => {
          this.categories = result.data;

          this.categories.forEach(category => {
            this.subCategories = this.subCategories.concat(category.categories);
          });
          this.xhrProcessCat = false;
        })
        .catch(() => {
          this.xhrProcessCat = false;
        });
    },
    getWorkedApp: function() {
      if (this.selectedPractices.length == 0) {
        HTTP.get("answers/usages/" + this.usageId).then(result => {
          if (result.data) {
            result.data.forEach(answer => {
              if (
                !this.selectedPractices.includes(answer.application.categoryId)
              ) {
                this.selectedPractices.push(answer.application.categoryId);
              }
            });
          }
        });
      }
    },
    selectPractice: function(id) {
      if (this.selectedPractices.includes(id)) {
        this.selectedPractices = this.selectedPractices.filter(
          practiceId => practiceId != id
        );
      } else {
        this.selectedPractices.push(id);
      }
    },
    listSelectedPractice: function() {
      return this.selectedPractices.map(categoryId => {
        var selectedPractice = this.subCategories.filter(
          category => category.id == categoryId
        )[0];
        return {
          name: selectedPractice ? selectedPractice.name : "",
          practice: selectedPractice ? selectedPractice.practice : ""
        };
      });
    },
    definePractice: function() {
      let data = { practice: JSON.stringify(this.selectedPractices) };
      HTTP.put("usages/" + this.usageId + "/practice", data)
        .then(() => {
          navigator.clipboard.writeText(
            this.$appDomain + "practices/" + this.usageId
          );

          Swal.fire({
            icon: "success",
            text: "Tanımlandı ve link kopyalandı",
            timer: 2500
          });
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            text:
              "Tanımlama sırasında hata oluştu, lütfen daha sonra tekrar deneyin.",
            timer: 2500
          });
        });
    }
  }
};
</script>

import axios from 'axios';

const HTTP = axios.create({
  baseURL: 'https://api.sobece.com/',
  //  baseURL: 'http://localhost:8080/',
});

const token = localStorage.token;

if (token) {
  HTTP.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export default HTTP;

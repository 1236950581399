<script>
/* eslint-disable */
import HTTP from "@/main-source";
import Swal from "sweetalert2";

export default {
  name: "Fill",
  data() {
    return {
      mainCategories: [],
      questions: [],
      xhrProcess: true,
      processDone: false,
      shortlink: this.$route.params.id,
      child: null
    };
  },
  created() {
    HTTP.get("assessments/answers/" + this.shortlink).then(res => {
      if (res.data.length == 1 && res.data[0].answers == null) {
        this.child = res.data[0].child;
        this.getCategories();
        this.getQuestions();
      } else {
        if (res.data.length == 0 || res.data.length > 1) {
          Swal.fire({
            icon: "error",
            text: "Hatalı link",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 1500
          });
          this.processDone = true;
          this.xhrProcess = false;
        } else {
          Swal.fire({
            icon: "warning",
            text: "Bu form daha önce doldurulmuş.",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 1500
          });
          this.processDone = true;
          this.xhrProcess = false;
        }
      }
    });
  },
  methods: {
    getQuestions: function() {
      HTTP.get("assessments").then(result => {
        this.questions = result.data.map(question => {
          return {
            id: question.id,
            question: question.question,
            categoryId: question.categoryId,
            answer: null
          };
        });
        this.xhrProcess = false;
      });
    },
    getCategories: function() {
      HTTP.get("categories/forscale").then(result => {
        this.mainCategories = result.data.map(cat => {
          return { id: cat.id, name: cat.name };
        });
      });
    },
    activeCategory: function(categoryId) {
      return this.questions.filter(
        question => question.categoryId == categoryId
      );
    },

    sendAnswers: function() {
      let unfilledcount = this.questions.filter(
        question => question.answer == null
      );
      let data = {
        shortlink: this.shortlink
      };

      if (unfilledcount.length > 0) {
        Swal.fire({
          icon: "warning",
          text: "Lütfen tüm soruları cevaplayınız.",
          timer: 1500,
          showCancelButton: false
        });
        // showConfirmButton: false
      } else {
        data.answers = JSON.stringify(
          this.questions.map(answer => {
            return {
              answer: answer.answer,
              categoryId: answer.categoryId,
              questionId: answer.id
            };
          })
        );
        HTTP.put("assessments/answers", data)
          .then(() => {
            Swal.fire({
              icon: "success",
              text: "Form başarıyla iletildi. Teşekkürler!",
              timer: 2500,
              showCancelButton: false
            });
            this.processDone = true;
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              text:
                "Kayıt sırasında hata meydana geldi, lütfen daha sonra tekrar deneyiniz!",
              showCancelButton: true
            });
          });
      }
    }
  }
};
</script>
<template>
  <div
    class="row mt-5 mr-3 ml-3 assessment-form"
    :class="[
      { 'centered-content': xhrProcess },
      { 'centered-content': processDone }
    ]"
  >
    <div
      class="spinner-border text-success"
      role="status"
      v-if="xhrProcess"
    ></div>
    <span v-if="processDone" class="h4 brand-color-orange">Teşekkürler!</span>

    <div
      class="col-12 col-sm-8 offset-sm-2 card p-4"
      v-if="!xhrProcess && !processDone"
    >
      <img src="@/assets/images/logo.svg" alt="Sobece Logo" class="logo" />
      <span class="h4"
        >SOBECE ÇOCUKTA SOSYAL BECERİLERİ DEĞERLENDİRME ÖLÇEĞİ</span
      >
      <p>
        ÇOCUĞUN ADI SOYADI:<b> {{ child.name }}</b
        ><br />

        ÇOCUĞUN DOĞUM TARİHİ:
        <b>{{ new Date(child.birthdate).toLocaleDateString("tr") }}</b>
      </p>
      <p>
        Her bir ifadeyi okuyarak değerlendirmeyi yaptığınız çocuğun, ilgili
        sosyal beceriye ne düzeyde sahip olduğunu gösteren seçeneği
        işaretleyiniz. Çocuğun o beceriye sahip olup olmadığını bilmiyorsanız
        kararsızım/bilmiyorum kutucuğunu işaretleyiniz.
      </p>
      <!-- Cat List -->
      <div v-for="(cat, index) in mainCategories" :key="cat.id">
        <span class="h4 brand-color-purple">
          {{ index + 1 }}. {{ cat.name }}</span
        >
        <div
          class="row"
          v-for="(question, index) in activeCategory(cat.id)"
          :key="question.id"
        >
          <div class="question custom-control custom-radio pl-3 ">
            <b>{{ index + 1 }}. {{ question.question }} </b>
            <div class="radio-group">
              <!-- Radio Group -->
              <div class="col">
                <input
                  type="radio"
                  :id="'customRadio1' + question.id"
                  :name="'customRadio' + question.id"
                  class="custom-control-input"
                  value="1"
                  v-model="question.answer"
                />
                <label
                  class="custom-control-label"
                  :for="'customRadio1' + question.id"
                  >Hiçbir Zaman</label
                >
              </div>
              <div class="col">
                <input
                  type="radio"
                  :id="'customRadio2' + question.id"
                  :name="'customRadio' + question.id"
                  class="custom-control-input"
                  value="2"
                  v-model="question.answer"
                />
                <label
                  class="custom-control-label"
                  :for="'customRadio2' + question.id"
                  >Bazen</label
                >
              </div>
              <div class="col">
                <input
                  type="radio"
                  :id="'customRadio3' + question.id"
                  :name="'customRadio' + question.id"
                  class="custom-control-input"
                  value="0"
                  v-model="question.answer"
                />
                <label
                  class="custom-control-label"
                  :for="'customRadio3' + question.id"
                  >Kararsızım / Bilmiyorum</label
                >
              </div>
              <div class="col">
                <input
                  type="radio"
                  :id="'customRadio4' + question.id"
                  :name="'customRadio' + question.id"
                  class="custom-control-input"
                  value="3"
                  v-model="question.answer"
                />
                <label
                  class="custom-control-label"
                  :for="'customRadio4' + question.id"
                  >Sıklıkla</label
                >
              </div>
              <div class="col">
                <input
                  type="radio"
                  :id="'customRadio5' + question.id"
                  :name="'customRadio' + question.id"
                  class="custom-control-input"
                  value="4"
                  v-model="question.answer"
                />
                <label
                  class="custom-control-label"
                  :for="'customRadio5' + question.id"
                  >Her Zaman</label
                >
              </div>
              <!-- Radio Group -->
            </div>
          </div>
        </div>
      </div>
      <!-- Cat List End -->
      <button class="btn btn-primary mt-2" @click="sendAnswers">Gönder</button>
    </div>
  </div>
</template>

<template>
  <div class="page-wrapper">
    <!-- Page Content-->
    <div class="page-content-tab c-size">
      <div class="container-fluid ">
        <!-- Body -->
        <div class="row">
          <div class="play-area">
            <div class="row actions">
              <div class="col-12 " :class="{ disabled: !isClientConnected }">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-8" :class="{ disabled: animations.show }">
                        <span class="action" @click="showAnim(null, 'smile')">
                          <img
                            src="/images/application/animations/list/smile.svg"
                            alt="Clap"
                          />
                        </span>
                        <span
                          class="action"
                          @click="showAnim('TEBRİKLER', 'ok')"
                        >
                          <img
                            src="/images/application/animations/list/like.svg"
                            alt="Clap"
                          />
                        </span>
                        <span
                          class="action"
                          @click="showAnim('TEBRİKLER', 'applause')"
                        >
                          <img
                            src="/images/application/animations/list/clap.svg"
                            alt="Clap"
                          />
                        </span>

                        <span
                          class="action"
                          @click="showAnim(null, 'fireworks')"
                        >
                          <img
                            src="/images/application/animations/list/fireworks.svg"
                            alt="Clap"
                          />
                        </span>
                        <span
                          class="action mr-5"
                          @click="showAnim(null, 'star')"
                        >
                          <img
                            src="/images/application/animations/list/star.svg"
                            alt="Clap"
                          />
                        </span>

                        <span
                          class="action ml-2"
                          @click="showAnim('Tekrar Dene', 'try-again')"
                        >
                          <img
                            src="/images/application/animations/list/try-again.svg"
                            alt="Clap"
                          />
                        </span>

                        <span
                          class="action"
                          @click="showAnim('Bu sefer olmadı', 'not-ok')"
                        >
                          <img
                            src="/images/application/animations/list/dislike.svg"
                            alt="Clap"
                          />
                        </span>

                        <span class="action" @click="showAnim(null, 'sad')">
                          <img
                            src="/images/application/animations/list/sad.svg"
                            alt="Clap"
                          />
                        </span>
                        <span
                          class="action ml-3"
                          @click="showAnim('Ekrana Odaklan', 'bell')"
                        >
                          <img
                            src="/images/application/animations/list/bell.svg"
                            alt="Clap"
                          />
                        </span>
                      </div>
                      <!-- <div class="col-4 text-right" v-if="selectedApplication">
                        <span class="action">
                          <img src="/images/application/play.svg" alt="Clap" />
                        </span>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card"
              id="play-area"
              :class="{
                'set-img-height': selectedApplication
                  ? selectedApplication.type != 6 &&
                    selectedApplication.type != 2 &&
                    selectedApplication.type != 5 &&
                    selectedApplication.type != 18
                    ? true
                    : false
                  : false
              }"
            >
              <div
                class="main-directive mt-3 col-10 offset-sm-1 no-selection"
                oncopy="return false"
                oncut="return false"
              >
                <p
                  class="lead text-center"
                  v-if="selectedApplication"
                  v-html="selectedApplication.directive"
                ></p>
              </div>
              <div
                class="card-body wrapper"
                :class="{ 'centered-content': !isClientConnected }"
              >
                <div
                  class="mouse"
                  :style="{ left: mouseX + 'px', top: mouseY + 'px' }"
                  v-if="isClientConnected"
                >
                  <img
                    src="/images/application/mouse.svg"
                    alt="Danışan Mouse"
                  />
                </div>
                <transition
                  name="fade"
                  enter-active-class="animate__animated animate__fadeIn"
                >
                  <component
                    oncopy="return false"
                    oncut="return false"
                    class="no-selection"
                    :is="applicationType"
                    :application="selectedApplication"
                    :childMouse="{ x: mouseX, y: mouseY }"
                    v-if="
                      selectedApplication &&
                        isClientConnected &&
                        showApplication
                    "
                  ></component>
                </transition>
                <!-- Animations -->
                <animations
                  :animations="animations"
                  v-if="animations.show && isClientConnected"
                ></animations>
                <!-- Animations -->

                <!-- Welcome Screen -->
                <div class="col-12 col-sm-8" v-if="!isClientConnected">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      id="clipboardInput"
                      :value="shortLink"
                      ref="shortLink"
                      readonly
                    />
                    <div class="input-group-append">
                      <button
                        type="button"
                        class="btn btn-gradient-secondary btn-clipboard"
                        @click="copyLink"
                      >
                        <i class="far fa-copy mr-2"></i> Kopyala
                      </button>
                    </div>
                    <div class="col-12 text-center mt-5">
                      <span class="warn brand-color-orange">
                        <b>*Görüşmeleriniz kesinlikle kayıt edilmemektedir.</b>
                      </span>
                    </div>
                    <div class="col-12 text-center mt-5">
                      <span
                        class="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span
                      ><br />
                      Uygulama başlangıcı için <br />danışanın katılımı
                      bekleniyor.
                    </div>
                  </div>
                </div>

                <!-- End Welcome Screen -->
                <!-- Test Area -->
                <div class="test" v-if="isClientConnected && false">
                  <input type="text" class="form-control" v-model="newActive" />
                  <button @click="setActive">Değiştir</button>
                  {{ active }}
                  <button @click="getRoomClients">Kaç kişi</button>
                  <!-- <img src="/images/application/animations/giphy.gif" alt="" /> -->
                </div>
                <!-- Test Area -->
              </div>
            </div>
          </div>
        </div>
        <!-- Body -->
      </div>
    </div>
  </div>
</template>
<script>
import HTTP from "@/main-source";
import Swal from "sweetalert2";
import EventBus from "@/components/event-bus";
import Animations from "@/components/application/Animations";
// Application Components
import OnlyText from "@/components/application/types/1";
import Draw from "@/components/application/types/exp-2";
import Slide from "@/components/application/types/3";
import TrueFalseCastle from "@/components/application/types/4";
import FindOnImage from "@/components/application/types/6";
import FillText from "@/components/application/types/7";
import DragAndOrder from "@/components/application/types/8";
import SlideDragAndOrderImage from "@/components/application/types/9";
import TimeTableDragAndOrder from "@/components/application/types/10";
import DoOrNotList from "@/components/application/types/11";
import TodoListWithTime from "@/components/application/types/12";
import Trello from "@/components/application/types/13";
import EventWeekCalendar from "@/components/application/types/14";
import HasOptionAndRightAnswer from "@/components/application/types/15";
import CustomAppStatusPerson from "@/components/application/types/16";
import CustomTwoInputSlide from "@/components/application/types/17";
import MixedCarousel from "@/components/application/types/18";
import TagCloud from "@/components/application/types/19";
import SingleToDoAndOrder from "@/components/application/types/20";
import VolcanoInputs from "@/components/application/types/21";
import SolutionsKit from "@/components/application/types/22";
import TrafficLights from "@/components/application/types/23";
import ConversationForest from "@/components/application/types/24";

export default {
  name: "Application",
  data() {
    return {
      usageInfo: null,
      usageId: this.$route.params.id,
      active: "bekleniyor...",
      newActive: null,
      user: JSON.parse(localStorage.getItem("user")),
      isClientConnected: false,
      shortLink: this.$appDomain + this.$route.params.id,
      mouseX: 0,
      mouseY: 0,
      applicationType: null,
      showApplication: false,
      applications: [],
      selectedApplication: null,
      pocketApplication: null,
      sessionCountDown: null,
      childId: null,
      animations: {
        text: "TEBRİKLER",
        gif: "applause",
        sounds: true,
        anim: null,
        show: false
      }
    };
  },
  components: {
    Animations,
    OnlyText,
    Draw,
    Slide,
    TrueFalseCastle,
    FindOnImage,
    FillText,
    DragAndOrder,
    SlideDragAndOrderImage,
    TimeTableDragAndOrder,
    DoOrNotList,
    TodoListWithTime,
    Trello,
    EventWeekCalendar,
    HasOptionAndRightAnswer,
    CustomAppStatusPerson,
    CustomTwoInputSlide,
    MixedCarousel,
    TagCloud,
    SingleToDoAndOrder,
    VolcanoInputs,
    SolutionsKit,
    TrafficLights,
    ConversationForest
  },
  created() {
    this.getUsageInfo().then(result => {
      HTTP.get("applications").then(result => {
        this.applications = result.data;
      });

      this.connect();
      this.usageInfo = result;
      this.childId = result.childId;
      EventBus.$emit("setChildId", this.childId);
    });
  },
  mounted() {
    EventBus.$on("applications", applications => {
      this.applications = applications;
    });
    EventBus.$on("applicationSetAndSendChild", applicationId => {
      this.applicationSetAndSendChild(applicationId);
    });
    EventBus.$on("getApplication", applicationId => {
      this.getApplication(applicationId);
    });
    EventBus.$on("endSession", () => {
      this.endSession();
    });
    EventBus.$on("show-warming-questions", questions => {
      // Save Answer if exists
      if (this.selectedApplication) {
        EventBus.$emit("getAnswers", true);
      }
      // Save Answer if exists END
      this.showWarmingQuestion(questions);
    });
    EventBus.$on("addNote", notes => {
      this.addNote(notes);
    });
    EventBus.$on("saveAnswers", answers => {
      this.saveAnswers(answers);
    });
    EventBus.$on("reSendActiveApplication", () => {
      this.$socket.client.emit("set-application", {
        to: this.usageId,
        application: this.selectedApplication
      });
    });

    EventBus.$on("showAnim", (text, which) => {
      this.showAnim(text, which);
    });
  },
  destroyed() {
    EventBus.$off("applications");
    EventBus.$off("applicationSetAndSendChild");
    EventBus.$off("getApplication");
    EventBus.$off("addNote");
    EventBus.$off("stopTimer");
    EventBus.$off("show-warming-questions");
    EventBus.$off("saveAnswers");
    this.$socket.client.emit("leave-room", { to: this.usageId });
    clearInterval(this.sessionCountDown);
  },
  sockets: {
    connect() {
      // console.log("socket connected");
    },
    moving(event) {
      let carousel = document.getElementsByClassName("carousel-thumbs");
      let offsetHeight = 0;

      if (carousel[0]) {
        offsetHeight = carousel[0].offsetHeight - 40;
      }
      this.mouseX = event.clientX;
      this.mouseY = event.clientY + offsetHeight;
    },
    childConnected: function() {
      this.isClientConnected = true;
      this.$notify({
        group: "general",
        type: "success",
        text: "<i class='mdi mdi-check-all alert-icon'></i> Bağlantı Kuruldu",
        duration: 4000
      });

      if (this.selectedApplication && this.applicationType) {
        this.$socket.client.emit("set-application", {
          to: this.usageId,
          application: this.selectedApplication
        });
      }

      this.stopTimer();
      this.startSession();
    },
    psyConnectedAgain: function() {
      this.isClientConnected = true;
      this.$notify({
        group: "general",
        type: "success",
        text:
          "<i class='mdi mdi-check-all alert-icon'></i> Bağlantı Tekrar Kuruldu",
        duration: 4000
      });
      this.stopTimer();
      this.startSession();
    },
    childDisconnected: function() {
      this.isClientConnected = false;
      this.$notify({
        group: "general",
        type: "error",
        text: "Bağlantı Kapatıldı",
        duration: 4000
      });
      this.stopTimer();
    },
    showAnim: function(animations) {
      this.animations = animations;
      setTimeout(() => {
        this.animations.show = false;
      }, 3500);
    }
  },
  methods: {
    getUsageInfo: function() {
      let usageId = this.usageId;

      return new Promise((resolve, reject) => {
        HTTP.get("usages/" + usageId)
          .then(result => {
            let usage = result.data.rows[0];

            if (usage.remainingMinutes) {
              let display = document.querySelector("#left-time");
              display.textContent = usage.remainingMinutes + ":" + "00";
            }

            if (result.data.rows.length == 0) {
              reject();
              Swal.fire({
                icon: "error",
                text:
                  "Geçersiz link, Lütfen yeni oturum başlatın. Yönlendiriliyorsunuz.",
                timer: 3500,
                showCancelButton: false,
                showConfirmButton: false
              });
              setTimeout(() => {
                window.location = this.$appDomain;
              }, 3500);
            }

            const now = new Date();
            const startedAt = usage.startedAt
              ? new Date(usage.startedAt)
              : new Date(usage.createdAt);
            const diffMinutes = Math.abs(now - startedAt) / 60000;

            if (usage.status == 2 || diffMinutes > 120) {
              reject();
              Swal.fire({
                icon: "error",
                text:
                  "Bu link daha önce kullanılmış ve artık devre dışı. Lütfen yeni oturum başlatın, Yönlendiriliyorsunuz.",
                timer: 3500,
                showCancelButton: false,
                showConfirmButton: false
              });

              // Change Usage Status
              if (diffMinutes > 120) {
                HTTP.put("usages/" + usageId + "/done");
              }
              // Change Usage Status

              setTimeout(() => {
                window.location = this.$appDomain;
              }, 3500);
            } else {
              resolve(usage);
            }
          })
          .catch(() => {
            reject();
          });
      });
    },
    connect: function() {
      let data = {
        nickname: this.user.name,
        room: this.usageId
      };
      this.$socket.client.emit("connect-room", data);
    },
    copyLink: function() {
      var shortlink = this.$refs.shortLink;
      shortlink.innerHTML = window.location.href;
      shortlink.select();
      document.execCommand("copy");
      Swal.fire({
        icon: "success",
        text: "Kopyalandı",
        timer: 1500,
        showCancelButton: false,
        showConfirmButton: false
      });
    },
    setActive: function() {
      this.$socket.client.emit("setActive", {
        to: this.usageId,
        m: this.newActive
      });
    },
    getRoomClients: function() {
      this.$socket.client.emit("get-room-clients", { name: this.usageId });
    },
    setApplicationType: function() {
      this.showApplication = false;
      this.applicationType = this.$setApplicationType(
        this.selectedApplication.type
      );
      setTimeout(() => {
        this.showApplication = true;
      }, 50);
    },
    returnApplicationType: function(type) {
      return this.$setApplicationType(type);
    },
    applicationSetAndSendChild: function(applicationId) {
      // Save Answer if exists
      if (this.pocketApplication) {
        EventBus.$emit("getAnswers", true);
      }
      // Save Answer if exists END

      let application = this.applications.filter(
        application => application.id == applicationId
      )[0];

      this.selectedApplication = application;
      this.pocketApplication = application;
      this.$socket.client.emit("set-application", {
        to: this.usageId,
        application
      });

      // Updating note place view data
      EventBus.$emit("updateNoteApplication", this.selectedApplication);
      // Getting Application Notes
      this.getNotes();
      this.setApplicationType();
    },
    getApplication: function(applicationId) {
      //  // Save Answer if exists
      // if (this.pocketApplication) {
      //   EventBus.$emit("getAnswers", true);
      // }
      // // Save Answer if exists END
      let application = this.applications.filter(
        application => application.id == applicationId
      )[0];
      this.selectedApplication = application;
      this.setApplicationType();
    },
    getNotes: function() {
      HTTP.get(
        "answers/" + this.selectedApplication.id + "/" + this.childId
      ).then(result => {
        EventBus.$emit("setApplicationNotes", result.data[0]);
      });
    },
    startSession: function() {
      let display = document.querySelector("#left-time");

      HTTP.put("usages/" + this.usageId + "/begin")
        .then(() => {
          clearInterval(this.sessionCountDown);

          this.getUsageInfo().then(usageInfo => {
            let remainingMinutes =
              (usageInfo.remainingMinutes ? usageInfo.remainingMinutes : 50) *
              60;

            this.startTimer(remainingMinutes, display);
          });
        })
        .catch(() => {
          clearInterval(this.sessionCountDown);
        });
    },
    startTimer: function(duration, display) {
      var timer = duration,
        usageId = this.usageId,
        minutes,
        seconds;

      this.sessionCountDown = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        display.textContent = minutes + ":" + seconds;

        if (seconds == 59) {
          this.remainingMinutes = minutes;

          HTTP.put("usages/" + usageId + "/remaining", {
            remainingMinutes: minutes
          });
        }

        // Last 5 minutes
        if (timer == 300) {
          Swal.fire({
            icon: "info",
            text: "Son 5 dakika",
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: "Tamam"
          });
          this.$socket.client.emit("showAlert", {
            to: this.usageId,
            alert: {
              type: "leftTime",
              last: 5
            }
          });
        }

        // Last 2 minutes
        if (timer == 120) {
          Swal.fire({
            icon: "info",
            text: "Son 2 dakika",
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: "Tamam"
          });
          this.$socket.client.emit("showAlert", {
            to: this.usageId,
            alert: {
              type: "leftTime",
              last: 2
            }
          });
        }

        if (--timer < 0) {
          this.stopTimer();
          this.$socket.client.emit("sessionEnd", {
            to: this.usageId
          });
          HTTP.put("usages/" + usageId + "/done").then(() => {
            window.location = this.$appDomain;
          });
        }
      }, 1000);
    },
    stopTimer: function() {
      clearInterval(this.sessionCountDown);
    },
    showWarmingQuestion: function(question) {
      this.applicationType = null;
      this.selectedApplication = { directive: question, type: -1, steps: null };
      this.$socket.client.emit("set-application", {
        to: this.usageId,
        application: null
      });
    },
    addNote: function(notes) {
      let data = {
        note: JSON.stringify(notes),
        applicationId: this.selectedApplication.id,
        usageId: this.usageId,
        childId: this.childId
      };
      HTTP.post("answers", data).catch(() => {
        this.$notify({
          group: "general",
          type: "error",
          text:
            "Not alırken hata oluştu lütfen aktif oyunu tekrar seçerek tekrar deneyiniz.",
          duration: 1000
        });
      });
    },
    triggerDone: function() {
      EventBus.$emit("getAnswers", true);
    },
    dataURLtoFile: function(dataurl, filename) {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1);
        n -= 1; // to make eslint happy
      }
      return new File([u8arr], filename, { type: mime });
    },
    saveAnswers: function(answers = null) {
      let formData = new FormData();
      formData.append("applicationId", this.selectedApplication.id);
      formData.append("usageId", this.usageId);
      formData.append("childId", this.childId);

      if (
        this.selectedApplication.type == 2 ||
        this.selectedApplication.type == 5 ||
        this.selectedApplication.type == 18
      ) {
        if (typeof answers == "string") {
          formData.append(
            "files",
            this.dataURLtoFile(answers),
            "answer-" +
              this.selectedApplication.id +
              "-" +
              this.usageId +
              ".png"
          );
        } else if (answers) {
          formData.append("answer", JSON.stringify(answers));
        }
      } else {
        if (answers) {
          formData.append("answer", JSON.stringify(answers));
        }
      }

      HTTP.post("answers", formData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
        .then(() => {
          this.$notify({
            group: "general",
            type: "success",
            text: "<i class='mdi mdi-check-circle-outline'></i> Kaydedildi",
            duration: 4000
          });
        })
        .catch(() => {
          this.$notify({
            group: "general",
            type: "error",
            text:
              "Kaydedilirken bir hata oluştur, lütfen tekrar deneyin yada başka bir uygulamaya geçin.",
            duration: 4000
          });
        });
    },
    showAnim: function(text, which) {
      this.animations.text = text;
      this.animations.gif = which;
      this.animations.show = true;

      this.$socket.client.emit("showAnim", {
        to: this.usageId,
        animations: this.animations
      });
      setTimeout(() => {
        this.animations.show = false;
      }, 3500);
    },
    endSession: function() {
      this.$socket.client.emit("sessionEnd", {
        to: this.usageId
      });
      // Save Answer if exists
      if (this.selectedApplication) {
        EventBus.$emit("getAnswers", true);
      }
      // Save Answer if exists END

      // TO BE FIXED
      setTimeout(() => {
        HTTP.put("usages/" + this.usageId + "/done").then(() => {
          window.location = this.$appDomain;
        });
      }, 1500);
    }
  },
  computed: {
    pocketAndSelectedNotSame: function() {
      let isNotSame = false;
      if (this.pocketApplication && this.selectedApplication) {
        if (this.pocketApplication.id != this.selectedApplication.id) {
          isNotSame = true;
        }
      }
      return isNotSame;
    }
  }
};
</script>
<style>
.c-size {
  padding: 0 10px 0px 10px !important;
  margin-top: 60px !important;
}
</style>

<template>
  <div>
    <div class="table-responsive usagerights-list">
      <table class="table table-hover mb-0">
        <thead>
          <tr>
            <th>Adet</th>
            <th>Açıklama</th>
            <th>Tarih</th>
            <th>Kişi</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="usageright in usagerights.usagerights"
            :key="usageright.id"
          >
            <td>
              {{ usageright.count }}
            </td>
            <td>
              {{ usageright.desc }}
            </td>
            <td>{{ new Date(usageright.createdAt).toLocaleString("tr") }}</td>
            <td>
              {{ usageright.user.name }}
            </td>
          </tr>
        </tbody>
      </table>
      <!--end /table-->
    </div>
  </div>
</template>

<script>
export default {
  name: "usagerights",
  props: ["usagerights"],
  data() {
    return {
      role: localStorage.getItem("role")
    };
  }
};
</script>

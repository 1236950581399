import Vue from 'vue';
import App from './App.vue';
import Vuelidate from 'vuelidate';
import router from './router';
import vSelect from 'vue-select';
import VueTheMask from 'vue-the-mask';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Notifications from 'vue-notification';
import VueUploadComponent from 'vue-upload-component';
// Text Editor


// Socket
import * as io from 'socket.io-client';
import VueSocketIO from 'vue-socket.io-extended';

window.io = io;

Vue.use(Vuelidate);
Vue.use(VueTheMask);
Vue.use(Notifications);
Vue.component('file-upload', VueUploadComponent);

// Socket
Vue.use(VueSocketIO, io('https://api.sobece.com/'));
Vue.prototype.$appDomain = 'https://app.sobece.com/';
Vue.prototype.$apiDomain = 'https://api.sobece.com/';


// Vue.use(VueSocketIO, io('http://localhost:8020/'));
// Vue.prototype.$appDomain = 'http://localhost:8081/';
// Vue.prototype.$apiDomain = 'http://localhost:8080/';



Vue.prototype.$setApplicationType = function(type) {
  let applicationType = null;
  switch (type) {
    case 1:
      applicationType = 'OnlyText';
      break;
    case 2:
      applicationType = 'Draw';
      break;
    case 3:
      applicationType = 'Slide';
      break;
    case 4:
      applicationType = 'TrueFalseCastle';
      break;
    case 5:
      applicationType = 'Draw';
      break;
    case 6:
      applicationType = 'FindOnImage';
      break;
    case 7:
      applicationType = 'FillText';
      break;
    case 8:
      applicationType = 'DragAndOrder';
      break;
    case 9:
      applicationType = 'SlideDragAndOrderImage';
      break;
    case 10:
      applicationType = 'TimeTableDragAndOrder';
      break;
    case 11:
      applicationType = 'DoOrNotList';
      break;
    case 12:
      applicationType = 'TodoListWithTime';
      break;
    case 13:
      applicationType = 'Trello';
      break;
    case 14:
      applicationType = 'EventWeekCalendar';
      break;
    case 15:
      applicationType = 'HasOptionAndRightAnswer';
      break;
    case 16:
      applicationType = 'CustomAppStatusPerson';
      break;
    case 17:
      applicationType = 'CustomTwoInputSlide';
      break;
    case 18:
      applicationType = 'MixedCarousel';
      break;
    case 19:
      applicationType = 'TagCloud';
      break;
    case 20:
      applicationType = 'SingleToDoAndOrder';
      break;
    case 21:
      applicationType = 'VolcanoInputs';
      break;
    case 22:
      applicationType = 'SolutionsKit';
      break;
    case 23:
      applicationType = 'TrafficLights';
      break;
    case 24:
      applicationType = 'ConversationForest';
      break;
  }
  return applicationType;
};

Vue.component('v-select', vSelect);
Vue.component('datepicker', DatePicker);

Vue.config.productionTip = false;

Vue.prototype.$childAge = function(birthdate) {
  if (birthdate) {
    const now = new Date();
    const childBirtdate = new Date(birthdate);
    return ((now - childBirtdate) / (1000 * 60 * 60 * 24) / 365.4).toFixed(0);
  } else {
    return '';
  }
};

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');

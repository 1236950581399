<template>
  <div class="page-wrapper">
    <!-- Page Content-->
    <div class="page-content-tab">
      <div class="container-fluid">
        <!-- Page-Title -->
        <div class="row">
          <div class="col-sm-12">
            <div class="page-title-box">
              <h4 class="page-title">Kurum Yönetimi</h4>
              <div class="float-right">
                <div class="row">
                  <div class=" col-7 input-group">
                    <input
                      type="text"
                      id="example-input1-group2"
                      name="example-input1-group2"
                      v-model="search"
                      class="form-control"
                      placeholder="Ara..."
                    /><span class="input-group-prepend">
                      <button
                        type="button"
                        class="btn btn-gradient-primary"
                        @click="searchClients"
                      >
                        <i class="fas fa-search"></i>
                      </button>
                    </span>
                  </div>
                  <div class="col text-right">
                    <router-link
                      class="btn btn-primary waves-effect waves-light"
                      :to="{
                        name: 'CreateClient'
                      }"
                    >
                      + Yeni Kurum
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <!--end page-title-box-->

            <!-- Body -->

            <div
              class="row"
              :class="[
                { 'centered-content': xhrProcess },
                { 'centered-content': !xhrProcess && clients.length == 0 }
              ]"
            >
              <div
                class="spinner-border text-success"
                role="status"
                v-if="xhrProcess"
              ></div>

              <div
                class="text-warning"
                role="status"
                v-if="!xhrProcess && clients.length == 0"
              >
                Sonuç bulunamadı.
              </div>

              <client
                v-for="client in clients"
                :client="client"
                :key="client.id"
              ></client>
            </div>

            <!-- Body End -->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <div class="row">
          <div class="col">
            <nav>
              <ul class="pagination float-right">
                <li class="page-item" v-if="false">
                  <a class="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">«</span>
                    <span class="sr-only">Previous</span>
                  </a>
                </li>
                <li
                  class="page-item"
                  v-for="i in totalPages"
                  :key="i"
                  :class="{ active: currentPage == i }"
                  @click="currentPage = i"
                >
                  <span class="page-link">{{ i }}</span>
                </li>
                <li class="page-item" v-if="false">
                  <a class="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">»</span>
                    <span class="sr-only">Next</span>
                  </a>
                </li>
              </ul>
              <!--end pagination-->
            </nav>
          </div>
        </div>
        <!-- end page title end breadcrumb -->
      </div>
      <!-- container -->

      <footer-text></footer-text>
      <!--end footer-->
    </div>
    <!-- end page content -->
  </div>
</template>

<script>
import footer from "@/components/Footer";
import client from "@/components/list/Client";
import HTTP from "@/main-source";
import router from "@/router";

export default {
  name: "Clients",
  data() {
    return {
      clients: [],
      hasError: false,
      xhrProcess: true,
      role: localStorage.getItem("role"),
      search: null,
      totalPages: null,
      currentPage: 1
    };
  },
  components: {
    "footer-text": footer,
    client
  },
  created() {
    this.getClients();
  },
  methods: {
    getClients() {
      if (this.role == 1) {
        let uri = "firms";
        this.xhrProcess = true;

        if (this.currentPage != 1) {
          uri += "?page=" + this.currentPage;
        }

        HTTP.get(uri)
          .then(result => {
            this.totalPages = Math.ceil(result.data.count / 12);
            this.clients = result.data.rows;
            this.xhrProcess = false;
          })
          .catch(e => {
            this.hasError = true;
            console.log(e);
            this.xhrProcess = false;
          });
      } else {
        router.push({ name: "Home" });
      }
    },
    searchClients: function() {
      let uri = "firms";
      if (this.search) {
        if (this.search.length >= 3) {
          uri += "?q=" + this.search;
          HTTP.get(uri)
            .then(result => {
              this.totalPages = Math.ceil(result.data.count / 12);
              this.clients = result.data.rows;
              this.xhrProcess = false;
            })
            .catch(e => {
              this.hasError = true;
              console.log(e);
            });
        }
      }
    }
  },
  watch: {
    search: function() {
      this.currentPage = 1;
      if (!this.search) {
        this.getClients();
      } else {
        this.searchClients();
      }
    },
    currentPage: function() {
      this.getClients();
    }
  }
};
</script>

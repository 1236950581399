<template>
  <div class="animations">
    <h1 class="h1 animate__animated animate__jackInTheBox">
      {{ animations.text ? animations.text : "" }}
    </h1>
    <img
      :src="'/images/application/animations/' + animations.gif + '.gif'"
      :class="{
        'animate__animated animate__delay-1s':
          animations.gif != 'fireworks' && animations.gif != 'star'
      }"
      alt="Animations"
      v-if="animations.gif"
    />
  </div>
</template>
<script>
export default {
  name: "animations",
  props: ["animations"],
  data() {
    return {
      animated: true
    };
  },
  mounted() {
    setTimeout(() => {
      this.animated = false;
    }, 5000);

    if (this.animations.sounds) {
      this.soundEffect(
        this.$appDomain +
          "images/application/animations/sounds/" +
          this.animations.gif +
          ".mp3"
      );
    }
  },
  methods: {
    soundEffect: function(src) {
      this.sound = document.createElement("audio");
      this.sound.src = src;
      this.sound.setAttribute("preload", "auto");
      this.sound.setAttribute("controls", "none");
      this.sound.style.display = "none";
      document.body.appendChild(this.sound);
      this.sound.play();
      setTimeout(() => {
        this.sound.pause();
      }, 5000);
    }
  }
};
</script>

<template>
  <div class="page-wrapper">
    <!-- Page Content-->
    <div class="page-content-tab">
      <div class="container-fluid">
        <!-- Page-Title -->
        <div class="row">
          <div class="col-sm-12">
            <div class="page-title-box">
              <h4 class="page-title">Uygulanan Eğitimler</h4>
            </div>
            <!--end page-title-box-->

            <!-- Body -->

            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <usageList :usages="usages" v-if="!xhrProcess"></usageList>
                  </div>
                </div>
              </div>
            </div>

            <!-- Body End -->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <!-- end page title end breadcrumb -->
      </div>
      <!-- container -->

      <footer-text></footer-text>
      <!--end footer-->
    </div>
    <!-- end page content -->
  </div>
</template>

<script>
import footer from "@/components/Footer";
import HTTP from "@/main-source";
import usageList from "@/components/clientDetail/usages";
export default {
  name: "Usages",
  data() {
    return {
      usages: [],
      xhrProcess: true
    };
  },
  components: {
    "footer-text": footer,
    usageList
  },
  created() {
    this.getUsages();
  },
  methods: {
    getUsages: function() {
      let authUser = JSON.parse(localStorage.getItem("user"));

      HTTP.get("usages/byuser/" + authUser.userId)
        .then(result => {
          this.usages = result;
          this.xhrProcess = false;
        })
        .catch(() => {
          this.xhrProcess = false;
        });
    }
  }
};
</script>

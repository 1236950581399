import Vue from 'vue';
import Router from 'vue-router';
import nprogress from 'nprogress';
import HTTP from '@/main-source';
import 'nprogress/nprogress.css';
// Components
import Sidebar from '@/components/Sidebar';
import Topbar from '@/components/Topbar';
import Footer from '@/components/Footer';
// Application Components
import ASidebar from '@/components/application/Sidebar';
import ChildSidebar from '@/components/application/Sidebar-Child';
import ATopbar from '@/components/application/Topbar';
// Views
import Home from '@/views/Home';
import Login from '@/views/Login';
import Childs from '@/views/Childs';
import ChildDetail from '@/views/ChildDetail';
import CreateChild from '@/views/CreateChild';
import Usages from '@/views/Usages';
import Clients from '@/views/Clients';
import ClientDetail from '@/views/ClientDetail';
import CreateClient from '@/views/CreateClient';
import MyAccount from '@/views/MyAccount';
import FillAssessment from '@/views/assessment/Fill.vue';
import ConsentForm from '@/views/ConsentFormOk.vue';
import PracticeDefine from '@/views/PracticeDefine.vue';
import PracticeView from '@/views/PracticeView.vue';
// Application Viewss
import AppChildSide from '@/views/application/ChildSide';
import Application from '@/views/application/Index';
// Reports
import ApplicationForm from '@/views/reports/ApplicationForm.vue';
import SobeceReport from '@/views/reports/SobeceReport.vue';
// Answers
import SobeceAnswers from '@/views/assessment/View.vue';

Vue.use(Router);
Vue.use(nprogress);

nprogress.configure({
  showSpinner: false,
});

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      components: {
        sidebar: Sidebar,
        topbar: Topbar,
        default: Home,
        footer: Footer,
      },
      meta: {
        title: 'Home',
        requiresAuth: true,
      },
    },
    {
      path: '/childs',
      name: 'Childs',
      components: {
        sidebar: Sidebar,
        topbar: Topbar,
        default: Childs,
        footer: Footer,
      },
      meta: {
        title: 'Childs',
        requiresAuth: true,
      },
    },
    {
      path: '/childs/:childId/reports/application',
      name: 'ApplicationForm',
      components: {
        default: ApplicationForm,
      },
      meta: {
        title: 'Assessment Fill',
        requiresAuth: true,
      },
    },
    {
      path: '/childs/:childId/reports/sobece',
      name: 'SobeceReport',
      components: {
        default: SobeceReport,
      },
      meta: {
        title: 'Assessment Fill',
        requiresAuth: true,
      },
    },
    {
      path: '/answers/:shortlink',
      name: 'SobeceAnswers',
      components: {
        default: SobeceAnswers,
      },
      meta: {
        title: 'Assessment Answers',
        requiresAuth: true,
      },
    },
    {
      path: '/childs/add',
      name: 'CreateChild',
      components: {
        sidebar: Sidebar,
        topbar: Topbar,
        default: CreateChild,
        footer: Footer,
      },
      meta: {
        title: 'Childs',
        requiresAuth: true,
      },
    },
    {
      path: '/childs/:id',
      name: 'ChildDetail',
      components: {
        sidebar: Sidebar,
        topbar: Topbar,
        default: ChildDetail,
        footer: Footer,
      },
      meta: {
        title: 'Childs',
        requiresAuth: true,
      },
    },
    {
      path: '/usages',
      name: 'Usages',
      components: {
        sidebar: Sidebar,
        topbar: Topbar,
        default: Usages,
        footer: Footer,
      },
      meta: {
        title: 'Usages',
        requiresAuth: true,
      },
    },
    {
      path: '/users/:id',
      name: 'UserProfile',
      components: {
        sidebar: Sidebar,
        topbar: Topbar,
        default: MyAccount,
        footer: Footer,
      },
      meta: {
        title: 'My Account',
        requiresAuth: true,
      },
    },
    {
      path: '/myaccount',
      name: 'MyAccount',
      components: {
        sidebar: Sidebar,
        topbar: Topbar,
        default: MyAccount,
        footer: Footer,
      },
      meta: {
        title: 'My Account',
        requiresAuth: true,
      },
    },
    {
      path: '/clients',
      name: 'Clients',
      components: {
        sidebar: Sidebar,
        topbar: Topbar,
        default: Clients,
        footer: Footer,
      },
      meta: {
        title: 'Clients',
        requiresAuth: true,
      },
    },
    {
      path: '/clients/add',
      name: 'CreateClient',
      components: {
        sidebar: Sidebar,
        topbar: Topbar,
        default: CreateClient,
        footer: Footer,
      },
      meta: {
        title: 'Create Client',
        requiresAuth: true,
      },
    },
    {
      path: '/clients/:id',
      name: 'ClientDetail',
      components: {
        sidebar: Sidebar,
        topbar: Topbar,
        default: ClientDetail,
        footer: Footer,
      },
      meta: {
        title: 'Client Details',
        requiresAuth: true,
      },
    },
    {
      path: '/login',
      name: 'Login',
      components: { default: Login },
    },
    // Application
    {
      path: '/application/:id?',
      name: 'App',
      components: {
        sidebar: ASidebar,
        topbar: ATopbar,
        default: Application,
      },
      meta: {
        title: 'App Admin Side',
        requiresAuth: true,
      },
    },
    {
      path: '/assessment/:id?',
      name: 'Assessment',
      components: {
        default: FillAssessment,
      },
      meta: {
        title: 'Assessment Fill',
        requiresAuth: false,
      },
    },
    {
      path: '/consentform/:id?',
      name: 'ConsentForm',
      components: {
        default: ConsentForm,
      },
      meta: {
        title: 'Consent Form',
        requiresAuth: false,
      },
    },
    {
      path: '/practice/:id',
      name: 'PracticeDefine',
      components: {
        sidebar: Sidebar,
        topbar: Topbar,
        default: PracticeDefine,
        footer: Footer,
      },
      meta: {
        title: 'Practice Define',
        requiresAuth: true,
      },
    },
    {
      path: '/practices/:id',
      name: 'PracticeView',
      components: {
        default: PracticeView,
      },
      meta: {
        title: 'Practice View',
        requiresAuth: false,
      },
    },
    {
      path: '/:id?',
      name: 'AppChildSide',
      components: {
        sidebar: ChildSidebar,
        default: AppChildSide,
      },
      meta: {
        title: 'Child Application PAge',
        requiresAuth: false,
      },
    },
  ],
});

router.beforeResolve((to, from, next) => {
  if (to.path) {
    nprogress.start();
  }
  next();
});

router.afterEach(() => {
  nprogress.done();
});

/* eslint-disable */
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let token = localStorage.token;

    if (!token) {
      HTTP.defaults.headers.common.Authorization = '';
      next({
        path: '/login',
      });
    }

    HTTP.get('/users/checkAuth').catch((result) => {
      if (result.response.status == 401) {
        localStorage.removeItem('token');
        HTTP.defaults.headers.common.Authorization = '';
        next({
          path: '/login',
        });
      }
    });

    if (to.path == '/login' && token) {
      next({
        path: '/',
      });
    }
  }
  next();
});
/* eslint-disable */

export default router;

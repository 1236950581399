<template>
  <!-- Left Sidenav -->
  <div>
    <div class="left-sidenav bigger-side">
      <!-- LOGO -->
      <div class="topbar-left">
        <router-link class="logo" :to="{ name: 'Home' }">
          <span>
            <img
              src="@/assets/images/logo.svg"
              alt="logo-small"
              class="logo-md"
            />
          </span>
          <span>
            <img
              src="@/assets/images/logo.svg"
              alt="logo-large"
              class="logo-lg logo-light"
            />
          </span>
        </router-link>
      </div>
      <!--end logo-->

      <div class="col-12">
        <div class="live-video">
          <vue-webrtc
            width="100%"
            ref="webrtc"
            :roomId="'sysb_' + roomId"
            socketURL="https://rtcsignal.sobece.com/"
            v-if="true"
          >
          </vue-webrtc>
        </div>
      </div>
    </div>
  </div>
  <!-- end left-sidenav-->
</template>

<script>
import webrtc from "@/components/webrtc";
import Swal from "sweetalert2";

export default {
  name: "sidebar",
  data() {
    return {
      authUser: JSON.parse(localStorage.getItem("user")),
      role: localStorage.getItem("role"),
      categories: [],
      applications: [],
      xhrCategories: true,
      roomId: this.$route.params.id
    };
  },
  components: {
    "vue-webrtc": webrtc
  },
  sockets: {
    childConnected: function() {
      this.roomId = this.$route.params.id;
      navigator.getUserMedia(
        // constraints
        {
          video: true,
          audio: true
        },

        // successCallback
        () => {
          this.$refs.webrtc.join();
        },

        // errorCallback
        err => {
          if (err) {
            Swal.fire({
              icon: "error",
              text:
                "Kameranıza ve/veya mikrofonunuza erişemiyoruz. Lütfen kameranızın çalıştığından, kamera ve mikrofon izinlerini verdiğinizden emin olunuz.",
              showCancelButton: false,
              showConfirmButton: false,
              allowOutsideClick: false
            });
          }
        }
      );
    },
    sessionEnd: function() {
      window.removeEventListener("beforeunload", this.preventNav);
      this.$refs.webrtc.leave();
    },
    reJoin: function() {
      this.renew();
    }
  },

  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNav);
  },

  methods: {
    renew: function() {
      var isChrome =
        /Chrome/.test(navigator.userAgent) &&
        /Google Inc/.test(navigator.vendor);

      if (!isChrome) {
        window.removeEventListener("beforeunload", this.preventNav);
        location.reload();
      } else {
        this.$refs.webrtc.leave();
        setTimeout(() => {
          this.$refs.webrtc.join();
        }, 1500);
      }
    },
    preventNav: function(event) {
      event.preventDefault();
      event.returnValue = "";
      this.$refs.webrtc.leave();
    }
  }
};
</script>

<template>
  <div id="app">
    <router-view name="sidebar" />
    <router-view name="topbar" />
    <router-view />
    <router-view name="footer" />
    <notifications group="general"  position="top center" />
    <notifications group="bottom"  position="bottom center" />
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
@import "@/assets/scss/main.scss";
</style>

<script>
/*eslint-disable */
import { Doughnut } from "vue-chartjs";

export default {
  name: "DoughnutChart",
  extends: Doughnut,
  props: ["graphData"],
  mounted() {
    let ages = this.graphData.map(data => {
      return data.age + " yaş";
    });
    let count = this.graphData.map(data => {
      return data.count;
    });
    let datasets = [
      {
        label: ages,
        backgroundColor: [
          "#992367",
          "#f48333",
          "#2c96a4",
          "#f2828e",
          "#4e849a",
          "#a84d5c",
          "#fd530e",
          "#2c85ac",
          "#3bb99d",
          "#bc1560",
          "#7883a9",
          "#fb43e0",
          "#4e59c7",
          "#5fbef6",
          "#6eb29d"
        ],
        data: count,
        datalabels: {
          color: "#fff"
        }
      }
    ];

    setTimeout(() => {
      this.renderChart(
        {
          labels: ages,
          datasets
        },

        {
          legend: {
            display: true
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                var dataset = data.datasets[tooltipItem.datasetIndex];

                var total = dataset.data.reduce(function(a, b) {
                  return a + b;
                });

                var currentValue = dataset.data[tooltipItem.index];

                var percentage = Math.floor((currentValue / total) * 100 + 0.5);

                return " " + percentage + "%";
              }
            }
          }
        }
      );
    }, 300);
  }
};
</script>

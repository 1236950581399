<template>
  <div class="row">
    <div class="col-12 table-todo">
      <div class="row table-head">
        <div class="col-3">Saat</div>
        <div class="col ">
          Yapılacaklar
        </div>
      </div>
      <draggable
        tag="ul"
        :list="list"
        class="list-group"
        handle=".handle"
        @end="sync"
      >
        <div
          class="list-group-item form-group row"
          v-for="(element, idx) in list"
          :key="element.name"
        >
          <i class="fa fa-align-justify handle"></i>

          <input
            type="time"
            class="form-control col-2 ml-5"
            v-model="element.time"
            @change="sync"
          />
          <input
            type="text"
            class="form-control col ml-2"
            v-model="element.text"
            @change="sync"
            placeholder="Buraya yazabilirsin."
          />

          <i class="fa fa-times close" @click="removeAt(idx)"></i>
        </div>
      </draggable>
      <button class="btn btn-secondary button float-right" @click="add">
        Yeni Ekle
      </button>
    </div>
  </div>
</template>

<script>
import EventBus from "@/components/event-bus";
let id = 0;
import draggable from "vuedraggable";
export default {
  name: "timeTableDragAndOrder",
  order: 5,
  props: ["application"],
  components: {
    draggable
  },
  data() {
    return {
      list: [{ time: "", text: "", id: 0 }],
      dragging: false
    };
  },
  mounted() {
    EventBus.$on("getAnswers", () => {
      this.saveAnswers();
    });
  },
  beforeDestroy: function() {
    EventBus.$off("getAnswers");
  },

  computed: {
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    }
  },
  sockets: {
    syncAnswers: function(answers) {
      if (this.application.id == answers.applicationId) {
        this.list = answers.answers;
      }
    }
  },
  methods: {
    removeAt(idx) {
      this.list.splice(idx, 1);
      this.sync();
    },
    add: function() {
      id++;
      this.list.push({ name: "" + id, id, text: "" });
      this.sync();
    },
    saveAnswers: function() {
      EventBus.$emit("saveAnswers", this.list);
    },
    sync: function() {
      this.$socket.client.emit("syncAnswers", {
        to: this.$route.params.id,
        answers: this.list,
        applicationId: this.application.id
      });
    }
  }
};
</script>
<style scoped>
.button {
  margin-top: 35px;
}
.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}
.close {
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
}
input {
  display: inline-block;
  width: 50%;
}
.text {
  margin: 20px;
}
</style>

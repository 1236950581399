<template>
  <div>
    <div class="carousel slide just-slide">
      <ol class="carousel-thumbs" v-if="routeName != 'AppChildSide'">
        <div class="float-right carousel-control">
          <button
            class="btn btn-secondary btn-circle"
            v-if="routeName != 'AppChildSide'"
            @click="
              activeSlide != 0 ? activeSlide-- : '';
              syncSlide();
            "
          >
            <i class="fas fa-angle-left"></i>
          </button>
          <button
            class="btn btn-secondary btn-circle"
            v-if="routeName != 'AppChildSide'"
            @click="
              activeSlide != steps.length - 1 ? activeSlide++ : '';
              syncSlide();
            "
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </div>
        <li
          v-for="(app, index) in steps"
          :key="index"
          :class="{ active: activeSlide == index }"
          @click="
            activeSlide = index;
            syncSlide();
          "
        >
          {{ index + 1 }}.

          <div v-html="app.directive.substr(0, 8)" v-if="app.directive"></div>
          <img
            class="d-block img-fluid"
            v-if="app.img"
            :src="app.img"
            :title="app.directive"
            :alt="app.directive"
          />
          <div class="no-image" :title="app.directive" v-if="!app.img">
            <span class="appindex"> {{ index + 1 }} </span>
          </div>
        </li>

        <div class="clearfix"></div>
      </ol>
      <div>
        <transition
          name="fade"
          enter-active-class="animate__animated animate__fadeIn"
        >
          <template
            v-for="(app, index) in steps"
            :class="{ active: activeSlide == index }"
          >
            <div
              v-if="activeSlide == index"
              :key="index"
              :class="{ 'just-text': !app.img }"
            >
              <div v-html="app.directive"></div>

              <img
                class="d-block img-fluid"
                v-if="app.img"
                :src="app.img"
                :alt="app.directive"
                usemap="#right-answer"
              />
              <map
                name="right-answer"
                v-if="app.coords && application.id != 233 && application.id != 211"
              >
                <area
                  target=""
                  alt=""
                  title=""
                  :coords="app.coords"
                  shape="poly"
                  @click="success"
                />
              </map>
              <map
                name="right-answer"
                v-if="app.coords && application.id == 233 && activeSlide < 2"
              >
                <area
                  target=""
                  alt=""
                  title=""
                  :coords="app.coords"
                  shape="poly"
                  @click="success"
                />
              </map>
              <map
                name="right-answer"
                v-if="app.coords && application.id == 233 && activeSlide == 2"
              >
                <area
                  target=""
                  alt=""
                  title=""
                  :coords="app.coords[0]"
                  shape="poly"
                  @click="success"
                />
                <area
                  target=""
                  alt=""
                  title=""
                  :coords="app.coords[1]"
                  shape="poly"
                  @click="success"
                />
              </map>
                 <map
                name="right-answer"
                v-if="app.coords && application.id == 211 && activeSlide != 5"
              >
                <area
                  target=""
                  alt=""
                  title=""
                  :coords="app.coords"
                  shape="poly"
                  @click="success"
                />
              </map>
              <map
                name="right-answer"
                v-if="app.coords && application.id == 211 && activeSlide == 5"
              >
                <area
                  target=""
                  alt=""
                  title=""
                  :coords="app.coords[0]"
                  shape="poly"
                  @click="success"
                />
                <area
                  target=""
                  alt=""
                  title=""
                  :coords="app.coords[1]"
                  shape="poly"
                  @click="success"
                />
              </map>
            </div>
          </template>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/components/event-bus";
export default {
  name: "Slide",
  props: ["application"],
  data() {
    return {
      routeName: this.$route.name,
      activeSlide: -1,
      steps: null
    };
  },
  mounted() {
    this.steps = JSON.parse(this.application.steps).items;
    EventBus.$on("getAnswers", () => {
      this.saveAnswers();
    });
  },
  beforeDestroy: function() {
    EventBus.$off("getAnswers");
  },
  sockets: {
    setActiveSlide: function(data) {
      if (data.applicationId == this.application.id) {
        this.activeSlide = data.activeSlideIndex;
      }
    }
  },
  methods: {
    syncSlide: function() {
      this.$socket.client.emit("setActiveSlide", {
        to: this.$route.params.id,
        activeSlideIndex: this.activeSlide,
        applicationId: this.application.id
      });
    },
    saveAnswers: function() {
      EventBus.$emit("saveAnswers");
    },
    success: function() {
      EventBus.$emit("showAnim", "TEBRİKLER", "applause");
      if (this.$route.name == "AppChildSide") {
        let animations = {
          text: "TEBRİKLER",
          gif: "applause",
          anim: null,
          sounds: true,
          show: true
        };
        this.$socket.client.emit("showAnim", {
          to: this.$route.params.id,
          animations: animations
        });
      }
    }
  },
  watch: {
    application: {
      deep: true,
      handler() {
        if (this.application.steps) {
          this.activeSlide = -1;
          this.steps = JSON.parse(this.application.steps).items;
        }
      }
    }
  }
};
</script>

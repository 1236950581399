<template>
  <div v-if="application" class="text-center">
    <div class="row">
      <div class="col-2">
        <div class="traffic-lights mt-4">
          <div
            class="circle"
            :class="{ red: focused == 'red' || focused == 'all' }"
          ></div>
          <div
            class="circle"
            :class="{ yellow: focused == 'yellow' || focused == 'all' }"
          ></div>
          <div
            class="circle"
            :class="{ green: focused == 'green' || focused == 'all' }"
          ></div>
        </div>
      </div>
      <div class="col text-left">
        <div class="form-group">
          <label class="red">Sorun Ne?</label>
          <textarea
            type="text"
            class="form-control"
            placeholder="Buraya yazabilirsin."
            v-model="answers.red"
            @focus="focused = 'red'"
            @change="sync()"
          ></textarea>
        </div>
        <div class="form-group">
          <label class="yellow">Çözümlerin Neler?</label>
          <textarea
            type="text"
            class="form-control"
            placeholder="Buraya yazabilirsin."
            v-model="answers.yellow"
            @focus="focused = 'yellow'"
            @change="sync()"
          ></textarea>
        </div>
        <div class="form-group">
          <label class="green">En iyi Çözümü Uygula!</label>
          <textarea
            type="text"
            class="form-control"
            placeholder="Buraya yazabilirsin."
            v-model="answers.green"
            @focus="focused = 'green'"
            @change="sync()"
          ></textarea>
        </div>
        <button
          class="btn btn-secondary btn-round waves-effect waves-light mt-3 pl-4 pr-4"
          @click="sync()"
        >
          Cevapla
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/components/event-bus";
export default {
  name: "TrafficLights",
  props: ["application"],
  data() {
    return {
      focused: null,
      answers: {
        red: null,
        yellow: null,
        green: null
      }
    };
  },
  created() {
    setTimeout(() => {
      this.focused = "red";
    }, 500);
    setTimeout(() => {
      this.focused = "yellow";
    }, 1000);
    setTimeout(() => {
      this.focused = "green";
      setTimeout(() => {
        this.focused = null;
      }, 1400);
    }, 1500);
    setTimeout(() => {
      this.focused = "all";
    }, 2000);
    setTimeout(() => {
      this.focused = null;
    }, 2500);
    setTimeout(() => {
      this.focused = "all";
    }, 3000);
    setTimeout(() => {
      this.focused = null;
    }, 3500);
  },
  mounted() {
    EventBus.$on("getAnswers", () => {
      this.saveAnswers();
    });
  },
  beforeDestroy: function() {
    EventBus.$off("getAnswers");
  },
  sockets: {
    syncAnswers: function(data) {
      if (data.applicationId == this.application.id) {
        this.answers = data.answers;
      }
    }
  },
  methods: {
    saveAnswers: function() {
      EventBus.$emit("saveAnswers", this.answers);
    },
    sync: function() {
      this.$socket.client.emit("syncAnswers", {
        to: this.$route.params.id,
        answers: this.answers,
        applicationId: this.application.id
      });
    }
  }
};
</script>

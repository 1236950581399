<template>
  <div>
    <table class="table table-hover mb-0 usage-list-table">
      <thead>
        <tr>
          <th>Adı Soyadı</th>
          <th>Tarih</th>
          <th>Uygulayıcı</th>
          <th>Durum</th>
          <th>Katılım Linki / Oturum Kodu</th>
          <th>Sobece Pratiği</th>
        </tr>
      </thead>
      <tbody v-if="usages.data">
        <tr v-for="usage in usages.data.rows" :key="usage.id">
          <td>
            <b>{{ usage.child.name }}</b>
          </td>
          <td>
            {{ new Date(usage.createdAt).toLocaleString("tr") }}
          </td>
          <td>
            {{ usage.user.name }}
          </td>
          <td>
            <span
              class="badge"
              :class="
                usage.status == 1 ? 'badge-soft-primary' : 'badge-soft-success'
              "
              >{{
                usage.status == 1 ? "Kullanım Olmamış" : "Kullanım Başarılı"
              }}</span
            >
            <p v-if="usage.status == 1">
              * Başlamamış kullanımlar, kullanım hakkınıza yansımaz.<br />
              * Üretilen linkler 2 saat için geçerlidir.
            </p>
          </td>
          <td>
            <router-link
              type="button"
              class="btn btn-success waves-effect waves-light create-usage mt-3"
              :to="{
                name: 'App',
                params: {
                  id: usage.shortlink
                }
              }"
              v-if="
                diffMinutesNowBetweenStartedAt(
                  usage.startedAt ? usage.startedAt : usage.createdAt
                ) < 120 &&
                  usage.status != 2 &&
                  !disableContinue
              "
            >
              <i class=" fas fa-play "></i>
              Oturuma Devam Et ({{
                usage.remainingMinutes ? usage.remainingMinutes : "50"
              }}.dk'nız kaldı)</router-link
            >
            <p
              class="mt-2"
              v-if="
                diffMinutesNowBetweenStartedAt(
                  usage.startedAt ? usage.startedAt : usage.createdAt
                ) < 120 &&
                  usage.status != 2 &&
                  !disableContinue
              "
            >
              {{ $appDomain }}application/{{ usage.shortlink }}
            </p>
            <p
              class="mt-2"
              v-if="
                diffMinutesNowBetweenStartedAt(
                  usage.startedAt ? usage.startedAt : usage.createdAt
                ) > 120 || usage.status != 1
              "
            >
              {{ usage.shortlink }}
            </p>
          </td>
          <td>
            <button
              class="btn btn-gradient-secondary"
              v-if="usage.practiceIds"
              @click="linkCopy(usage.shortlink)"
            >
              <i class="fas fa-copy"></i> Pratik Linki Kopyala
            </button>
            <router-link
              :to="{
                name: 'PracticeDefine',
                params: { id: usage.shortlink }
              }"
              v-if="usage.status != 1"
              class="btn btn-primary waves-effect waves-light create-practice ml-1"
            >
              <i class=" fa fa-pen "></i>
              {{ usage.practiceIds ? "" : "Pratik Tanımla" }}
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <!--end /table-->
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "usages",
  props: ["usages", "disableContinue"],
  methods: {
    diffMinutesNowBetweenStartedAt: function(startedAt) {
      const now = new Date();
      const startedAtDate = startedAt ? new Date(startedAt) : new Date();
      const diffMinutes = Math.abs(now - startedAtDate) / 60000;
      return diffMinutes;
    },
    linkCopy: function(usageId) {
      navigator.clipboard.writeText(this.$appDomain + "practices/" + usageId);

      Swal.fire({
        icon: "success",
        text: "Link başarıyla kopyalandı.",
        timer: 2500
      });
    }
  }
};
</script>

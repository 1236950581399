<template>
  <div class="col-lg-3">
    <div class="card client-card">
      <div class="card-body text-center">
        <img
          :src="
            client.profileImg
              ? $apiDomain + client.profileImg
              : '/assets/images/users/user-4.jpg'
          "
          alt="user"
          class="rounded-circle thumb-xl"
        />

        <h5 class="client-name">{{ client.name }}</h5>

        <p class="text-center mt-3 rights">
          <span class="badge-rights"> Kullanım Hakkı: {{ usageRights }} </span>
        </p>
        <p class="text-center mt-3 rights">
          <span class="custom-badge " :class="{ corporate: client.isCorporate }"
            >{{ client.isCorporate ? "Kurumsal" : "Bireysel" }} Üye</span
          >
        </p>

        <span class="text-muted mr-3">
          <i class="dripicons-location mr-2 text-info"></i
          >{{ client.cityCode ? client.city.name : "-" }}</span
        >
        <span class="text-muted">
          <i class="dripicons-phone mr-2 text-info"></i
          >{{ client.tel ? client.tel : "-" }}</span
        >
        <p class="text-muted text-center mt-3">
          <span class="text-muted"
            ><i class="dripicons-mail mr-2 text-info"></i
            >{{ client.mail ? client.mail : "-" }}</span
          >
        </p>

        <router-link
          :to="{ name: 'ClientDetail', params: { id: client.id } }"
          type="button"
          class="btn btn-md btn-gradient-secondary"
        >
          Detay
        </router-link>
      </div>
      <!--end card-body-->
    </div>
    <!--end card-->
  </div>
</template>

<script>
import HTTP from "@/main-source";
export default {
  name: "client",
  props: ["client"],
  data() {
    return {
      usageRights: 0
    };
  },
  created() {
    // TODO to be fixed
    HTTP.get("firms/" + this.client.id + "/usagerights")
      .then(usagerights => {
        this.usageRights = usagerights.data.total;
      })
      .catch(() => {
        return 0;
      });
  }
};
</script>

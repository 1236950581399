<template>
  <div v-if="application" class="text-center">
    <div
      class="alert alert-outline-purple b-round"
      role="alert"
      v-if="
        application.steps && $route.name == 'App' && !application.childCanSee
      "
    >
      <i class=" fas fa-info-circle "></i> Aşağıdaki metni sadece siz
      görüyorsunuz.
    </div>
    <div
      v-html="
        application.steps
          ? JSON.parse(application.steps).items[0].directive
          : ''
      "
      v-if="
        ($route.name == 'AppChildSide' && application.childCanSee) ||
          $route.name == 'App'
      "
    ></div>
  </div>
</template>
<script>
import EventBus from "@/components/event-bus";
export default {
  name: "OnlyText",
  props: ["application"],
  mounted() {
    EventBus.$on("getAnswers", () => {
      this.saveAnswers();
    });
  },
  beforeDestroy: function() {
    EventBus.$off("getAnswers");
  },
  methods: {
    saveAnswers: function() {
      EventBus.$emit("saveAnswers");
    }
  }
};
</script>

<template>
  <div class="row status-switch">
    <div class="col statuses">
      <span class="h4">Kişiler</span>
      <draggable class="list-group" :list="person" group="asd" @end="sync">
        <div
          class="list-group-item"
          v-for="(element, index) in person"
          :key="index"
        >
          <input
            type="text"
            v-model="element.name"
            class="form-control"
            placeholder="İsmi?"
            @change="sync"
          />
        </div>
      </draggable>
      <button
        class="btn btn-secondary button float-right"
        @click="
          add('person');
          sync();
        "
      >
        Yeni Kişi Ekle
      </button>
    </div>

    <div class="col do">
      <span class="h4">Görevler</span>
      <draggable class="list-group" :list="todo" group="asd" @end="sync">
        <div
          class="list-group-item"
          v-for="(element, index) in todo"
          :key="index"
        >
          <input
            type="text"
            v-model="element.name"
            class="form-control"
            placeholder="Görev?"
            @change="sync"
          />
        </div>
      </draggable>
      <button
        class="btn btn-secondary button float-right"
        @click="
          add('todo');
          sync();
        "
      >
        Yeni Görev Ekle
      </button>
    </div>
    <div class="col dont">
      <span class="h4">Planla</span>
      <draggable class="list-group" :list="plan" group="asd" @end="sync">
        <div
          class="list-group-item"
          v-for="(element, index) in plan"
          :key="index"
        >
          <input
            type="text"
            v-model="element.name"
            class="form-control"
            placeholder="Plan?"
            @change="sync"
          />
        </div>
      </draggable>
      <button
        class="btn btn-secondary button float-right"
        @click="
          add('plan');
          sync();
        "
      >
        Yeni Plan Ekle
      </button>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import EventBus from "@/components/event-bus";

export default {
  name: "Trello",
  props: ["application"],
  components: {
    draggable
  },
  data() {
    return {
      person: [],
      todo: [],
      plan: []
    };
  },
  mounted() {
    EventBus.$on("getAnswers", () => {
      this.saveAnswers();
    });
  },
  beforeDestroy: function() {
    EventBus.$off("getAnswers");
  },
  sockets: {
    syncAnswers: function(answers) {
      if (answers.applicationId == this.application.id) {
        this.person = answers.answers.person;
        this.todo = answers.answers.todo;
        this.plan = answers.answers.plan;
      }
    }
  },
  methods: {
    add: function(which) {
      switch (which) {
        case "person":
          this.person.push({ name: "" });
          break;
        case "todo":
          this.todo.push({ name: "" });
          break;
        case "plan":
          this.plan.push({ name: "" });
          break;
      }
    },
    replace: function() {
      this.list = [{ name: "Edgard" }];
    },
    clone: function(el) {
      return {
        name: el.name + " cloned"
      };
    },
    log: function(evt) {
      window.console.log(evt);
    },
    saveAnswers: function() {
      EventBus.$emit("saveAnswers", {
        person: this.person,
        todo: this.todo,
        plan: this.plan
      });
    },
    sync: function() {
      this.$socket.client.emit("syncAnswers", {
        to: this.$route.params.id,
        answers: {
          person: this.person,
          todo: this.todo,
          plan: this.plan
        },
        applicationId: this.application.id
      });
    }
  }
};
</script>

<template>
  <div class="row status-switch">
    <div class="col statuses">
      <span class="h4">Planım</span>
      <draggable>
        <div
          class="list-group-item"
          v-for="element in todos"
          :key="element.name"
        >
          <input
            type="text"
            v-model="element.text"
            class="form-control"
            placeholder="Buraya yazabilirsin"
            @change="sync()"
          />
        </div>
      </draggable>
      <button
        class="btn btn-secondary button float-right"
        @click="
          add();
          sync();
        "
      >
        Yeni Plan Ekle
      </button>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import EventBus from "@/components/event-bus";

export default {
  name: "SingleToDoAndOrder",
  props: ["application"],
  components: {
    draggable
  },
  data() {
    return {
      todos: [{ text: "" }]
    };
  },
  mounted() {
    this.add();
    EventBus.$on("getAnswers", () => {
      this.saveAnswers();
    });
  },
  beforeDestroy: function() {
    EventBus.$off("getAnswers");
  },
  sockets: {
    syncAnswers: function(data) {
      if (data.applicationId == this.application.id) {
        this.todos = data.answers;
      }
    }
  },
  methods: {
    add: function() {
      this.todos.push({ text: "" });
    },
    replace: function() {
      this.list = [];
    },
    clone: function(el) {
      return {
        name: el.name + " cloned"
      };
    },
    log: function(evt) {
      window.console.log(evt);
    },
    saveAnswers: function() {
      EventBus.$emit("saveAnswers", this.todos);
    },
    sync: function() {
      this.$socket.client.emit("syncAnswers", {
        to: this.$route.params.id,
        answers: this.todos,
        applicationId: this.application.id
      });
    }
  }
};
</script>

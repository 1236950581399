<template>
  <div>
    <div class="carousel slide just-slide">
      <ol class="carousel-thumbs" v-if="routeName != 'AppChildSide'">
        <div class="float-right carousel-control">
          <button
            class="btn btn-secondary btn-circle"
            v-if="routeName != 'AppChildSide'"
            @click="
              activeSlide != 0 ? activeSlide-- : '';
              syncSlide();
            "
          >
            <i class="fas fa-angle-left"></i>
          </button>
          <button
            class="btn btn-secondary btn-circle"
            v-if="routeName != 'AppChildSide'"
            @click="
              activeSlide != steps.length - 1 ? activeSlide++ : '';
              syncSlide();
            "
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </div>
        <li
          v-for="(app, index) in steps"
          :key="index"
          :class="{ active: activeSlide == index }"
          @click="
            activeSlide = index;
            syncSlide();
          "
        >
          <div class="directive">
            {{ index + 1 }}.

            <div v-html="app.directive.substr(0, 8)" v-if="app.directive"></div>
          </div>
          <img
            class="d-block img-fluid"
            v-if="app.img"
            :src="app.img"
            :title="app.directive"
            :alt="app.directive"
          />
          <div class="no-image" :title="app.directive" v-if="!app.img">
            <span class="appindex"> {{ index + 1 }} </span>
          </div>
        </li>

        <div class="clearfix"></div>
      </ol>
      <div>
        <transition
          name="fade"
          enter-active-class="animate__animated animate__fadeIn"
        >
          <template
            v-for="(app, index) in steps"
            :class="{ active: activeSlide == index }"
          >
            <div v-if="activeSlide == index" :key="index">
              <div
                id="trueFalseCastle"
                class="trueFalseCastle"
                @mousemove="routeName == 'AppChildSide' ? mousemove() : ''"
              >
                <div
                  class="castle-directive animate__animated animate__swing"
                  v-html="app.directive"
                ></div>
                <img
                  class="goal accept animate__animated"
                  :class="{ animate__tada: tadaAccept }"
                  @mouseover="checkAnswer(true)"
                  src="/images/application/goal/accept.jpg"
                  alt="Kabul"
                />
                <img
                  class="goal reject animate__animated"
                  :class="{ animate__tada: tadaReject }"
                  @mouseover="checkAnswer(false)"
                  src="/images/application/goal/reject.jpg"
                  alt="Ret"
                />
                <div
                  class="ball-wrapper"
                  v-if="routeName == 'AppChildSide'"
                  :style="{ left: childBallX + 'px', top: childBallY + 'px' }"
                >
                  <img src="/images/application/goal/ball.png" alt="Top" />
                </div>

                <div
                  class="ball-wrapper exp"
                  v-if="routeName != 'AppChildSide'"
                  :style="{
                    left: childMouse.x - 80 + 'px',
                    top: childMouse.y - 350 + 'px'
                  }"
                >
                  <img src="/images/application/goal/ball.png" alt="Top" />
                </div>
              </div>
              <img
                class="d-block img-fluid"
                v-if="app.img"
                :src="app.img"
                :alt="app.directive"
              />
            </div>
          </template>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/components/event-bus";

export default {
  name: "Slide",
  props: ["application", "childMouse"],
  data() {
    return {
      routeName: this.$route.name,
      activeSlide: -1,
      steps: null,
      childBallX: 200,
      childBallY: 200,
      expBallX: 200,
      expBallY: 200,
      tadaAccept: false,
      tadaReject: false
    };
  },
  mounted() {
    this.steps = JSON.parse(this.application.steps).items;
    EventBus.$on("getAnswers", () => {
      this.saveAnswers();
    });
  },
  beforeDestroy: function() {
    EventBus.$off("getAnswers");
  },
  sockets: {
    setActiveSlide: function(data) {
      if (data.applicationId == this.application.id) {
        this.activeSlide = data.activeSlideIndex;
      }
    }
  },
  methods: {
    syncSlide: function() {
      this.$socket.client.emit("setActiveSlide", {
        to: this.$route.params.id,
        activeSlideIndex: this.activeSlide,
        applicationId: this.application.id
      });
    },
    saveAnswers: function() {
      EventBus.$emit("saveAnswers");
    },
    tada(goal) {
      if (goal == "accept") {
        this.tadaAccept = true;
      } else {
        this.tadaReject = true;
      }
      setTimeout(() => {
        this.tadaAccept = false;
        this.tadaReject = false;
      }, 2000);
    },

    getRelativeCoordinates(event, referenceElement) {
      const position = {
        x: event.pageX,
        y: event.pageY
      };

      const offset = {
        left: referenceElement.offsetLeft,
        top: referenceElement.offsetTop
      };

      let reference = referenceElement.offsetParent;

      while (reference) {
        offset.left += reference.offsetLeft;
        offset.top += reference.offsetTop;
        reference = reference.offsetParent;
      }

      return {
        x: position.x - offset.left,
        y: position.y - offset.top
      };
    },
    mousemove: function() {
      const area = document.getElementById("trueFalseCastle");
      this.childBallX = this.getRelativeCoordinates(event, area).x;
      this.childBallY = this.getRelativeCoordinates(event, area).y;
    },
    checkAnswer: function(answer) {
      let animations = {
        text: "TEBRİKLER",
        gif: "applause",
        anim: null,
        sounds: true,
        show: true
      };
      if (this.$route.name == "AppChildSide") {
        if (this.steps[this.activeSlide].rightAnswer != answer) {
          animations.text = "TEKRAR DENE";
          animations.gif = "try-again";
        }
        this.$socket.client.emit("showAnim", {
          to: this.$route.params.id,
          animations: animations
        });
      }
    }
  },
  watch: {
    application: {
      deep: true,
      handler() {
        if (this.application.steps) {
          this.activeSlide = -1;
          this.steps = JSON.parse(this.application.steps).items;
        }
      }
    }
  }
};
</script>

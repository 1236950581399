<template>
  <!-- Left Sidenav -->
  <div>
    <div class="left-sidenav bigger-side">
      <!-- LOGO -->
      <div class="topbar-left">
        <router-link class="logo" :to="{ name: 'Home' }">
          <span>
            <img
              src="@/assets/images/logo.svg"
              alt="logo-small"
              class="logo-md"
            />
          </span>
          <span>
            <img
              src="@/assets/images/logo.svg"
              alt="logo-large"
              class="logo-lg logo-light"
            />
          </span>
        </router-link>
      </div>
      <!--end logo-->

      <div class="wrapper">
        <div class="row camera-manage">
          <button class="btn mr-3 p-1" @click="hideCamera('expert')">
            <i class="far fa-eye"></i>
            {{ isCamHiding ? "Görüntünü Aç" : "Görüntünü Kapat" }}
          </button>
          <button class="btn " @click="reJoin">
            <i class="fas fa-redo-alt"></i> İletişimi Yenile
          </button>
        </div>
        <div class="live-video">
          <vue-webrtc
            width="100%"
            ref="webrtc"
            :roomId="'sysb_' + roomId"
            socketURL="https://rtcsignal.sobece.com/"
          >
          </vue-webrtc>
        </div>

        <notes></notes>
      </div>
    </div>
    <div class="application-menu">
      <application-menu
        :categories="categories"
        :applications="applications"
        v-if="!xhrCategories"
      ></application-menu>
    </div>
  </div>
  <!-- end left-sidenav-->
</template>

<script>
/* eslint-disable */
import HTTP from "@/main-source";
import menu from "@/components/application/Menu";
import EventBus from "@/components/event-bus";
import notes from "@/components/Notes";
import webrtc from "@/components/webrtc";
import Swal from "sweetalert2";

export default {
  name: "sidebar",
  data() {
    return {
      authUser: JSON.parse(localStorage.getItem("user")),
      role: localStorage.getItem("role"),
      categories: [],
      applications: [],
      xhrCategories: true,
      roomId: this.$route.params.id,
      isClientConnected: false,
      isCamHiding: false
    };
  },
  components: {
    "application-menu": menu,
    notes,
    "vue-webrtc": webrtc
  },
  sockets: {
    childConnected: function() {
      this.isClientConnected = true;
    },
    childDisconnected: function() {
      this.isClientConnected = false;
    }
  },
  created() {
    this.getCategoriesAndApplications();
  },
  mounted() {
    navigator.getUserMedia(
      {
        video: true,
        audio: true
      },

      // success
      () => {
        this.$refs.webrtc.join();
      },

      // error
      err => {
        if (err) {
          Swal.fire({
            icon: "error",
            text:
              "Kameranıza ve/veya mikrofonunuza erişemiyoruz. Lütfen kameranızın çalıştığından, kamera ve mikrofon izinlerini verdiğinizden emin olunuz.",
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false
          });
        }
      }
    );
  },

  beforeDestroy() {
    this.$refs.webrtc.leave();
  },
  methods: {
    getCategoriesAndApplications: function() {
      HTTP.get("categories")
        .then(result => {
          this.categories = result.data;
        })
        .then(() => {
          HTTP.get("applications").then(result => {
            this.applications = result.data;
            this.xhrCategories = false;
            EventBus.$emit("applications", this.applications);
          });
        });
    },
    logEvent(event) {
      console.log(
        "🚀 ~ file: Sidebar.vue ~ line 101 ~ logEvent ~ event",
        event
      );
    },
    leftRoom(event) {
      console.log(
        "🚀 ~ file: Sidebar.vue ~ line 111 ~ leftRoom ~ event",
        event
      );
      this.$refs.webrtc.leave();
    },
    hideCamera: function(who) {
      this.isCamHiding = !this.isCamHiding;
      this.$socket.client.emit("hideCamera", { to: this.roomId, who });
    },
    reJoin: function() {
      this.$socket.client.emit("reJoin", { to: this.roomId });
    }
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body usages-box">
      <b>Kullanımlar</b>
      <div class="icon-info mt-1 mb-4">
        <i class="mdi mdi-bullseye bg-soft-success"></i>
      </div>

      <div class="total text-center">
        <div>
          <h1>
            {{ clientUsageRights ? clientUsageRights.total : 0 }}
          </h1>
          <p v-if="clientUsageRights">adet kullanım hakkı var.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "UsageInfoBox",
  props: ["clientUsageRights"]
};
</script>

<template>
  <div>
    <div class="carousel slide just-slide">
      <ol class="carousel-thumbs" v-if="routeName != 'AppChildSide'">
        <div class="float-right carousel-control">
          <button
            class="btn btn-secondary btn-circle"
            v-if="routeName != 'AppChildSide'"
            @click="
              activeSlide != 0 ? activeSlide-- : '';
              syncSlide();
            "
          >
            <i class="fas fa-angle-left"></i>
          </button>
          <button
            class="btn btn-secondary btn-circle"
            v-if="routeName != 'AppChildSide'"
            @click="
              activeSlide != steps.length - 1 ? activeSlide++ : '';
              syncSlide();
            "
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </div>
        <li
          v-for="(app, index) in steps"
          :key="index"
          :class="{ active: activeSlide == index }"
          @click="
            activeSlide = index;
            syncSlide();
          "
        >
          <div class="directive">
            {{ index + 1 }}.

            <div v-html="app.directive.substr(0, 8)" v-if="app.directive"></div>
          </div>
          <img
            class="d-block img-fluid"
            v-if="app.img"
            :src="app.img"
            :title="app.directive"
            :alt="app.directive"
          />
          <div class="no-image" :title="app.directive" v-if="!app.img">
            <span class="appindex"> {{ index + 1 }} </span>
          </div>
        </li>

        <div class="clearfix"></div>
      </ol>
      <div>
        <transition
          name="fade"
          enter-active-class="animate__animated animate__fadeIn"
        >
          <template
            v-for="(app, index) in steps"
            :class="{ active: activeSlide == index }"
          >
            <div v-if="activeSlide == index" :key="index" class="mt-5">
              <div v-html="app.directive"></div>

              <img
                class="d-block img-fluid"
                v-if="app.img"
                :src="app.img"
                :alt="app.directive"
              />

              <div
                class="d-flex solutions-kit"
                v-if="app.type == 3 || app.type == 4"
              >
                <div class="row w-100">
                  <div class="col-12 text-left">
                    {{ app.type == 3 ? app.topOfTable : "1. Sorun Ne?" }}
                    <div v-if="app.type == 4">
                      <input
                        type="text"
                        class="mt-2 form-control"
                        placeholder="Buraya Yazabilirsin"
                        v-model="answersSolutions[index - 2][0]"
                        @change="sync"
                      />
                    </div>
                  </div>
                  <div class="col m-0 p-0">
                    <div class="card p-2">
                      <h5 class="card-header bg-primary text-white mt-0">
                        2. Çözümler
                      </h5>

                      <input
                        type="text"
                        class="mt-2 form-control"
                        placeholder="Buraya Yazabilirsin"
                        v-for="n in 5"
                        :key="n"
                        v-model="answersSolutions[index - 2][n + 1]"
                        @change="sync"
                      />
                    </div>
                  </div>
                  <div class="col m-0 p-0">
                    <div class="card p-2">
                      <h5 class="card-header bg-secondary text-white mt-0">
                        3. Sonuçlar
                      </h5>
                      <input
                        type="text"
                        class="mt-2 form-control"
                        placeholder="Buraya Yazabilirsin"
                        v-for="n in 5"
                        :key="n"
                        v-model="answersResults[index - 2][n + 1]"
                        @change="sync"
                      />
                    </div>
                  </div>
                  <div class="col m-0 p-0">
                    <div class="card p-2">
                      <h5 class="card-header bg-warning text-white mt-0">
                        4. Değerlendir + / -
                      </h5>
                      <select
                        class="mt-2 form-control"
                        v-for="n in 5"
                        :key="n"
                        v-model="answersEvaluate[index - 2][n + 1]"
                        @change="sync"
                      >
                        <option value="+">+</option>
                        <option value="-">-</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 text-left">
                    <div class="row">
                      <div class="col-4">
                        5. En iyi çözümü seç:
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          class="form-control"
                          v-model="pickupAnswers[index - 2]"
                          placeholder="Buraya yazabilirsin"
                          @change="sync()"
                        />
                      </div>
                    </div>

                    6. Hadi uygula!
                  </div>
                </div>
              </div>
            </div>
          </template>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/components/event-bus";
export default {
  name: "SolutionsKit",
  props: ["application"],
  data() {
    return {
      routeName: this.$route.name,
      activeSlide: -1,
      steps: null,
      answersSolutions: [[], [], [], [], [], []],
      answersResults: [[], [], [], [], [], []],
      answersEvaluate: [[], [], [], [], [], []],
      pickupAnswers: [[], [], [], [], []]
    };
  },
  mounted() {
    this.steps = JSON.parse(this.application.steps).items;
    EventBus.$on("getAnswers", () => {
      this.saveAnswers();
    });
  },
  sockets: {
    setActiveSlide: function(data) {
      if (data.applicationId == this.application.id) {
        this.activeSlide = data.activeSlideIndex;
      }
    },
    syncAnswers: function(data) {
      if (this.application.id == data.applicationId) {
        this.answersSolutions = data.answers.answersSolutions;
        this.answersResults = data.answers.answersResults;
        this.answersEvaluate = data.answers.answersEvaluate;
        this.pickupAnswers = data.answers.pickupAnswers;
      }
    }
  },
  beforeDestroy: function() {
    EventBus.$off("getAnswers");
  },
  methods: {
    syncSlide: function() {
      this.$socket.client.emit("setActiveSlide", {
        to: this.$route.params.id,
        activeSlideIndex: this.activeSlide,
        applicationId: this.application.id
      });
    },
    checkAnswer: function(index) {
      alert(index);
    },
    saveAnswers: function() {
      EventBus.$emit("saveAnswers", {
        answersSolutions: JSON.stringify(this.answers),
        answersResults: JSON.stringify(this.answersResults),
        answersEvaluate: JSON.stringify(this.answersEvaluate),
        pickupAnswers: JSON.stringify(this.pickupAnswers)
      });
    },
    sync: function() {
      this.$socket.client.emit("syncAnswers", {
        to: this.$route.params.id,
        answers: {
          answersSolutions: this.answersSolutions,
          answersResults: this.answersResults,
          answersEvaluate: this.answersEvaluate,
          pickupAnswers: this.pickupAnswers
        },
        applicationId: this.application.id
      });
    }
  },
  watch: {
    application: {
      deep: true,
      handler() {
        if (this.application.steps) {
          this.activeSlide = -1;
          this.steps = JSON.parse(this.application.steps).items;
        }
      }
    }
  }
};
</script>

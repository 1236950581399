<script>
/*eslint-disable */
import { Line } from "vue-chartjs";

export default {
  name: "LineChart",
  extends: Line,
  props: ["graphData"],
  mounted() {
    let datasets = [
      {
        label: "Uygulama",
        backgroundColor: [
          "#992367",
          "#f48333",
          "#2c96a4",
          "#f2828e",
          "#4e849a",
          "#a84d5c",
          "#fd530e",
          "#2c85ac",
          "#3bb99d",
          "#bc1560",
          "#7883a9",
          "#fb43e0",
          "#4e59c7",
          "#5fbef6",
          "#6eb29d"
        ],
        data: this.graphData,
        datalabels: {
          color: "#fff"
        }
      }
    ];

    setTimeout(() => {
      this.renderChart(
        {
          labels: [
            "Ocak",
            "Şubat",
            "Mart",
            "Nisan",
            "Mayıs",
            "Haziran",
            "Temmuz",
            "Ağustos",
            "Eylül",
            "Ekim",
            "Kasım",
            "Aralık"
          ],
          datasets: [
            {
              label: "Kullanım",
              backgroundColor: "#f87979",
              data: this.graphData
            }
          ]
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          }
        }
      );
    }, 300);
  }
};
</script>

<template>
  <div class="page-wrapper">
    <!-- Page Content-->
    <div
      :class="[{ 'page-content-tab': !asComponent }, { 'w-100': asComponent }]"
    >
      <div class="container-fluid">
        <!-- Page-Title -->
        <div class="row">
          <div class="col-sm-12">
            <div class="page-title-box">
                            <h4 class="page-title">Danışanlar</h4>
              <div class="float-right">
                <div class="row">
                  <div class="col-7 input-group">
                    <input
                      type="text"
                      id="example-input1-group2"
                      name="example-input1-group2"
                      v-model="search"
                      class="form-control"
                      placeholder="Ara..."
                    /><span class="input-group-prepend">
                      <button
                        type="button"
                        class="btn btn-gradient-primary"
                        @click="searchChilds"
                      >
                        <i class="fas fa-search"></i>
                      </button>
                    </span>
                  </div>
                  <div class="col text-right">
                    <router-link
                      class="btn btn-primary waves-effect waves-light"
                      :to="{
                        name: 'CreateChild'
                      }"
                    >
                      + Yeni Danışan
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

            <!--end page-title-box-->

            <!-- Body -->

            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <child-list :childs="childsList" :usageRights="usageRights"></child-list>
                    <div class="row">
                      <div class="col">
                        <nav>
                          <ul class="pagination float-right">
                            <li class="page-item" v-if="false">
                              <a
                                class="page-link"
                                href="#"
                                aria-label="Previous"
                              >
                                <span aria-hidden="true">«</span>
                                <span class="sr-only">Previous</span>
                              </a>
                            </li>
                            <li
                              class="page-item"
                              v-for="i in totalPages"
                              :key="i"
                              :class="{ active: currentPage == i }"
                              @click="currentPage = i"
                            >
                              <span class="page-link">{{ i }}</span>
                            </li>
                            <li class="page-item" v-if="false">
                              <a class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">»</span>
                                <span class="sr-only">Next</span>
                              </a>
                            </li>
                          </ul>
                          <!--end pagination-->
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Body End -->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <!-- end page title end breadcrumb -->
      </div>
      <!-- container -->

      <footer-text v-if="!asComponent"></footer-text>
      <!--end footer-->
    </div>
    <!-- end page content -->
  </div>
</template>

<script>
import footer from "@/components/Footer";
import childs from "@/components/childs/childs";
import HTTP from "@/main-source";

export default {
  name: "Childs",
  props: ["asComponent"],
  data() {
    return {
      authFirmId: JSON.parse(localStorage.getItem("user")).firmId,
      childsList: [],
      search: null,
      totalPages: null,
      currentPage: 1,
      usageRights: 0,
    };
  },
  components: {
    "footer-text": footer,
    "child-list": childs
  },
  created() {
    this.getChilds();
  },
  methods: {
    getChilds() {
      let uri = "childs";
      this.xhrProcess = true;

      if (this.currentPage != 1) {
        uri += "?page=" + this.currentPage;
      }
      HTTP.get(uri).then(result => {
        this.childsList = result.data.rows;
        this.totalPages = Math.ceil(result.data.count / 14);
      });
       HTTP.get("firms/" + this.authFirmId + "/usagerights/").then(result => {
        this.usageRights = result.data.total;
      });
    },
    searchChilds: function() {
      let uri = "childs";
      if (this.search) {
        if (this.search.length >= 3) {
          uri += "?q=" + this.search;
          HTTP.get(uri)
            .then(result => {
              this.totalPages = Math.ceil(result.data.count / 14);
              this.childsList = result.data.rows;
              this.xhrProcess = false;
            })
            .catch(e => {
              this.hasError = true;
              console.log(e);
            });
        }
      }
    }
  },
  watch: {
    search: function() {
      this.currentPage = 1;
      if (!this.search) {
        this.getChilds();
      } else {
        this.searchChilds();
      }
    },
    currentPage: function() {
      this.getChilds();
    }
  }
};
</script>

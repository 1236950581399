<template>
  <div
    class="row mt-5 mr-3 ml-3 assessment-form"
    :class="{ 'centered-content': xhrProcess }"
  >
    <div
      class="spinner-border text-success"
      role="status"
      v-if="xhrProcess"
    ></div>

    <div class="col-12 col-sm-8 offset-sm-2 card p-4" v-if="!xhrProcess">
      <img src="@/assets/images/logo.svg" alt="Sobece Logo" class="logo" />
      <span class="h4">SOBECE PRATİĞİ</span>

      <div
        class="row item pl-2 "
        v-for="(practice, index) in practices"
        :key="index"
      >
        <div class="col-12 p-1">
          <b>{{ index + 1 }}. {{ practice.name }} </b>
          <p v-html="practice.practice"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HTTP from "@/main-source";
import Swal from "sweetalert2";

export default {
  name: "PracticeView",
  data() {
    return {
      practices: [],
      xhrProcess: true,
      usageId: this.$route.params.id
    };
  },
  created() {
    this.getPractice();
  },
  methods: {
    getPractice: function() {
      HTTP.get("usages/" + this.usageId + "/practice")
        .then(result => {
          this.practices = result.data;
          this.xhrProcess = false;
          if (this.practices.length == 0) {
            Swal.fire({
              icon: "error",
              text: "Pratik bulunamadı, yönlendiriliyorsunuz.",
              timer: 4000,
              showCancelButton: false,
              showConfirmButton: false
            });
            setTimeout(() => {
              window.location = "https://sobece.com/";
            }, 4000);
          }
        })
        .catch(() => {
          this.xhrProcess = false;
          Swal.fire({
            icon: "error",
            text: "Pratik bulunamadı, yönlendiriliyorsunuz.",
            timer: 4000,
            showCancelButton: false,
            showConfirmButton: false
          });
          setTimeout(() => {
            window.location = "https://sobece.com/";
          }, 4000);
        });
    }
  }
};
</script>

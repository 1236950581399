<template>
  <div>
    <div class="carousel slide just-slide">
      <ol class="carousel-thumbs" v-if="routeName != 'AppChildSide'">
        <div class="float-right carousel-control">
          <button
            class="btn btn-secondary btn-circle"
            v-if="routeName != 'AppChildSide'"
            @click="
              activeSlide != 0 ? activeSlide-- : '';
              syncSlide();
            "
          >
            <i class="fas fa-angle-left"></i>
          </button>
          <button
            class="btn btn-secondary btn-circle"
            v-if="routeName != 'AppChildSide'"
            @click="
              activeSlide != steps.length - 1 ? activeSlide++ : '';
              syncSlide();
            "
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </div>
        <li
          v-for="(app, index) in steps"
          :key="index"
          :class="{ active: activeSlide == index }"
          @click="
            activeSlide = index;
            syncSlide();
          "
        >
          <div class="directive">
            {{ index + 1 }}.

            <div v-html="app.directive.substr(0, 8)" v-if="app.directive"></div>
          </div>
          <img
            class="d-block img-fluid"
            v-if="app.img"
            :src="app.img"
            :title="app.directive"
            :alt="app.directive"
          />
          <div class="no-image" :title="app.directive" v-if="!app.img">
            <span class="appindex"> {{ index + 1 }} </span>
          </div>
        </li>

        <div class="clearfix"></div>
      </ol>
      <div>
        <transition
          name="fade"
          enter-active-class="animate__animated animate__fadeIn"
        >
          <template
            v-for="(app, index) in steps"
            :class="{ active: activeSlide == index }"
          >
            <div
              v-if="activeSlide == index"
              :key="index"
              :class="{ 'just-text': !app.img }"
            >
              <div v-if="application.id == 95" class="text-left wheel">
                <div v-if="$route.name != 'AppChildSide' && activeSlide == 0">
                  <button class="btn btn-primary" @click="turnover">
                    Çevir
                  </button>
                  <button
                    class="btn btn-danger ml-1"
                    @click="isTurning = false"
                  >
                    Durdur
                  </button>
                  <span class="marker"></span>
                </div>
                <div v-if="$route.name != 'App' && activeSlide == 1">
                  <button class="btn btn-primary" @click="turnover">
                    Çevir
                  </button>
                  <button
                    class="btn btn-danger ml-1"
                    @click="isTurning = false"
                  >
                    Durdur
                  </button>
                  <span class="marker"></span>
                </div>
              </div>
              <div v-html="app.directive" v-if="application.id != 95"></div>

              <div v-if="application.id == 95">
                <img
                  class="d-block img-fluid turn_active"
                  :class="{ stopped: !isTurning }"
                  v-if="$route.name != 'AppChildSide' && activeSlide == 0"
                  :src="app.img"
                  :alt="app.directive"
                />

                <img
                  class="d-block img-fluid turn_active"
                  :class="{ stopped: !isTurning }"
                  v-if="$route.name != 'App' && activeSlide == 1"
                  :src="app.img"
                  :alt="app.directive"
                />
              </div>
              <img
                class="d-block img-fluid"
                :class="{ stopped: !isTurning }"
                v-if="app.img && application.id != 95"
                :src="app.img"
                :alt="app.directive"
              />
            </div>
          </template>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/components/event-bus";

export default {
  name: "Slide",
  props: ["application"],
  data() {
    return {
      routeName: this.$route.name,
      activeSlide: -1,
      steps: null,
      isTurning: false
    };
  },
  mounted() {
    this.steps = JSON.parse(this.application.steps).items;
    EventBus.$on("getAnswers", () => {
      this.saveAnswers();
    });
  },
  beforeDestroy: function() {
    EventBus.$off("getAnswers");
  },
  sockets: {
    setActiveSlide: function(data) {
      if (data.applicationId == this.application.id) {
        this.activeSlide = data.activeSlideIndex;
      }
    },
    syncAnswers: function(data) {
      if (data.applicationId == this.application.id) {
        this.isTurning = data.answers;
      }
    }
  },
  methods: {
    syncSlide: function() {
      this.$socket.client.emit("setActiveSlide", {
        to: this.$route.params.id,
        activeSlideIndex: this.activeSlide,
        applicationId: this.application.id
      });
    },
    saveAnswers: function() {
      EventBus.$emit("saveAnswers");
    },
    turnover: function() {
      this.isTurning = true;
      let randomDelay = (Math.random() * 5 + 1).toFixed() * 1000;
      setTimeout(() => {
        this.isTurning = false;
      }, randomDelay);
    }
  },
  watch: {
    application: {
      deep: true,
      handler() {
        if (this.application.steps) {
          this.activeSlide = -1;
          this.steps = JSON.parse(this.application.steps).items;
        }
      }
    },
    isTurning: function() {
      this.$socket.client.emit("syncAnswers", {
        to: this.$route.params.id,
        answers: this.isTurning,
        applicationId: this.application.id
      });
    }
  }
};
</script>

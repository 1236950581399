<template>
  <div class="row status-switch week-calendar p-0">
    <div class="col-2 statuses p-0">
      <span class="h4">Yapılacaklar</span>
      <draggable
        class="list-group"
        :list="todos"
        :group="{ name: 'todos', pull: 'clone', put: false }"
        @end="sync"
      >
        <div
          class="list-group-item"
          v-for="element in todos"
          :key="element.name"
        >
          <div class="task-priority-icon">
            <i class="fas fa-circle text-info"></i>
          </div>
          {{ element.name }}
        </div>
      </draggable>
    </div>

    <div class="col m-0 p-0 do">
      <span class="h4">Pazartesi</span>
      <draggable class="list-group" :list="mon" group="todos" @end="sync">
        <div class="list-group-item" v-for="element in mon" :key="element.name">
          <i class="fas fa-circle text-info"></i>
          {{ element.name }}
        </div>
      </draggable>
    </div>
    <div class="col m-0 p-0 dont">
      <span class="h4">Salı</span>
      <draggable class="list-group" :list="tues" group="todos" @end="sync">
        <div
          class="list-group-item"
          v-for="element in tues"
          :key="element.name"
        >
          <i class="fas fa-circle text-info"></i>
          {{ element.name }}
        </div>
      </draggable>
    </div>
    <div class="col m-0 p-0 wed">
      <span class="h4">Çarşamba</span>
      <draggable class="list-group" :list="wed" group="todos" @end="sync">
        <div class="list-group-item" v-for="element in wed" :key="element.name">
          <i class="fas fa-circle text-info"></i>
          {{ element.name }}
        </div>
      </draggable>
    </div>
    <div class="col m-0 p-0 thurs">
      <span class="h4">Perşembe</span>
      <draggable class="list-group" :list="thurs" group="todos" @end="sync">
        <div
          class="list-group-item"
          v-for="element in thurs"
          :key="element.name"
        >
          <i class="fas fa-circle text-info"></i>
          {{ element.name }}
        </div>
      </draggable>
    </div>
    <div class="col m-0 p-0 fri">
      <span class="h4">Cuma</span>
      <draggable class="list-group" :list="fri" group="todos" @end="sync">
        <div class="list-group-item" v-for="element in fri" :key="element.name">
          <i class="fas fa-circle text-info"></i>
          {{ element.name }}
        </div>
      </draggable>
    </div>
    <div class="col m-0 p-0 sat">
      <span class="h4">Cumartesi</span>
      <draggable class="list-group" :list="sat" group="todos" @end="sync">
        <div class="list-group-item" v-for="element in sat" :key="element.name">
          <i class="fas fa-circle text-info"></i>
          {{ element.name }}
        </div>
      </draggable>
    </div>
    <div class="col m-0 p-0 sun">
      <span class="h4">Pazar</span>
      <draggable class="list-group" :list="sun" group="todos" @end="sync">
        <div class="list-group-item" v-for="element in sun" :key="element.name">
          <i class="fas fa-circle text-info"></i>
          {{ element.name }}
        </div>
      </draggable>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import EventBus from "@/components/event-bus";

export default {
  name: "EventWeekCalendar",
  props: ["application"],
  components: {
    draggable
  },
  data() {
    return {
      todos: [],
      mon: [],
      tues: [],
      wed: [],
      thurs: [],
      fri: [],
      sat: [],
      sun: []
    };
  },
  mounted() {
    this.todos = JSON.parse(this.application.steps).items;
    EventBus.$on("getAnswers", () => {
      this.saveAnswers();
    });
  },
  beforeDestroy: function() {
    EventBus.$off("getAnswers");
  },
  sockets: {
    syncAnswers: function(answers) {
      if (this.application.id == answers.applicationId) {
        this.todos = answers.answers.todos;
        this.mon = answers.answers.mon;
        this.tues = answers.answers.tues;
        this.wed = answers.answers.wed;
        this.thurs = answers.answers.thurs;
        this.fri = answers.answers.fri;
        this.sat = answers.answers.sat;
        this.sun = answers.answers.sun;
      }
    }
  },
  methods: {
    add: function() {
      this.list.push({ name: "Juan" });
    },
    replace: function() {
      //   this.list = [{ name: "Edgard" }];
    },
    clone: function(el) {
      return {
        name: el.name + " cloned"
      };
    },
    log: function(evt) {
      window.console.log(evt);
    },
    saveAnswers: function() {
      EventBus.$emit("saveAnswers", {
        todos: this.todos,
        mon: this.mon,
        tues: this.tues,
        wed: this.wed,
        thurs: this.thurs,
        fri: this.fri,
        sat: this.sat,
        sun: this.sun
      });
    },
    sync: function() {
      this.$socket.client.emit("syncAnswers", {
        to: this.$route.params.id,
        answers: {
          todos: this.todos,
          mon: this.mon,
          tues: this.tues,
          wed: this.wed,
          thurs: this.thurs,
          fri: this.fri,
          sat: this.sat,
          sun: this.sun
        },
        applicationId: this.application.id
      });
    }
  }
};
</script>

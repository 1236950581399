<template>
  <div>
    <div class="carousel slide just-slide">
      <ol class="carousel-thumbs" v-if="routeName != 'AppChildSide'">
        <div class="float-right carousel-control">
          <button
            class="btn btn-secondary btn-circle"
            v-if="routeName != 'AppChildSide'"
            @click="
              activeSlide != 0 ? activeSlide-- : '';
              syncSlide();
            "
          >
            <i class="fas fa-angle-left"></i>
          </button>
          <button
            class="btn btn-secondary btn-circle"
            v-if="routeName != 'AppChildSide'"
            @click="
              activeSlide != steps.length - 1 ? activeSlide++ : '';
              syncSlide();
            "
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </div>
        <li
          v-for="(app, index) in steps"
          :key="index"
          :class="{ active: activeSlide == index }"
          @click="
            activeSlide = index;
            syncSlide();
          "
        >
          <div class="directive">
            {{ index + 1 }}.
            <div v-html="app.directive.substr(0, 8)" v-if="app.directive"></div>
          </div>

          <img
            class="d-block img-fluid"
            v-if="app.img"
            :src="app.img"
            :title="app.directive"
            :alt="app.directive"
          />
          <div class="no-image" :title="app.directive" v-if="!app.img">
            <span class="appindex"> {{ index + 1 }} </span>
          </div>
        </li>

        <div class="clearfix"></div>
      </ol>
      <div>
        <transition
          name="fade"
          enter-active-class="animate__animated animate__fadeIn"
        >
          <template
            v-for="(app, index) in steps"
            :class="{ active: activeSlide == index }"
          >
            <div
              v-if="activeSlide == index"
              :key="index"
              :class="{ 'just-text': !app.img }"
            >
              <div v-html="app.directive"></div>

              <img
                class="d-block img-fluid"
                v-if="app.img"
                :src="app.img"
                :alt="app.directive"
              />

              <input
                v-if="app.img != '/images/application/dyb/yeni-grubum.jpg'"
                class="form-control mt-2 text-center"
                type="text"
                v-model="answers[index]"
                placeholder="Buraya yazabilirsin."
                @change="syncAnswers()"
              />
              <button
                v-if="app.img != '/images/application/dyb/yeni-grubum.jpg'"
                class="btn btn-secondary btn-round waves-effect waves-light mt-3 pl-4 pr-4"
                @click="syncAnswers()"
              >
                Cevapla
              </button>
            </div>
          </template>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/components/event-bus";

export default {
  name: "FillText",
  props: ["application"],
  data() {
    return {
      routeName: this.$route.name,
      activeSlide: -1,
      steps: null,
      answers: []
    };
  },
  mounted() {
    this.steps = JSON.parse(this.application.steps).items;
    EventBus.$on("getAnswers", () => {
      this.saveAnswers();
    });
  },
  beforeDestroy: function() {
    EventBus.$off("getAnswers");
  },
  sockets: {
    setActiveSlide: function(data) {
      if (data.applicationId == this.application.id) {
        this.activeSlide = data.activeSlideIndex;
      }
    },
    // setAnswersChild: function(answers) {
    //   this.answers = answers;
    // }
    syncAnswers: function(answers) {
      if (this.application.id == answers.applicationId) {
        this.answers = answers.answers;
      }
    }
  },
  methods: {
    syncSlide: function() {
      this.$socket.client.emit("setActiveSlide", {
        to: this.$route.params.id,
        activeSlideIndex: this.activeSlide,
        applicationId: this.application.id
      });
    },
    syncAnswers: function() {
      this.$socket.client.emit("syncAnswers", {
        to: this.$route.params.id,
        answers: this.answers,
        applicationId: this.application.id
      });
    },
    setAnswersChild: function() {
      this.$socket.client.emit("setAnswersChild", {
        to: this.$route.params.id,
        answers: this.answers,
        applicationId: this.application.id
      });
    },
    saveAnswers: function() {
      EventBus.$emit("saveAnswers", this.answers);
    }
  },
  watch: {
    application: {
      deep: true,
      handler() {
        if (this.application.steps) {
          this.activeSlide = -1;
          this.steps = JSON.parse(this.application.steps).items;
        }
      }
    }
  }
};
</script>

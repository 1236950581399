<template>
  <div class="draw-area" @mouseup="mouseup">
    <div class="control">
      <span
        class="color"
        style="background:#1a1a1a;"
        @click="setColor('#1a1a1a')"
        :class="{
          editorActive:
            editor.options.stroke == '#1a1a1a' && editor.mode != 'text'
        }"
      ></span>
      <span
        class="color brand-bg-orange"
        @click="setColor('#f48333')"
        :class="{ editorActive: editor.options.stroke == '#f48333' }"
      ></span>
      <span
        class="color brand-bg-purple"
        @click="setColor('#992367')"
        :class="{ editorActive: editor.options.stroke == '#992367' }"
      ></span>

      <span
        class="color"
        style="background:#ff0000;"
        @click="setColor('#ff0000')"
        :class="{ editorActive: editor.options.stroke == '#ff0000' }"
      ></span>
      <span
        class="color"
        style="background:#65c31a;"
        @click="setColor('#65c31a')"
        :class="{ editorActive: editor.options.stroke == '#65c31a' }"
      ></span>
      <span
        class="color"
        style="background:#34b7eb;"
        @click="setColor('#34b7eb')"
        :class="{ editorActive: editor.options.stroke == '#34b7eb' }"
      ></span>
      <span
        class="color"
        style="background:#1a10ad;"
        @click="setColor('#1a10ad')"
        :class="{ editorActive: editor.options.stroke == '#1a10ad' }"
      ></span>
      <span
        class="eraser"
        @click="createText"
        :class="{
          editorActive: editor.mode == 'text' && editor.options.stroke != '#fff'
        }"
      >
        <i class=" fas fa-font "></i>
      </span>
      <span
        class="eraser"
        @click="setColor('#fff')"
        :class="{
          editorActive:
            editor.options.stroke == '#fff' && editor.mode == 'freeDrawing'
        }"
      >
        <i class="fas fa-eraser"></i>
      </span>
      <span class="eraser" @click="undo">
        <i class="fas fa-redo-alt"></i>
      </span>
      <span class="eraser" @click="clear">
        <i class="fas fa-trash-alt"></i>
      </span>
    </div>
    <Editor
      :canvasWidth="canvasWidth"
      :canvasHeight="canvasHeight"
      ref="editor"
      class="editor"
      :editorId="'a'"
    />
  </div>
</template>

<script>
import Editor from "vue-image-markup";
export default {
  name: "draw",
  props: ["application", "bg", "applicationId"],
  data() {
    return {
      canvasWidth: 700,
      canvasHeight: 500,
      editor: {
        mode: "freeDrawing",
        options: { stroke: "#1a1a1a" }
      }
    };
  },
  components: {
    Editor
  },
  mounted() {
    this.$refs.editor.set(this.editor.mode, this.editor.options);

    this.$refs.editor.canvas.getContext("2d").beginPath();

    if (this.application.type == 5) {
      this.setCanvasBg();
    }

    if (this.bg) {
      let imageUrl = this.bg;
      this.$refs.editor.setBackgroundImage(imageUrl);

      console.log("gitti");
      setTimeout(() => {
        this.$socket.client.emit("drawing", {
          to: this.$route.params.id,
          data: this.$refs.editor.canvas.toDataURL(),
          applicationId: this.application.id || this.applicationId
        });
      }, 700);
    }
  },

  methods: {
    getCanvasData: function() {
      // console.log(this.$refs.editor.canvas.toDataURL());
    },
    createText: function() {
      let textModeOptions = {
        fontSize: 15,
        placeholder: "Buraya yazabilirsin",
        fontStyle: "normal"
      };
      this.editor.mode = "text";
      this.editor.options.stroke = "#1a1a1a";
      this.$refs.editor.set("text", textModeOptions);
    },
    setColor: function(hexColor) {
      let customizeFreeDrawing = { stroke: hexColor };
      this.editor.options.stroke = hexColor;
      this.editor.mode = "freeDrawing";
      this.$refs.editor.set("freeDrawing", customizeFreeDrawing);
    },
    undo: function() {
      this.$refs.editor.undo();
    },
    clear: function() {
      this.$refs.editor.clear();
      this.$socket.client.emit("drawing", {
        to: this.$route.params.id,
        data: null,
        applicationId: this.application.id
      });
      this.editor.options.stroke = "#1a1a1a";
      let customizeFreeDrawing = { stroke: "#1a1a1a" };
      this.$refs.editor.set("freeDrawing", customizeFreeDrawing);
      if (this.application.type == 5) {
        this.setCanvasBg();
      }
      if (this.bg) {
        let imageUrl = this.bg;
        this.$refs.editor.setBackgroundImage(imageUrl);

        // const img = new Image();
        // img.src = this.$appDomain + imageUrl;
        //   ctx.drawImage(img, 200, 20, 70, 70);

        setTimeout(() => {
          this.$socket.client.emit("drawing", {
            to: this.$route.params.id,
            data: this.$refs.editor.canvas.toDataURL(),
            applicationId: this.application.id
          });
        }, 500);
      }
    },
    setCanvasBg: function() {
      let imageUrl = JSON.parse(this.application.steps).items[0].img;
      this.$refs.editor.setBackgroundImage(imageUrl);

      // const img = new Image();
      // img.src = this.$appDomain + imageUrl;
      //   ctx.drawImage(img, 200, 20, 70, 70);

      setTimeout(() => {
        this.$socket.client.emit("drawing", {
          to: this.$route.params.id,
          data: this.$refs.editor.canvas.toDataURL(),
          applicationId: this.application.id
        });
      }, 500);
    },
    mouseup: function() {
      setTimeout(() => {
        this.$socket.client.emit("drawing", {
          to: this.$route.params.id,
          data: this.$refs.editor.canvas.toDataURL(),
          applicationId: this.application.id || this.applicationId
        });
      }, 500);
    }
  },
  watch: {
    application: {
      deep: true,
      handler() {
        this.setCanvasBg();
      }
    }
  }
};
</script>

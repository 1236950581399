<template>
  <div class="page-wrapper">
    <!-- Page Content-->

    <div class="page-content-tab" :class="{ 'centered-content': xhrProcess }">
      <div
        class="spinner-border text-success"
        role="status"
        v-if="xhrProcess"
      ></div>
      <div class="container-fluid" v-if="!xhrProcess">
        <!-- Page-Title -->
        <div class="row">
          <div class="col-sm-12">
            <div class="page-title-box">
              <h4 class="page-title">Danışan Detay</h4>
              <div class="text-right float-right">
                <button
                  type="button"
                  class="btn btn-success waves-effect waves-light create-usage"
                  @click="createUsage"
                >
                  <i class="fas fa-play"></i>
                  Oturum Başlat
                </button>
              </div>
            </div>
            <!--end page-title-box-->

            <!-- Body -->

            <div class="row">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-body child-info">
                    <div class="met-profile">
                      <div class="row">
                        <!-- head -->
                        <div class="col-12">
                          <div>
                            <h3 class="mt-0 cml">{{ child.name }}</h3>

                            <span class="d-block m-1 mb-2 mt-2 created-date">
                              <b>Kayıt Tarihi:</b>
                              {{
                                new Date(child.createdAt).toLocaleDateString(
                                  "tr"
                                )
                              }}
                              - <b>Onam Formu:</b>
                              <span
                                class="badge"
                                :class="
                                  child.isConsent
                                    ? 'badge-soft-success'
                                    : 'badge-soft-primary'
                                "
                              >
                                {{
                                  child.consentDateTime
                                    ? new Date(
                                        child.consentDateTime
                                      ).toLocaleDateString("tr") + " tarihinde"
                                    : ""
                                }}
                                {{
                                  child.isConsent
                                    ? "Onaylandı"
                                    : "Henüz Onaylanmadı"
                                }}
                              </span>
                              <button
                                class="btn btn-gradient-primary consent-ok"
                                v-if="!child.isConsent"
                                @click="consentOk()"
                              >
                                <i class="fas fa-check"></i> Ebeveyn ile
                                görüşmeme istinaden onam formu onayını sisteme
                                tanımlıyorum.
                              </button>
                            </span>
                          </div>
                        </div>
                        <!-- head end -->
                        <!-- subhead -->
                        <div class="col-6 pl-3">
                          <div class="row">
                            <div class="col-12">
                              <span class="sex badge">{{
                                child.sex ? "Erkek" : "Kız"
                              }}</span>
                              <span class="badge age">{{
                                childAge(child.birthdate) + " yaşında"
                              }}</span>
                              <span class="badge school">
                                {{
                                  child.school
                                    ? child.school + " okulunda "
                                    : ""
                                }}

                                {{
                                  child.classroom
                                    ? child.classroom + " sınıfında "
                                    : ""
                                }}

                                {{
                                  child.school || child.classroom
                                    ? "okuyor"
                                    : ""
                                }}
                              </span>
                            </div>
                            <div class="col-6 detail">
                              <div>
                                <i class="far fa-calendar-alt"></i>
                                {{
                                  child.birthdate
                                    ? new Date(
                                        child.birthdate
                                      ).toLocaleDateString("tr")
                                    : ""
                                }}
                              </div>
                              <div v-if="false">
                                <i class="fas fa-map-marker-alt"></i>
                              </div>
                            </div>
                            <div class="col-6 detail">
                              <div>
                                <i class="fas fa-phone"></i>
                                {{ child.tel }}
                              </div>
                              <div>
                                <i class="far fa-envelope"></i>
                                {{ child.email }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="row parents">
                            <div class="col-6">
                              <div>
                                <b>Anne: {{ child.momName }} </b>
                              </div>
                              <div>
                                <i class="fas fa-phone"></i>
                                {{ child.momTel }}
                              </div>
                              <div>
                                <i class="far fa-envelope"></i>
                                {{ child.momEmail }}
                              </div>
                            </div>
                            <div class="col-6">
                              <b>Baba: {{ child.dadName }}</b>

                              <div>
                                <i class="fas fa-phone"></i>
                                {{ child.dadTel }}
                              </div>
                              <div>
                                <i class="far fa-envelope"></i>
                                {{ child.dadEmail }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- subhead end -->
                      </div>
                      <!--end row-->
                    </div>
                    <!--end f_profile-->
                  </div>
                  <!--end card-body-->
                  <div class="card-body" v-if="false">
                    <ul
                      class="nav nav-pills mb-0"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          id="general_detail_tab"
                          data-toggle="pill"
                          href="#general_detail"
                          >General</a
                        >
                      </li>
                    </ul>
                  </div>
                  <!--end card-body-->
                </div>

                <!--end card-->
              </div>

              <!--end col-->
            </div>
            <!--end row-->

            <!-- application form -->
            <div class="row">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-body">
                    <ul class="nav nav-tabs" role="tablist">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          data-toggle="tab"
                          href="#form"
                          role="tab"
                          aria-selected="true"
                        >
                          <i class="fab fa-elementor"></i>
                          Bireysel Uygulama Formu</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          href="#report"
                          role="tab"
                          aria-selected="false"
                        >
                          <i class="far fa-file-alt"></i>
                          SOBECE Raporu</a
                        >
                      </li>
                    </ul>
                    <div class="tab-content">
                      <div
                        class="tab-pane p-3 active"
                        id="form"
                        role="tabpanel"
                      >
                        <h5>Bireysel Uygulama Formu</h5>

                        <router-link
                          class="w-100"
                          :to="{
                            name: 'ApplicationForm',
                            params: { childId: childId },
                            query: {
                              scales: JSON.stringify(scaleResults),
                            },
                          }"
                        >
                          <table
                            class="table table-hover mb-0 child-list-table"
                          >
                            <tbody>
                              <tr>
                                <td>
                                  Bireysel Uygulama Formu
                                  <i class="fas fa-angle-right"></i>
                                </td>
                                <td></td>

                                <td class="text-right">
                                  <router-link
                                    class="
                                      btn btn-outline-secondary
                                      waves-effect waves-light
                                    "
                                    :to="{
                                      name: 'ApplicationForm',
                                      params: { childId: childId },
                                      query: {
                                        scales: JSON.stringify(scaleResults),
                                      },
                                    }"
                                  >
                                    Raporu Görüntüle
                                    <i class="far fa-chart-bar"></i>
                                  </router-link>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </router-link>
                      </div>
                      <div class="tab-pane p-3" id="report" role="tabpanel">
                        <h5>SOBECE Beceri Çocuk Eğitimi Raporu</h5>
                        <div class="form-group">
                          <label> Program İçerik: </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Program İçerik"
                            autocomplete="nope"
                            v-model="child.sobeceContent"
                          />
                        </div>
                        <div class="form-group">
                          <label> Gelişim Gösterdiği Beceriler: </label>
                          <textarea
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            placeholder="Gelişim Gösterdiği Beceriler"
                            v-model="child.improvedCategories"
                          ></textarea>
                        </div>
                        <div class="form-group">
                          <label> Sobece Pratiklere Uyum: </label>
                          <textarea
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            placeholder="Sobece Pratiklere Uyum:"
                            v-model="child.practiceHarmony"
                          ></textarea>
                        </div>
                        <div class="form-group">
                          <label> Geliştirilmesi Hedeflenen Beceriler: </label>
                          <textarea
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            placeholder="Geliştirilmesi Hedeflenen Beceriler:"
                            v-model="child.skillsAimedDevelop"
                          ></textarea>
                        </div>
                        <div class="form-group">
                          <label> Aileye Öneriler: </label>
                          <textarea
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            placeholder="Aileye Öneriler:"
                            v-model="child.recommendationsForFamily"
                          ></textarea>
                        </div>
                        <div class="row">
                          <div class="col-12 text-right">
                            <router-link
                              role="button"
                              class="
                                btn btn-outline-secondary
                                mt-3
                                pr-3
                                pl-3
                                mr-3
                              "
                              :to="{
                                name: 'SobeceReport',
                                params: { childId: childId },
                                query: {
                                  scales: JSON.stringify(scaleResults),
                                },
                              }"
                            >
                              <i class="fas fa-angle-right"></i>
                              Raporu Görüntüle
                            </router-link>
                            <button
                              class="
                                btn btn-success
                                waves-effect waves-light
                                create-usage
                                mt-3
                                pr-5
                                pl-5
                              "
                              @click="updateReport"
                            >
                              <i class="fas fa-save"></i>
                              Raporu Kaydet
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- application form end -->

            <!-- assessment form -->
            <div class="row">
              <div class="col-sm-12">
                <transition
                  name="fade"
                  enter-active-class="animate__animated animate__fadeIn"
                >
                  <div class="card assessmentforms" v-if="!showGraph" :key="1">
                    <div class="card-body">
                      <h5>Değerlendirme Formları</h5>
                      <div class="row">
                        <div class="col-6">
                          <h6 class="brand-color-orange">Ön Test</h6>
                          <table
                            class="table table-hover mb-0 child-list-table"
                          >
                            <tbody>
                              <tr>
                                <td>Ebeveyn</td>
                                <td>
                                  <b
                                    class="brand-color-orange"
                                    v-if="
                                      getAssessmentAnswer('parent', 'first')
                                        .answers == null
                                    "
                                  >
                                    Henüz Doldurulmadı
                                  </b>
                                  {{
                                    getAssessmentAnswer("parent", "first")
                                      .answers != null
                                      ? new Date(
                                          getAssessmentAnswer(
                                            "parent",
                                            "first"
                                          ).updatedAt
                                        ).toLocaleDateString("tr")
                                      : ""
                                  }}
                                </td>
                                <td class="text-right">
                                  <button
                                    class="btn btn-gradient-secondary mr-2"
                                    v-if="
                                      getAssessmentAnswer('parent', 'first')
                                        .answers != null
                                    "
                                    @click="
                                      seeAnswers(
                                        getAssessmentAnswer('parent', 'first')
                                          .shortlink
                                      )
                                    "
                                  >
                                    <i class="far fa-eye"></i> Cevapları
                                    Görüntüle
                                  </button>
                                  <button
                                    class="btn btn-gradient-primary"
                                    v-if="
                                      getAssessmentAnswer('parent', 'first')
                                        .answers == null
                                    "
                                    @click="
                                      linkCopy(
                                        getAssessmentAnswer('parent', 'first')
                                          .shortlink
                                      )
                                    "
                                  >
                                    <i class="fas fa-copy"></i> Link Kopyala
                                  </button>
                                  <!-- Go Graph -->
                                  <button
                                    class="
                                      btn btn-outline-secondary
                                      waves-effect waves-light
                                    "
                                    @click="
                                      viewChart(
                                        'single',
                                        getAssessmentAnswer('parent', 'first')
                                          .shortlink
                                      )
                                    "
                                    v-if="
                                      getAssessmentAnswer('parent', 'first')
                                        .answers != null
                                    "
                                  >
                                    Grafik Görüntüle
                                    <i class="far fa-chart-bar"></i>
                                  </button>
                                  <!-- Go Graph -->
                                </td>
                              </tr>
                              <tr>
                                <td>Uygulayıcı</td>
                                <td>
                                  <b
                                    class="brand-color-orange"
                                    v-if="
                                      getAssessmentAnswer('user', 'first')
                                        .answers == null
                                    "
                                  >
                                    Henüz Doldurulmadı
                                  </b>
                                  {{
                                    getAssessmentAnswer("user", "first")
                                      .answers != null
                                      ? new Date(
                                          getAssessmentAnswer(
                                            "user",
                                            "first"
                                          ).updatedAt
                                        ).toLocaleDateString("tr")
                                      : ""
                                  }}
                                </td>
                                <td class="text-right">
                                  <button
                                    class="btn btn-gradient-secondary mr-2"
                                    v-if="
                                      getAssessmentAnswer('user', 'first')
                                        .answers != null
                                    "
                                    @click="
                                      seeAnswers(
                                        getAssessmentAnswer('user', 'first')
                                          .shortlink
                                      )
                                    "
                                  >
                                    <i class="far fa-eye"></i> Cevapları
                                    Görüntüle
                                  </button>
                                  <button
                                    class="btn btn-gradient-primary"
                                    v-if="
                                      getAssessmentAnswer('user', 'first')
                                        .answers == null
                                    "
                                    @click="
                                      linkCopy(
                                        getAssessmentAnswer('user', 'first')
                                          .shortlink
                                      )
                                    "
                                  >
                                    <i class="fas fa-copy"></i> Link Kopyala
                                  </button>
                                  <!-- Go Graph -->
                                  <button
                                    class="
                                      btn btn-outline-secondary
                                      waves-effect waves-light
                                    "
                                    @click="
                                      viewChart(
                                        'single',
                                        getAssessmentAnswer('user', 'first')
                                          .shortlink
                                      )
                                    "
                                    v-if="
                                      getAssessmentAnswer('user', 'first')
                                        .answers != null
                                    "
                                  >
                                    Grafik Görüntüle
                                    <i class="far fa-chart-bar"></i>
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>Öğretmen</td>
                                <td>
                                  <b
                                    class="brand-color-orange"
                                    v-if="
                                      getAssessmentAnswer('teacher', 'first')
                                        .answers == null
                                    "
                                  >
                                    Henüz Doldurulmadı
                                  </b>
                                  {{
                                    getAssessmentAnswer("teacher", "first")
                                      .answers != null
                                      ? new Date(
                                          getAssessmentAnswer(
                                            "teacher",
                                            "first"
                                          ).updatedAt
                                        ).toLocaleDateString("tr")
                                      : ""
                                  }}
                                </td>
                                <td class="text-right">
                                  <button
                                    class="btn btn-gradient-secondary mr-2"
                                    v-if="
                                      getAssessmentAnswer('teacher', 'first')
                                        .answers != null
                                    "
                                    @click="
                                      seeAnswers(
                                        getAssessmentAnswer('teacher', 'first')
                                          .shortlink
                                      )
                                    "
                                  >
                                    <i class="far fa-eye"></i> Cevapları
                                    Görüntüle
                                  </button>
                                  <button
                                    class="btn btn-gradient-primary"
                                    v-if="
                                      getAssessmentAnswer('teacher', 'first')
                                        .answers == null
                                    "
                                    @click="
                                      linkCopy(
                                        getAssessmentAnswer('teacher', 'first')
                                          .shortlink
                                      )
                                    "
                                  >
                                    <i class="fas fa-copy"></i> Link Kopyala
                                  </button>
                                  <!-- Go Graph -->
                                  <button
                                    class="
                                      btn btn-outline-secondary
                                      waves-effect waves-light
                                    "
                                    @click="
                                      viewChart(
                                        'single',
                                        getAssessmentAnswer('teacher', 'first')
                                          .shortlink
                                      )
                                    "
                                    v-if="
                                      getAssessmentAnswer('teacher', 'first')
                                        .answers != null
                                    "
                                  >
                                    Grafik Görüntüle
                                    <i class="far fa-chart-bar"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <button
                            type="button"
                            class="btn btn-info btn-block brand-bg-orange"
                            @click="viewChart('first')"
                            :disabled="filledScaleCount('first') <= 1"
                          >
                            <i class="far fa-chart-bar"></i> Ön Test Grafiği
                          </button>
                        </div>
                        <div class="col-6">
                          <h6 class="brand-color-purple">Son Test</h6>
                          <table
                            class="table table-hover mb-0 child-list-table"
                          >
                            <tbody>
                              <tr>
                                <td>Ebeveyn</td>
                                <td>
                                  <b
                                    class="brand-color-orange"
                                    v-if="
                                      getAssessmentAnswer('parent', 'last')
                                        .answers == null
                                    "
                                  >
                                    Henüz Doldurulmadı
                                  </b>
                                  {{
                                    getAssessmentAnswer("parent", "last")
                                      .answers != null
                                      ? new Date(
                                          getAssessmentAnswer(
                                            "parent",
                                            "last"
                                          ).updatedAt
                                        ).toLocaleDateString("tr")
                                      : ""
                                  }}
                                </td>
                                <td class="text-right">
                                  <button
                                    class="btn btn-gradient-secondary mr-2"
                                    v-if="
                                      getAssessmentAnswer('parent', 'last')
                                        .answers != null
                                    "
                                    @click="
                                      seeAnswers(
                                        getAssessmentAnswer('parent', 'last')
                                          .shortlink
                                      )
                                    "
                                  >
                                    <i class="far fa-eye"></i> Cevapları
                                    Görüntüle
                                  </button>
                                  <button
                                    class="btn btn-gradient-primary"
                                    v-if="
                                      getAssessmentAnswer('parent', 'last')
                                        .answers == null
                                    "
                                    @click="
                                      linkCopy(
                                        getAssessmentAnswer('parent', 'last')
                                          .shortlink
                                      )
                                    "
                                  >
                                    <i class="fas fa-copy"></i> Link Kopyala
                                  </button>
                                  <!-- Go Graph -->
                                  <button
                                    class="
                                      btn btn-outline-secondary
                                      waves-effect waves-light
                                    "
                                    @click="
                                      viewChart(
                                        'single',
                                        getAssessmentAnswer('parent', 'last')
                                          .shortlink
                                      )
                                    "
                                    v-if="
                                      getAssessmentAnswer('parent', 'last')
                                        .answers != null
                                    "
                                  >
                                    Grafik Görüntüle
                                    <i class="far fa-chart-bar"></i>
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>Uygulayıcı</td>
                                <td>
                                  <b
                                    class="brand-color-orange"
                                    v-if="
                                      getAssessmentAnswer('user', 'last')
                                        .answers == null
                                    "
                                  >
                                    Henüz Doldurulmadı
                                  </b>
                                  {{
                                    getAssessmentAnswer("user", "last")
                                      .answers != null
                                      ? new Date(
                                          getAssessmentAnswer(
                                            "user",
                                            "last"
                                          ).updatedAt
                                        ).toLocaleDateString("tr")
                                      : ""
                                  }}
                                </td>
                                <td class="text-right">
                                  <button
                                    class="btn btn-gradient-secondary mr-2"
                                    v-if="
                                      getAssessmentAnswer('user', 'last')
                                        .answers != null
                                    "
                                    @click="
                                      seeAnswers(
                                        getAssessmentAnswer('user', 'last')
                                          .shortlink
                                      )
                                    "
                                  >
                                    <i class="far fa-eye"></i> Cevapları
                                    Görüntüle
                                  </button>
                                  <button
                                    class="btn btn-gradient-primary"
                                    v-if="
                                      getAssessmentAnswer('user', 'last')
                                        .answers == null
                                    "
                                    @click="
                                      linkCopy(
                                        getAssessmentAnswer('user', 'last')
                                          .shortlink
                                      )
                                    "
                                  >
                                    <i class="fas fa-copy"></i> Link Kopyala
                                  </button>
                                  <!-- Go Graph -->
                                  <button
                                    class="
                                      btn btn-outline-secondary
                                      waves-effect waves-light
                                    "
                                    @click="
                                      viewChart(
                                        'single',
                                        getAssessmentAnswer('user', 'last')
                                          .shortlink
                                      )
                                    "
                                    v-if="
                                      getAssessmentAnswer('user', 'last')
                                        .answers != null
                                    "
                                  >
                                    Grafik Görüntüle
                                    <i class="far fa-chart-bar"></i>
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>Öğretmen</td>
                                <td>
                                  <b
                                    class="brand-color-orange"
                                    v-if="
                                      getAssessmentAnswer('teacher', 'last')
                                        .answers == null
                                    "
                                  >
                                    Henüz Doldurulmadı
                                  </b>
                                  {{
                                    getAssessmentAnswer("teacher", "last")
                                      .answers != null
                                      ? new Date(
                                          getAssessmentAnswer(
                                            "teacher",
                                            "last"
                                          ).updatedAt
                                        ).toLocaleDateString("tr")
                                      : ""
                                  }}
                                </td>
                                <td class="text-right">
                                  <button
                                    class="btn btn-gradient-secondary mr-2"
                                    v-if="
                                      getAssessmentAnswer('teacher', 'last')
                                        .answers != null
                                    "
                                    @click="
                                      seeAnswers(
                                        getAssessmentAnswer('teacher', 'last')
                                          .shortlink
                                      )
                                    "
                                  >
                                    <i class="far fa-eye"></i> Cevapları Görüntüle
                                  </button>
                                  <button
                                    class="btn btn-gradient-primary"
                                    v-if="
                                      getAssessmentAnswer('teacher', 'last')
                                        .answers == null
                                    "
                                    @click="
                                      linkCopy(
                                        getAssessmentAnswer('teacher', 'last')
                                          .shortlink
                                      )
                                    "
                                  >
                                    <i class="fas fa-copy"></i> Link Kopyala
                                  </button>
                                  <!-- Go Graph -->
                                  <button
                                    class="
                                      btn btn-outline-secondary
                                      waves-effect waves-light
                                    "
                                    @click="
                                      viewChart(
                                        'single',
                                        getAssessmentAnswer('teacher', 'last')
                                          .shortlink
                                      )
                                    "
                                    v-if="
                                      getAssessmentAnswer('teacher', 'last')
                                        .answers != null
                                    "
                                  >
                                    Grafik Görüntüle
                                    <i class="far fa-chart-bar"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <button
                            type="button"
                            class="btn btn-info btn-block brand-bg-purple"
                            @click="viewChart('last')"
                            :disabled="filledScaleCount('last') <= 1"
                          >
                            <i class="far fa-chart-bar"></i> Son Test Grafiği
                          </button>
                        </div>
                      </div>
                      <div class="text-center over-button">
                        <button
                          type="button"
                          class="
                            btn btn-gradient-purple
                            waves-effect waves-light
                            pr-5
                            pl-5
                          "
                          @click="viewChart('comparative')"
                          :disabled="
                            filledScaleCount('first') == 0 ||
                            filledScaleCount('last') == 0
                          "
                        >
                          <i class="fas fa-chart-line"></i>
                          ÖN TEST VE SON TEST KARŞILAŞTIR
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- Graph Start -->
                  <div class="card assessment-graph" v-if="showGraph" :key="2">
                    <div class="card-body">
                      <button
                        class="
                          btn btn-outline-danger
                          waves-effect waves-light
                          float-right
                        "
                        @click="showGraph = false"
                      >
                        Grafik Görünümü Kapat
                      </button>
                      <button
                        class="
                          btn btn-outline-primary
                          waves-effect waves-light
                          float-right
                          mr-3
                        "
                        @click="generateReport"
                      >
                        <div
                          class="spinner-border spinner-border-sm"
                          role="status"
                          v-if="pdfProcess"
                        ></div>
                        Grafiği İndir
                      </button>
                      <div class="row">
                        <div class="col-12 mb-3">
                          <span v-if="false">
                            {{ activeChart }}
                          </span>
                          <span class="h4">{{ getChartTitle() }}</span>
                        </div>
                        <div class="col-12 col-sm-8">
                          <BarChart
                            :height="254"
                            :graphData="graphData"
                          ></BarChart>

                          <!-- PDF -->
                          <vue-html2pdf
                            :enable-download="true"
                            :paginate-elements-by-height="2100"
                            :manual-pagination="false"
                            :html-to-pdf-options="{
                              margin: [0, 5, 10, 5],
                              filename: child
                                ? child.name + ' Grafik'
                                : 'Grafik',
                            }"
                            pdf-format="a4"
                            pdf-orientation="portrait"
                            ref="html2Pdf"
                            @hasDownloaded="pdfProcess = false"
                          >
                            <section slot="pdf-content">
                              <div class="card p-1 graph-wrapper m-0 p-0">
                                <div class="col-12">
                                  <span class="h4 d-block mb-1">
                                    {{ child.name }} -
                                    {{ getChartTitle() }}</span
                                  >
                                  <br />
                                  <BarChart
                                    :height="294"
                                    :graphData="graphData"
                                    :anim="false"
                                  ></BarChart>
                                  <br /><hr>
                                </div>
                              </div>
                            </section>
                          </vue-html2pdf>
                          <!-- PDF -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Graph End -->
                </transition>
              </div>
            </div>
            <!-- assessment form end -->

            <!-- Body End -->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <!-- end page title end breadcrumb -->
      </div>
      <!-- container -->

      <footer-text></footer-text>
      <!--end footer-->
    </div>
    <!-- end page content -->
  </div>
</template>

<script>
import footer from "@/components/Footer";
import HTTP from "@/main-source";
import Swal from "sweetalert2";
import router from "@/router";
import BarChart from "@/components/charts/bar";
import VueHtml2pdf from "vue-html2pdf";
// import "quill/dist/quill.core.css";
// import "quill/dist/quill.snow.css";

// import { quillEditor } from "vue-quill-editor";

export default {
  name: "ChildDetail",
  data() {
    return {
      childId: this.$route.params.id,
      authFirmId: JSON.parse(localStorage.getItem("user")).firmId,
      child: [],
      xhrProcess: true,
      showGraph: false,
      graphData: {
        data: null,
      },
      categories: null,
      activeChart: null,
      usageRights: 0,
      pdfProcess: false,
      // content: null,
      // editorOption: {
      //   theme: "snow"
      // }
    };
  },
  components: {
    "footer-text": footer,
    BarChart,
    VueHtml2pdf,
    // "quill-editor": quillEditor
  },
  created() {
    HTTP.get("/categories")
      .then((result) => {
        this.categories = result.data;
      })
      .then(() => {
        this.getChild();
      });
    HTTP.get("firms/" + this.authFirmId + "/usagerights/").then((result) => {
      this.usageRights = result.data.total;
    });
  },
  methods: {
    seeAnswers(shortlink) {
      router.push({
        name: "SobeceAnswers",
        params: {
          shortlink: shortlink,
        },
      });
    },
    getChild() {
      HTTP.get("childs/" + this.childId)
        .then((result) => {
          this.child = result.data.rows[0];
          this.xhrProcess = false;
          // Calculate Chart Data And Save for Reports
          this.setScaleResults();
        })
        .catch((e) => {
          console.log(e);
          Swal.fire({ icon: "error", text: "Danışan detayı getirilemedi" });
        });
    },
    childAge(birthdate) {
      return this.$childAge(birthdate);
    },
    createUsage() {
      if (this.usageRights > 0) {
        Swal.fire({
          title: "Oturum Başlatılıyor",
          html: "Online Sobece Uygulamasına yönlendirliyorsunuz. Danışanınız katıldığında oturum başlayacak ve kullanım hakkınıza yansıyacak.",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `Oturum Başlat`,
          denyButtonText: `İptal`,
        }).then((result) => {
          if (result.isConfirmed) {
            let data = {
              childId: this.childId,
            };
            HTTP.post("usages", data).then((result) => {
              if (result.status == 200) {
                let usage = result.data;

                router.push({
                  name: "App",
                  params: { id: usage.shortlink },
                });
              }
            });
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Kullanım hakkınız bulunmuyor",
          confirmButtonText: `Tamam`,
        });
      }
    },
    linkCopy: function (shortlink) {
      navigator.clipboard.writeText(
        this.$appDomain + "assessment/" + shortlink
      );

      Swal.fire({
        icon: "success",
        text: "Link başarıyla kopyalandı.",
        timer: 2500,
      });
    },
    getAssessmentAnswer: function (who, which) {
      let type = 1,
        filledBy = null;
      if (which == "last") {
        type = 2;
      }
      switch (who) {
        case "user":
          filledBy = 1;
          break;
        case "teacher":
          filledBy = 2;
          break;

        default:
          filledBy = 3;
          break;
      }
      return this.child.AssessmentScaleAnswers.filter(
        (assessmentAnswer) =>
          assessmentAnswer.filledBy == filledBy && assessmentAnswer.type == type
      )[0];
    },
    combineAnswers: function (selectedAssessmentAnswers) {
      if (selectedAssessmentAnswers) {
        return []
          .concat(
            selectedAssessmentAnswers[0].answers
              ? typeof selectedAssessmentAnswers[0].answers == "string"
                ? JSON.parse(selectedAssessmentAnswers[0].answers)
                : selectedAssessmentAnswers[0].answers
              : []
          )
          .concat(
            selectedAssessmentAnswers[1].answers
              ? typeof selectedAssessmentAnswers[1].answers == "string"
                ? JSON.parse(selectedAssessmentAnswers[1].answers)
                : selectedAssessmentAnswers[1].answers
              : []
          )
          .concat(
            selectedAssessmentAnswers[2].answers
              ? typeof selectedAssessmentAnswers[2].answers == "string"
                ? JSON.parse(selectedAssessmentAnswers[2].answers)
                : selectedAssessmentAnswers[2].answers
              : []
          );
      } else {
        return [];
      }
    },
    answersTotal: function (selectedAssessmentAnswers) {
      let totalReplyCountWithoutZero;
      let answersTotal = [];
      this.categories
        .filter((cat) => cat.parentId == null)
        .forEach((mainCat) => {
          totalReplyCountWithoutZero = selectedAssessmentAnswers.filter(
            (answer) =>
              mainCat.id == answer.categoryId && parseInt(answer.answer) != 0
          ).length;

          let total = selectedAssessmentAnswers
            .filter((answer) => mainCat.id == answer.categoryId)
            .reduce((a, b) => {
              return a + parseInt(b.answer);
            }, 0);

          let ratioForPercent = (
            ((total / totalReplyCountWithoutZero).toFixed(2) * 100) /
            4
          ).toFixed(2);
          answersTotal.push(ratioForPercent);
        });
      // Grand Total
      let grandTotal = answersTotal.reduce((a, b) => {
        return parseInt(a) + parseInt(b);
      });

      answersTotal.push((grandTotal / 7).toFixed(2));
      return answersTotal;
    },
    viewChart: function (type, answerShortLink, returnValue = false) {
      /* eslint-disable */
      this.graphData = { type, data: null, isSingleTypeFirst: true };
      this.activeChart = { type, answerShortLink, data: null };

      // types: single,first,last,mixed
      let selectedAssessmentAnswers;

      if (type == "single") {
        selectedAssessmentAnswers = this.child.AssessmentScaleAnswers.filter(
          (assessmentAnswer) => assessmentAnswer.shortlink == answerShortLink
        )[0];
        if (selectedAssessmentAnswers.type == 2) {
          this.graphData.isSingleTypeFirst = false;
        }
      }

      if (type == "first" || type == "last") {
        let atype = 1;
        if (type == "last") {
          atype = 2;
          this.graphData.isSingleTypeFirst = false;
        }
        selectedAssessmentAnswers = this.child.AssessmentScaleAnswers.filter(
          (assessmentAnswer) => assessmentAnswer.type == atype
        );

        selectedAssessmentAnswers = {
          answers: this.combineAnswers(selectedAssessmentAnswers),
        };
      }

      if (type == "comparative") {
        let firstTest = this.combineAnswers(
          this.child.AssessmentScaleAnswers.filter(
            (assessmentAnswer) => assessmentAnswer.type == 1
          )
        );

        let lastTest = this.combineAnswers(
          this.child.AssessmentScaleAnswers.filter(
            (assessmentAnswer) => assessmentAnswer.type == 2
          )
        );

        this.graphData.data = this.answersTotal(firstTest);
        this.graphData.last = this.answersTotal(lastTest);

        this.showGraph = true;
      } else {
        if (typeof selectedAssessmentAnswers.answers == "string") {
          selectedAssessmentAnswers.answers = JSON.parse(
            selectedAssessmentAnswers.answers
          );
        }

        // set active chart data and after set answers start
        this.activeChart.data = selectedAssessmentAnswers;
        selectedAssessmentAnswers = selectedAssessmentAnswers.answers;
        //# set active chart data and after set answers

        this.graphData.data = this.answersTotal(selectedAssessmentAnswers);

        if (returnValue) {
          return this.graphData;
        } else {
          this.showGraph = true;
        }
      }
    },
    getChartTitle: function () {
      let head = "";
      switch (this.activeChart.type) {
        case "single":
          // Single
          switch (this.activeChart.data.filledBy) {
            case 1:
              head = "Uygulayıcı";
              break;
            case 2:
              head = "Öğretmen";
              break;
            case 3:
              head = "Ebeveyn";
              break;
          }
          if (this.activeChart.data.type == 1) {
            head += " Ön Test ";
          } else {
            head += " Son Test ";
          }
          // Single
          break;

        case "first":
          head = "Ön Test ";
          break;

        case "last":
          head = "Son Test ";
          break;
        case "comparative":
          head = "Ön Test ve Son Test Karşılaştırmalı ";
          break;
      }
      head += "Değerlendirme Formu Grafiği";
      return head;
    },
    filledScaleCount: function (which) {
      let type = 1;
      if (which == "last") {
        type = 2;
      }
      return this.child.AssessmentScaleAnswers.filter(
        (assessmentAnswer) =>
          assessmentAnswer.type == type && assessmentAnswer.answers != null
      ).length;
    },
    setScaleResults: function () {
      let scaleResults = {
        parentFirst: 0,
        teacherFirst: 0,
        userFirst: 0,
        parentLast: 0,
        teacherLast: 0,
        userLast: 0,
        firstsSum: 0,
        lastsSum: 0,
      };

      if (this.getAssessmentAnswer("parent", "first").answers != null) {
        scaleResults.parentFirst = this.viewChart(
          "single",
          this.getAssessmentAnswer("parent", "first").shortlink,
          true
        ).data[7];
      }
      if (this.getAssessmentAnswer("parent", "last").answers != null) {
        scaleResults.parentLast = this.viewChart(
          "single",
          this.getAssessmentAnswer("parent", "last").shortlink,
          true
        ).data[7];
      }
      if (this.getAssessmentAnswer("teacher", "first").answers != null) {
        scaleResults.teacherFirst = this.viewChart(
          "single",
          this.getAssessmentAnswer("teacher", "first").shortlink,
          true
        ).data[7];
      }
      if (this.getAssessmentAnswer("teacher", "last").answers != null) {
        scaleResults.teacherLast = this.viewChart(
          "single",
          this.getAssessmentAnswer("teacher", "last").shortlink,
          true
        ).data[7];
      }
      if (this.getAssessmentAnswer("user", "first").answers != null) {
        scaleResults.userFirst = this.viewChart(
          "single",
          this.getAssessmentAnswer("user", "first").shortlink,
          true
        ).data[7];
      }
      if (this.getAssessmentAnswer("user", "last").answers != null) {
        scaleResults.userLast = this.viewChart(
          "single",
          this.getAssessmentAnswer("user", "last").shortlink,
          true
        ).data[7];
      }
      if (this.filledScaleCount("first") >= 1) {
        scaleResults.firstsSum = this.viewChart("first", null, true).data[7];
      }
      if (this.filledScaleCount("last") >= 1) {
        scaleResults.lastsSum = this.viewChart("last", null, true).data[7];
      }

      this.scaleResults = scaleResults;
      // Calculate Chart Data And Save for Reports
    },
    consentOk: function () {
      HTTP.patch("childs/" + this.childId).then((result) => {
        this.child.isConsent = true;
      });
    },
    updateReport: function () {
      let data = {
        sobeceContent: this.child.sobeceContent,
        improvedCategories: this.child.improvedCategories,
        practiceHarmony: this.child.practiceHarmony,
        skillsAimedDevelop: this.child.skillsAimedDevelop,
        recommendationsForFamily: this.child.recommendationsForFamily,
      };
      HTTP.put("childs/report/" + this.childId, data)
        .then(() => {
          Swal.fire({
            icon: "success",
            text: "Rapor güncellendi.",
            timer: 1500,
          });
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            text: "Rapor güncellenirken hata oluştu, lütfen daha sonra tekrar deneyin.",
            timer: 2500,
          });
        });
    },
    generateReport() {
      this.pdfProcess = true;
      this.$refs.html2Pdf.generatePdf();
    },
  },
  watch: {
    showGraph: function () {
      if (!this.showGraph) {
        this.graphData = { data: null };
        this.activeChart = { type: null, answerShortLink: null, data: null };
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="carousel slide just-slide conversation-forest">
      <ol class="carousel-thumbs" v-if="routeName != 'AppChildSide'">
        <div class="float-right carousel-control">
          <button
            class="btn btn-secondary btn-circle"
            v-if="routeName != 'AppChildSide'"
            @click="
              activeSlide != 0 ? activeSlide-- : '';
              syncSlide();
            "
          >
            <i class="fas fa-angle-left"></i>
          </button>
          <button
            class="btn btn-secondary btn-circle"
            v-if="routeName != 'AppChildSide'"
            @click="
              activeSlide != steps.length - 1 ? activeSlide++ : '';
              syncSlide();
            "
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </div>
        <li
          v-for="(app, index) in steps"
          :key="index"
          :class="{ active: activeSlide == index }"
          @click="
            activeSlide = index;
            syncSlide();
          "
        >
          <div class="directive">
            {{ index + 1 }}.

            <div v-html="app.directive.substr(0, 8)" v-if="app.directive"></div>
          </div>
          <img
            class="d-block img-fluid"
            v-if="app.img"
            :src="app.img"
            :title="app.directive"
            :alt="app.directive"
          />
          <div class="no-image" :title="app.directive" v-if="!app.img">
            <span class="appindex"> {{ index + 1 }} </span>
          </div>
        </li>

        <div class="clearfix"></div>
      </ol>
      <div class="row">
        <div class="col-12 p-0 text-left">
          <button
            class="btn btn-primary mr-3 ml-3"
            @click="replay"
            v-if="$route.name == 'App'"
          >
            <i class="fas fa-redo-alt"></i>
            Yeniden Oyna
          </button>
        </div>
      </div>
      <div>
        <transition
          name="fade"
          enter-active-class="animate__animated animate__fadeIn"
        >
          <template
            v-for="(app, index) in steps"
            :class="{ active: activeSlide == index }"
          >
            <div v-if="activeSlide == index" :key="index">
              <div class="mb-4 mt-4 bold" v-html="app.directive"></div>
              <!-- {{ app }} -->

              <div class="select-places" v-if="activeSlide == 0">
                <button
                  class="btn btn-outline-primary mr-3 ml-3"
                  v-for="(place, index) in app.options"
                  :class="{ selectedPLace: answers.place.title == place.title }"
                  :key="index"
                  @click="
                    answers.place = place;
                    syncAnswers();
                  "
                >
                  {{ place.title }}
                </button>
              </div>
              <div class="select-person" v-if="activeSlide == 1">
                <div class="row">
                  <template v-for="(person, index) in app.options">
                    <div
                      class="col-3 text-center"
                      :key="index"
                      :class="[
                        { selectedChild: answers.childPersonIndex == index },
                        { selectedExpert: answers.expertPersonIndex == index }
                      ]"
                    >
                      <img
                        class="d-block"
                        v-if="person.img"
                        :src="person.img"
                        @click="selectPerson(index)"
                      />
                      <button
                        class="btn btn-primary mr-2 ml-2 mt-3"
                        @click="selectPerson(index)"
                      >
                        {{ person.title ? person.title : "Seç" }}
                      </button>
                    </div>
                  </template>
                </div>
              </div>
              <div
                class="select-words pt-3"
                :style="{
                  'background-image': 'url(' + answers.place.img + ')'
                }"
                v-if="activeSlide > 1"
              >
                <div class="d-block buttons">
                  <button
                    class="btn btn-secondary btn-round waves-effect waves-light bg-white m-2"
                    :class="{ clicked: option.clicked }"
                    v-for="(option, index) in app.options"
                    :key="index"
                    @click="
                      option.clicked = true;
                      syncOption(index);
                    "
                  >
                    {{ option.name }}
                  </button>
                </div>
                <div class="speech left" v-if="told.child">
                  {{ told.child }}
                </div>
                <img
                  :src="childImage"
                  alt="Danışan"
                  class="child"
                  :class="[
                    { right: answers.childPersonIndex >= 2 },
                    { leftFlip: answers.childPersonIndex >= 2 }
                  ]"
                />
                <div class="speech right" v-if="told.expert">
                  {{ told.expert }}
                </div>
                <img
                  :src="expertImage"
                  alt="Uzman"
                  class="expert"
                  :class="[
                    { right: answers.expertPersonIndex >= 2 },
                    { rightFlip: answers.expertPersonIndex < 2 }
                  ]"
                />
              </div>
            </div>
          </template>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/components/event-bus";

export default {
  name: "ConversationForest",
  props: ["application"],
  data() {
    return {
      routeName: this.$route.name,
      activeSlide: -1,
      steps: null,
      answers: {
        place: {
          title: null,
          img: null
        },
        expertPersonIndex: null,
        childPersonIndex: null
      },
      told: {
        child: null,
        expert: null
      }
    };
  },
  mounted() {
    this.steps = JSON.parse(this.application.steps).items;

    this.steps[2].options = this.steps[2].options.map(option => {
      return {
        name: option,
        clicked: false
      };
    });
    this.steps[3].options = this.steps[3].options.map(option => {
      return {
        name: option,
        clicked: false
      };
    });
    this.steps[4].options = this.steps[4].options.map(option => {
      return {
        name: option,
        clicked: false
      };
    });

    EventBus.$on("getAnswers", () => {
      this.saveAnswers();
    });
  },
  beforeDestroy: function() {
    EventBus.$off("getAnswers");
  },
  sockets: {
    setActiveSlide: function(data) {
      if (data.applicationId == this.application.id) {
        this.activeSlide = data.activeSlideIndex;
      }
    },
    syncAnswers: function(data) {
      if (data.applicationId == this.application.id) {
        this.answers = data.answers;
      }
    },
    syncOption: function(data) {
    console.log("🚀 ~ file: 24.vue ~ line 241 ~ data", data)
      if (data.applicationId == this.application.id) {
        if (this.$route.name == "AppChildSide") {
          this.told.expert = this.steps[this.activeSlide].options[
            data.optionIndex
          ].name;
        } else {
          this.told.child = this.steps[this.activeSlide].options[
            data.optionIndex
          ].name;
        }
        this.steps[this.activeSlide].options[data.optionIndex].clicked = true;
      }
    }
  },
  methods: {
    replay: function() {
      EventBus.$emit("reSendActiveApplication", true);
      this.steps = JSON.parse(this.application.steps).items;
      this.steps[2].options = this.steps[2].options.map(option => {
        return {
          name: option,
          clicked: false
        };
      });
      this.steps[3].options = this.steps[3].options.map(option => {
        return {
          name: option,
          clicked: false
        };
      });
      this.steps[4].options = this.steps[4].options.map(option => {
        return {
          name: option,
          clicked: false
        };
      });

      this.activeSlide = 0;
      this.answers = {
        place: {
          title: null,
          img: null
        },
        expertPersonIndex: null,
        childPersonIndex: null
      };
      this.told = {
        child: null,
        expert: null
      };
      setTimeout(() => {
        this.syncAnswers();
        this.syncSlide();
      }, 250);
    },
    selectPerson: function(personIndex) {
      if (this.$route.name == "App") {
        this.answers.expertPersonIndex = personIndex;
      } else {
        this.answers.childPersonIndex = personIndex;
      }
      this.syncAnswers();
    },
    syncSlide: function() {
      this.$socket.client.emit("setActiveSlide", {
        to: this.$route.params.id,
        activeSlideIndex: this.activeSlide,
        applicationId: this.application.id
      });
    },
    saveAnswers: function() {
      EventBus.$emit("saveAnswers");
    },
    syncAnswers: function() {
      setTimeout(() => {
        this.$socket.client.emit("syncAnswers", {
          to: this.$route.params.id,
          answers: this.answers,
          applicationId: this.application.id
        });
      }, 250);
    },
    syncOption: function(optionIndex) {
      if (this.$route.name == "App") {
        this.told.expert = this.steps[this.activeSlide].options[
          optionIndex
        ].name;
      } else {
        this.told.child = this.steps[this.activeSlide].options[
          optionIndex
        ].name;
      }
      this.$socket.client.emit("syncOption", {
        to: this.$route.params.id,
        optionIndex: optionIndex,
        applicationId: this.application.id
      });
    }
  },
  computed: {
    childImage: function() {
      if (this.answers.childPersonIndex != null) {
        return this.steps[1].options[this.answers.childPersonIndex].img;
      }
      return null;
    },
    expertImage: function() {
      if (this.answers.expertPersonIndex != null) {
        return this.steps[1].options[this.answers.expertPersonIndex].img;
      }
      return null;
    }
  },
  watch: {
    application: {
      deep: true,
      handler() {
        if (this.application.steps) {
          this.activeSlide = -1;
          this.steps = JSON.parse(this.application.steps).items;
        }
      }
    },
    activeSlide: function() {
      this.told = {
        child: null,
        expert: null
      };
    }
  }
};
</script>

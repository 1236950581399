<template>
  <div
    class="row mt-5 mr-3 ml-3 assessment-form"
    :class="{ 'centered-content': xhrProcess }"
  >
    <div
      class="spinner-border text-success"
      role="status"
      v-if="xhrProcess"
    ></div>
    <button
      type="button"
      class="btn btn-secondary btn-round waves-effect waves-light h-2 position-absolute goback"
      @click="goBack()"
      v-if="!xhrProcess"
    >
      <i class=" far fa-arrow-alt-circle-left "></i>
      Panel
    </button>

    <div
      class="col-12 col-sm-8 offset-sm-2 card p-4 report-wrapper"
      v-if="!xhrProcess"
    >
      <button
        class="btn btn-primary btn-round waves-effect waves-light h-2 position-absolute"
        style="right:15px;top:15px;"
        @click="generateReport"
      >
        <span v-if="!pdfProcess">
          <i class="far fa-file-pdf"></i> PDF olarak Kaydet
        </span>
        <div
          class="spinner-border spinner-border-sm"
          role="status"
          v-if="pdfProcess"
        ></div>
      </button>
      <img
        src="@/assets/images/logo.svg"
        alt="Sobece Logo"
        class="logo text-left"
      />
      <span class="h4">SOBECE Beceri Çocuk Eğitimi Raporu</span>
      <div v-if="!xhrProcess && child">
        <p>
          <b>ÇOCUĞUN ADI SOYADI:</b> {{ child.name }}<br />

          <b>DOĞUM TARİHİ:</b>
          {{ new Date(child.birthdate).toLocaleDateString("tr") }}
        </p>
        <p>
          <b>PROGRAM İÇERİK:</b>
          {{ child.sobeceContent }}
        </p>
        <p style="max-width:90%;">
          <b>SOBECE GRUBUNDA ÇALIŞILAN BECERİLER:</b>
          <template v-for="(cat, index) in studiedCats">
            <span :key="index" class="mr-1"> {{ getCatInfo(cat).name }} </span
            >{{ index + 1 != studiedCats.length ? "," : "" }}
          </template>
        </p>
        <p>
          <b>GELİŞİM GÖSTERDİĞİ BECERİLER:</b>
          {{ child.improvedCategories }}
        </p>
        <p>
          <b>SOBECE PRATİKLERE UYUM:</b>
          {{ child.practiceHarmony }}
        </p>
      </div>
      <table class="table table-bordered table-centered">
        <thead>
          <tr>
            <th></th>
            <th>Ön Test</th>
            <th>Son Test</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Anne - Baba</td>
            <td>
              {{
                scaleResults.parentFirst != 0
                  ? scaleResults.parentFirst + " %"
                  : "Doldurulmadı"
              }}
            </td>
            <td>
              {{
                scaleResults.parentLast != 0
                  ? scaleResults.parentLast + " %"
                  : "Doldurulmadı"
              }}
            </td>
          </tr>
          <tr>
            <td>Öğretmen</td>
            <td>
              {{
                scaleResults.teacherFirst != 0
                  ? scaleResults.teacherFirst + " %"
                  : "Doldurulmadı"
              }}
            </td>
            <td>
              {{
                scaleResults.teacherLast != 0
                  ? scaleResults.teacherLast + " %"
                  : "Doldurulmadı"
              }}
            </td>
          </tr>
          <tr>
            <td>Uzman</td>
            <td>
              {{
                scaleResults.userFirst != 0
                  ? scaleResults.userFirst + " %"
                  : "Doldurulmadı"
              }}
            </td>
            <td>
              {{
                scaleResults.userLast != 0
                  ? scaleResults.userLast + " %"
                  : "Doldurulmadı"
              }}
            </td>
          </tr>
          <tr>
            <td>Genel Toplam</td>
            <td>
              {{
                scaleResults.firstsSum != 0
                  ? scaleResults.firstsSum + " %"
                  : "Doldurulmadı"
              }}
            </td>
            <td>
              {{
                scaleResults.lastsSum != 0
                  ? scaleResults.lastsSum + " %"
                  : "Doldurulmadı"
              }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row" v-if="graphDatas">
        <div class="col-12 firsts" v-if="false">
          <div class="col-12mt-3 mb-3">
            <span class="h5 mb-2 d-block"
              >Anne - Baba Ön Test Grafiği
              <small class="alert red" v-if="!graphDatas.parentFirst.data"
                >(Doldurulmadı)</small
              ></span
            >
            <BarChart
              :height="254"
              :graphData="graphDatas.parentFirst"
              v-if="showGraph"
            ></BarChart>
          </div>
          <div class="col-12 ">
            <span class="h5 mb-2 d-block"
              >Öğretmen Ön Test Grafiği
              <small class="alert red" v-if="!graphDatas.teacherFirst.data"
                >(Doldurulmadı)</small
              >
            </span>
            <BarChart
              :height="254"
              :graphData="graphDatas.teacherFirst"
              v-if="showGraph"
            ></BarChart>
          </div>
          <div class="col-12 mt-3 mb-3">
            <span class="h5 mb-2 d-block"
              >Uzman Ön Test Grafiği
              <small class="alert red" v-if="!graphDatas.userFirst.data"
                >(Doldurulmadı)</small
              ></span
            >

            <BarChart
              :height="254"
              :graphData="graphDatas.userFirst"
              v-if="showGraph"
            ></BarChart>
          </div>
        </div>
        <div class="col-12 lasts" v-if="false">
          <div class="col-12 mt-3 mb-3">
            <span class="h5 mb-2 d-block"
              >Anne - Baba Son Test Grafiği

              <small class="alert red" v-if="!graphDatas.parentLast.data"
                >(Doldurulmadı)</small
              >
            </span>
            <BarChart
              :height="254"
              :graphData="graphDatas.parentLast"
              v-if="showGraph"
            ></BarChart>
          </div>
          <div class="col-12 mt-3 mb-3">
            <span class="h5 mb-2 d-block"
              >Öğretmen Son Test Grafiği
              <small class="alert red" v-if="!graphDatas.teacherLast.data"
                >(Doldurulmadı)</small
              >
            </span>
            <BarChart
              :height="254"
              :graphData="graphDatas.teacherLast"
              v-if="showGraph"
            ></BarChart>
          </div>
          <div class="col-12 mt-3 mb-3">
            <span class="h5 mb-2 d-block"
              >Uzman Son Test Grafiği
              <small class="alert red" v-if="!graphDatas.userLast.data"
                >(Doldurulmadı)</small
              >
            </span>
            <BarChart
              :height="254"
              :graphData="graphDatas.userLast"
              v-if="showGraph"
            ></BarChart>
          </div>
        </div>
        <div class="col-12 mt-3 mb-3">
          <span class="h5 mb-2 d-block"
            >Ön Test - Son Test Karşılaştırma
            <small class="alert red" v-if="!graphData.data"
              >(Yeterli Veri Yok)</small
            >
          </span>
          <BarChart
            :height="254"
            :graphData="graphData"
            v-if="showGraph"
          ></BarChart>
        </div>
      </div>
      <div v-if="!xhrProcess && child" class="mt-1">
        <p>
          <b>GELİŞTİRİLMESİ HEDEFLENEN BECERİLER:</b> <br />
          {{ child.skillsAimedDevelop }}
        </p>
        <p>
          <b>AİLEYE ÖNERİLER:</b> <br />
          {{ child.recommendationsForFamily }}
        </p>
      </div>
    </div>
    <vue-html2pdf
      v-if="!xhrProcess"
      :enable-download="true"
      :paginate-elements-by-height="22400"
      :manual-pagination="false"
      :html-to-pdf-options="{
        margin: [5, 5, 5, 5],
        filename: child
          ? child.name + ' Bireysel Uygulama Formu'
          : 'Bireysel Uygulama Formu'
      }"
      pdf-format="a4"
      pdf-orientation="portrait"
      ref="html2Pdf"
      @hasDownloaded="pdfProcess = false"
    >
      <section slot="pdf-content">
        <div
          class="col-12 col-sm-8 offset-sm-2 card p-4 report-wrapper"
          v-if="!xhrProcess"
        >
          <img
            src="@/assets/images/logo.svg"
            alt="Sobece Logo"
            class="logo text-left"
            width="300"
            height="auto"
            style="margin:0;"
          />
          <span class="h4">SOBECE Beceri Çocuk Eğitimi Raporu</span>
          <div v-if="!xhrProcess && child">
            <p>
              <b>ÇOCUĞUN ADI SOYADI:</b> {{ child.name }}<br />

              <b>DOĞUM TARİHİ:</b>
              {{ new Date(child.birthdate).toLocaleDateString("tr") }}
            </p>
            <p>
              <b>PROGRAM İÇERİK:</b>
              {{ child.sobeceContent }}
            </p>
            <p style="max-width:90%;">
              <b>SOBECE GRUBUNDA ÇALIŞILAN BECERİLER:</b>
              <template v-for="(cat, index) in studiedCats">
                <span :key="index" class="mr-1">
                  {{ getCatInfo(cat).name }} </span
                >{{ index + 1 != studiedCats.length ? "," : "" }}
              </template>
            </p>
            <p>
              <b>GELİŞİM GÖSTERDİĞİ BECERİLER:</b>
              {{ child.improvedCategories }}
            </p>
            <p>
              <b>SOBECE PRATİKLERE UYUM:</b>
              {{ child.practiceHarmony }}
            </p>
          </div>
          <table class="table table-bordered table-centered">
            <thead>
              <tr>
                <th></th>
                <th>Ön Test</th>
                <th>Son Test</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Anne - Baba</td>
                <td>
                  {{
                    scaleResults.parentFirst != 0
                      ? scaleResults.parentFirst + " %"
                      : "Doldurulmadı"
                  }}
                </td>
                <td>
                  {{
                    scaleResults.parentLast != 0
                      ? scaleResults.parentLast + " %"
                      : "Doldurulmadı"
                  }}
                </td>
              </tr>
              <tr>
                <td>Öğretmen</td>
                <td>
                  {{
                    scaleResults.teacherFirst != 0
                      ? scaleResults.teacherFirst + " %"
                      : "Doldurulmadı"
                  }}
                </td>
                <td>
                  {{
                    scaleResults.teacherLast != 0
                      ? scaleResults.teacherLast + " %"
                      : "Doldurulmadı"
                  }}
                </td>
              </tr>
              <tr>
                <td>Uzman</td>
                <td>
                  {{
                    scaleResults.userFirst != 0
                      ? scaleResults.userFirst + " %"
                      : "Doldurulmadı"
                  }}
                </td>
                <td>
                  {{
                    scaleResults.userLast != 0
                      ? scaleResults.userLast + " %"
                      : "Doldurulmadı"
                  }}
                </td>
              </tr>
              <tr>
                <td>Genel Toplam</td>
                <td>
                  {{
                    scaleResults.firstsSum != 0
                      ? scaleResults.firstsSum + " %"
                      : "Doldurulmadı"
                  }}
                </td>
                <td>
                  {{
                    scaleResults.lastsSum != 0
                      ? scaleResults.lastsSum + " %"
                      : "Doldurulmadı"
                  }}
                </td>
              </tr>
            </tbody>
          </table>
<div class="html2pdf__page-break"></div>
          <div class="row" v-if="graphDatas">
            <div class="col-12 mt-3 mb-3">
              <span class="h5 mb-2 d-block"
                >Ön Test - Son Test Karşılaştırma
                <small class="alert red" v-if="!graphData.data"
                  >(Yeterli Veri Yok)</small
                >
              </span>
              <BarChart
                :height="254"
                :graphData="graphData"
                v-if="showGraph"
              ></BarChart>
            </div>
          </div>
          
          <div v-if="!xhrProcess && child" class="mt-1">
            <p>
              <b>GELİŞTİRİLMESİ HEDEFLENEN BECERİLER:</b> <br />
              {{ child.skillsAimedDevelop }}
            </p>
            <p>
              <b>AİLEYE ÖNERİLER:</b> <br />
              {{ child.recommendationsForFamily }}
            </p>
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
/* eslint-disable */
import HTTP from "@/main-source";
import Swal from "sweetalert2";
import router from "@/router";
import VueHtml2pdf from "vue-html2pdf";
import BarChart from "@/components/charts/bar";

export default {
  name: "SobeceReport",
  data() {
    return {
      authUser: JSON.parse(localStorage.getItem("user")),
      role: localStorage.getItem("role"),
      categories: [],
      subCategories: [],
      studiedCats: [],
      applications: [],
      xhrProcess: false,
      childId: this.$route.params.childId,
      child: null,
      answers: [],
      answeredQuestionIdList: [],
      answeredQuestionSubCatIdList: [],
      scaleResults: JSON.parse(this.$route.query.scales),
      pdfProcess: false,
      graphData: {
        data: null
      },
      graphDatas: {
        parentFirst: { data: null },
        teacherFirst: { data: null },
        userFirst: { data: null },
        parentLast: { data: null },
        teacherLast: { data: null },
        userLast: { data: null },
        firstsSum: { data: null },
        lastsSum: { data: null }
      },
      showGraph: false
    };
  },
  components: {
    VueHtml2pdf,
    BarChart
  },
  created() {
    document.getElementsByTagName("body")[0].classList.add("nobg");
    HTTP.get("childs/" + this.childId).then(result => {
      if (result.data.rows.length > 0) {
        this.child = result.data.rows[0];
      }
    });
    HTTP.get("answers/child/" + this.childId).then(res => {
      if (res.data.length > 0) {
        this.answers = res.data;
        this.answeredQuestionIdList = this.answers.map(ans => {
          return ans.applicationId;
        });
      }
      this.getCategoriesAndApplications();
    });
  },
  beforeDestroy: function() {
    document.getElementsByTagName("body")[0].classList.remove("nobg");
  },
  methods: {
    generateReport() {
      this.pdfProcess = true;
      this.$refs.html2Pdf.generatePdf();
    },
    getCategoriesAndApplications: function() {
      HTTP.get("categories")
        .then(result => {
          this.categories = result.data;
          this.categories.forEach(mainCat => {
            this.subCategories = this.subCategories.concat(mainCat.categories);
          });
        })
        .then(() => {
          HTTP.get("applications")
            .then(result => {
              this.applications = result.data;
              this.xhrProcess = false;
              this.setStudiedApps();
            })
            .then(() => {
              // Set Chart Data

              this.graphDatas.parentFirst = this.viewChart(
                "single",
                this.getAssessmentAnswer("parent", "first", true).shortlink
              );
              this.graphDatas.teacherFirst = this.viewChart(
                "single",
                this.getAssessmentAnswer("teacher", "first", true).shortlink
              );
              this.graphDatas.userFirst = this.viewChart(
                "single",
                this.getAssessmentAnswer("user", "first", true).shortlink
              );
              this.graphDatas.parentLast = this.viewChart(
                "single",
                this.getAssessmentAnswer("parent", "last", true).shortlink
              );
              this.graphDatas.teacherLast = this.viewChart(
                "single",
                this.getAssessmentAnswer("teacher", "last", true).shortlink
              );
              this.graphDatas.userLast = this.viewChart(
                "single",
                this.getAssessmentAnswer("user", "last", true).shortlink
              );
              this.graphDatas.comparative = this.viewChart("comparative");

              //
              this.showGraph = true;
            })
            .catch(e => {
              console.log(
                "🚀 ~ file: SobeceReport.vue ~ line 312 ~ .then ~ e",
                e
              );
              this.xhrProcess = false;
            });
        })
        .catch(() => {
          this.xhrProcess = false;
        });
    },
    getAnswer: function(questionId) {
      return this.answers.filter(ans => ans.applicationId == questionId)[0];
    },
    getFilteredApplications: function(categoryId) {
      return this.applications.filter(app => app.categoryId == categoryId);
    },
    returnNote: function(noteArray) {
      if (typeof noteArray == "string") {
        let result = "";
        JSON.parse(noteArray).forEach((r, index) => {
          result += r + "<br> ";
        });

        return result;
      }
    },
    goBack: function() {
      router.go(-1);
    },
    isChecked: function(applicationId) {
      return this.answeredQuestionIdList.includes(applicationId);
    },
    isCheckedSub: function(subcategoryId) {
      let applications = this.getFilteredApplications(subcategoryId);
      let fullDone = applications.some(app => {
        return !this.answeredQuestionIdList.includes(app.id);
      });
      return !fullDone;
    },
    getAnswerText: function(answer, appType) {
      let answerHTML = "";

      if (answer.img) {
        answerHTML +=
          "<img class='bordered-img' src='" +
          this.$apiDomain +
          answer.img +
          "' width='150px' height='auto'>";
      }
      return answerHTML;
    },
    setStudiedApps: function() {
      /* eslint-disable */
      new Promise((resolve, reject) => {
        this.answeredQuestionIdList.forEach(applicationId => {
          let application = this.applications.filter(
            app => app.id == applicationId
          )[0];

          if (application) {
            if (
              !this.answeredQuestionSubCatIdList.includes(
                application.categoryId
              )
            ) {
              this.answeredQuestionSubCatIdList.push(application.categoryId);
            }
          }
        });
        resolve();
      }).then(() => {
        this.answeredQuestionSubCatIdList.forEach(subCatId => {
          let mainCatId = this.subCategories.filter(
            cat => cat.id == subCatId
          )[0];

          if (mainCatId) {
            !this.studiedCats.includes(mainCatId.parentId)
              ? this.studiedCats.push(mainCatId.parentId)
              : "";
          }
        });
      });
    },
    getCatInfo: function(catId) {
      return this.categories.filter(cat => cat.id == catId)[0];
    },
    // Graph Duplicate... to be fixed
    getAssessmentAnswer: function(who, which) {
      let type = 1,
        filledBy = null;
      if (which == "last") {
        type = 2;
      }
      switch (who) {
        case "user":
          filledBy = 1;
          break;
        case "teacher":
          filledBy = 2;
          break;

        default:
          filledBy = 3;
          break;
      }
      return this.child.AssessmentScaleAnswers.filter(
        assessmentAnswer =>
          assessmentAnswer.filledBy == filledBy && assessmentAnswer.type == type
      )[0];
    },
    answersTotal: function(selectedAssessmentAnswers) {
      if (selectedAssessmentAnswers) {
        let totalReplyCountWithoutZero;
        let answersTotal = [];
        this.categories
          .filter(cat => cat.parentId == null)
          .forEach(mainCat => {
            totalReplyCountWithoutZero = selectedAssessmentAnswers.filter(
              answer =>
                mainCat.id == answer.categoryId && parseInt(answer.answer) != 0
            ).length;

            let total = selectedAssessmentAnswers
              .filter(answer => mainCat.id == answer.categoryId)
              .reduce((a, b) => {
                return a + parseInt(b.answer);
              }, 0);

            let ratioForPercent = (
              ((total / totalReplyCountWithoutZero).toFixed(2) * 100) /
              4
            ).toFixed(2);
            answersTotal.push(ratioForPercent);
          });
        // Grand Total
        let grandTotal = answersTotal.reduce((a, b) => {
          return parseInt(a) + parseInt(b);
        });

        answersTotal.push((grandTotal / 7).toFixed(2));
        return answersTotal;
      }
      return false;
    },
    combineAnswers: function(selectedAssessmentAnswers) {
      return []
        .concat(
          selectedAssessmentAnswers[0].answers
            ? typeof selectedAssessmentAnswers[0].answers == "string"
              ? JSON.parse(selectedAssessmentAnswers[0].answers)
              : selectedAssessmentAnswers[0].answers
            : []
        )
        .concat(
          selectedAssessmentAnswers[1].answers
            ? typeof selectedAssessmentAnswers[1].answers == "string"
              ? JSON.parse(selectedAssessmentAnswers[1].answers)
              : selectedAssessmentAnswers[1].answers
            : []
        )
        .concat(
          selectedAssessmentAnswers[2].answers
            ? typeof selectedAssessmentAnswers[2].answers == "string"
              ? JSON.parse(selectedAssessmentAnswers[2].answers)
              : selectedAssessmentAnswers[2].answers
            : []
        );
    },
    viewChart: function(type, answerShortLink, returnValue = true) {
      /* eslint-disable */
      this.graphData = { type, data: null, isSingleTypeFirst: true };
      this.activeChart = { type, answerShortLink, data: null };

      // types: single,first,last,mixed
      let selectedAssessmentAnswers;

      if (type == "single") {
        selectedAssessmentAnswers = this.child.AssessmentScaleAnswers.filter(
          assessmentAnswer => assessmentAnswer.shortlink == answerShortLink
        )[0];
        if (selectedAssessmentAnswers.type == 2) {
          this.graphData.isSingleTypeFirst = false;
        }
      }

      if (type == "first" || type == "last") {
        let atype = 1;
        if (type == "last") {
          atype = 2;
          this.graphData.isSingleTypeFirst = false;
        }
        selectedAssessmentAnswers = this.child.AssessmentScaleAnswers.filter(
          assessmentAnswer => assessmentAnswer.type == atype
        );

        selectedAssessmentAnswers = {
          answers: this.combineAnswers(selectedAssessmentAnswers)
        };
      }

      if (type == "comparative") {
        let firstTest = this.combineAnswers(
          this.child.AssessmentScaleAnswers.filter(
            assessmentAnswer => assessmentAnswer.type == 1
          )
        );

        let lastTest = this.combineAnswers(
          this.child.AssessmentScaleAnswers.filter(
            assessmentAnswer => assessmentAnswer.type == 2
          )
        );

        this.graphData.data = this.answersTotal(firstTest);
        this.graphData.last = this.answersTotal(lastTest);

        this.showGraph = true;
      } else {
        if (typeof selectedAssessmentAnswers.answers == "string") {
          selectedAssessmentAnswers.answers = JSON.parse(
            selectedAssessmentAnswers.answers
          );
        }

        // set active chart data and after set answers start
        this.activeChart.data = selectedAssessmentAnswers;
        selectedAssessmentAnswers = selectedAssessmentAnswers.answers;
        //# set active chart data and after set answers

        this.graphData.data = this.answersTotal(selectedAssessmentAnswers);

        if (returnValue) {
          console.log("return valued");
          return this.graphData;
        } else {
          this.showGraph = true;
        }
      }
    }
  }
};
</script>
